import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material';
import MaterialTable from 'material-table';
import { useState, useRef, useEffect } from "react";
import { FormWithConstraints, FieldFeedbacks, FieldFeedback, } from 'react-form-with-constraints';
import services from "../Services/Services";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import moment from 'moment';
import XLSX from "xlsx-color";
import Select from 'react-select'

const defaultMaterialTheme = createTheme();

const Viewcardtextrecognize = () => {


    const handleExcelExport = async (st) => {
        
        var UI = UserID;

        if (UserID.value === "--Select User Name--") {
          UI = "";
        }
        else {
          UI = UserID.value;
        }

        var data = JSON.stringify({
            "CardTextRecognizeID": "",
            "UserID": st == "cancle" ? "" : UI,
            "StartDate": moment(StartDate, "DD-MM-YYYY"),
            "EndDate": moment(EndDate, "DD-MM-YYYY"),
            "PaginationID": "-1",
            "PageLength": ""
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "CardTextRecognize/GetCardTextRecognize",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        services.JsonValue(config).then(response => {
            if (response.data.status) {
                exportToExcel(response.data.data)
            }
        }, error => { })
    };

    const exportToExcel = (array) => {
        console.log(array, 'data');
        debugger;
        const header = ["Sr.No", "User Name", "Company Name", "Designation", "Name", "Mobile No", "Address", "Email", "Website", "Other", "Date"];
        const body = [];
        array?.forEach((col, index) => {
            body.push([index + 1, col.UserName, col.CompanyName, col.Designation, col.Name, col.MobileNo, col.Address, col.Email, col.Website, col.Other, col.CreatedDate]);
        });

        if (!body.length) {
            return Swal.fire({
                title: "No Data Found!",
                icon: "error",
                timer: 1500
            });
        }

        const ws1 = XLSX.utils.aoa_to_sheet([header, ...body]);

        header.forEach((col, index) => {
            ws1[XLSX.utils.encode_cell({ r: 0, c: index })].s = {

                font: { bold: true, color: { rgb: "f7f4ef" } }, // White font color
                fill: { patternType: 'solid', fgColor: { rgb: "#B71C1C" } }, // Blue background color
                alignment: { wrapText: false, horizontal: 'center' },
            };
        });

        ws1['!cols'] = [
            { width: 6 },
            { width: 15 },
            { width: 25 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 30 },
            { width: 25 },
            { width: 30 },
            { width: 40 },
            { width: 20 },


        ];

        let cell = {
            font: { bold: true, color: { rgb: "f7f4ef" } },
            fill: { patternType: 'solid', fgColor: { rgb: "#B71C1C" } },
            alignment: { wrapText: false, horizontal: 'center' },
            border: {
                left: { style: 'thin', color: { rgb: '000000' } },
                right: { style: 'thin', color: { rgb: '000000' } },
                top: { style: 'thin', color: { rgb: '000000' } },
                bottom: { style: 'thin', color: { rgb: '000000' } }
            }
        }

        ws1['A1'].s = cell;
        ws1['B1'].s = cell;
        ws1['C1'].s = cell;
        ws1['D1'].s = cell;
        ws1['E1'].s = cell;
        ws1['F1'].s = cell;
        ws1['G1'].s = cell;
        ws1['H1'].s = cell;
        ws1['I1'].s = cell;
        ws1['J1'].s = cell;
        ws1['K1'].s = cell;

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws1, 'Cardtext');

        // Save the workbook
        XLSX.writeFile(wb, `Cardtext_${moment().format('DD-MM-YYYY_HH:mm')}.xlsx`);

    };

    const headerTblStyle = { color: 'Black' };
    const cols = [
        { title: (<div style={{ color: 'white', width: '10px' }}><span>Sr.No</span></div>), field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '1px' }}>{(pageId * pageCount - 10) + rowData.tableData.id + 1}</p>) } },
        { title: (<div style={{ width: '120%', color: 'white' }}><span>User Name</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '90px' }}>{rowData.UserName}</p>) } },
        { title: (<div style={{ width: '100%', color: 'white' }}><span>Company Name</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '140px' }}>{rowData.CompanyName}</p>) } },
        { title: (<div style={{ width: '100%', color: 'white' }}><span>Name</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '90px' }}>{rowData.Name}</p>) } },
        {
            title: (<div style={{ color: 'white', width: '160px' }}><span> Mobile No</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => {
                return (
                    <div style={{ marginBottom: '1px', width: '200px' }}>{
                        <table id="MobileDetail" style={{ border: 'solid 1px #000000', marginRight: '10px' }}>
                            <tbody>
                                {/* <tr style={{ border: 'solid 1px #000000', background: '#ffffff' }}>
                                    <td style={{ border: 'solid 1px #000000' }}>Mobile No</td>
                                    <td style={{ border: 'solid 1px #000000', padding: 2, fontWeight: 'bold', color: 'black' }}>
                                        <span id="MobileNo" />{rowData.MobileNo}<br />
                                    </td>
                                </tr> */}
                                <tr style={{ border: 'solid 1px #000000', background: '#ffffff' }}>
                                    <td style={{ border: 'solid 1px #000000' }}>Mobile No</td>
                                    <td style={{ border: 'solid 1px #000000', padding: 2, fontWeight: 'bold', color: 'black', height: '20px' }}>
                                        {/* Conditionally render the MobileNo and apply styles */}
                                        {rowData.MobileNo ? (
                                            <span id="MobileNo">{rowData.MobileNo}<br /></span>
                                        ) : (
                                            <span style={{ height: '20px', width: '85px', display: 'block' }}></span>
                                        )}
                                    </td>
                                </tr>

                                <tr style={{ border: 'solid 1px #000000', background: '#ffffff' }}>
                                    <td style={{ border: 'solid 1px #000000' }}>Mobile No 2 </td>
                                    <td style={{ border: 'solid 1px #000000', padding: 2, fontWeight: 'bold', color: 'black' }}>
                                        <span id="MobileNo2" />{rowData.MobileNo2}<br />
                                    </td>
                                </tr>
                            </tbody>
                        </table>}</div>)
            }
        },
        { title: (<div style={{ width: '100%', color: 'white' }}><span>Designation</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '90px' }}>{rowData.Designation}</p>) } },
        { title: (<div style={{ width: '100%', color: 'white' }}><span>Date</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '90px' }}>{rowData.CreatedDate}</p>) } },
    ]

    const form = useRef(FormWithConstraints);
    const [iconFilter, seticonFilter] = useState('fa fa-plus')
    const [isfiltervisible, setFilter] = useState(false)

    const [pageCount, setPageCount] = useState(10);
    const [pageId, setPageId] = useState(1);
    const [tableData, setTableData] = useState([]);
    const [allTableData, setAllTableData] = useState([]);
    const [TotalCount, setTotalCount] = useState('')

    const [UserID, setUserID] = useState({ 'label': '--Select User Name--', 'value': '--Select User Name--' });
    const [Useres, getUser] = useState([])

    const [StartDate, setStartDate] = useState(new Date())
    const [EndDate, setEndDate] = useState(new Date())
    const [dateError, setDateError] = useState("");

    useEffect(() => {
        GetCardTextRecognize(pageCount);
        GetUserMaster();
    }, []);

    const GetCardTextRecognize = (pageCount, st) => {
        debugger;

        var UI = UserID;

        if (UserID.value === "--Select User Name--") {
          UI = "";
        }
        else {
          UI = UserID.value;
        }

        var data = JSON.stringify({
            "CardTextRecognizeID": "",
            "UserID": st == "cancle" ? "" : UI,
            "StartDate": moment(StartDate, "DD-MM-YYYY"),
            "EndDate": moment(EndDate, "DD-MM-YYYY"),
            "PaginationID": "0",
            "PageLength": `${pageCount}`
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "CardTextRecognize/GetCardTextRecognize",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                setTableData(response.data.data)
                setAllTableData(response.data.data)
                setPageId(1)
                setTotalCount(response.data.total[0].TotalCount);
            }
            else {
                setTableData([]);
            }

        }, error => { })
    }

    const GetUserMaster = () => {
        var data = JSON.stringify({
          "UserID": "",
          "UserName": "",
          "MobileNo": "",
          "BirthDate": "",
          "StartDate": "",
          "EndDate": "",
          "PaginationID": "-1",
          "PageLength": ""
        });
        var config = {
          method: 'POST',
          url: services.API_URL + "User/GetUser",
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };
        services.JsonValue(config).then(response => {
    
          if (response.data.status) {
            getUser(response.data.data.map(item => ({ value: item.UserID, label: item.UserName })))
          }
          else {
            getUser([]);
          }
        }, error => { })
      }
      const onUserChange = (e) => {
        setUserID(e);
      }

    const setPreviousApi = () => {
        debugger;
        if (pageId == 1) {
            return
        }
        const tempt = [...allTableData]
        console.log((pageId - 2) * pageCount, (pageId - 1) * pageCount, 'alltabledata')
        const sliced = tempt.slice((pageId - 2) * pageCount, (pageId - 1) * pageCount)

        setTableData(sliced)
        setPageId(pageId - 1)

    };
    const setNextApi = () => {
        debugger
        console.log(pageId, "pageID", pageCount)

        if (!tableData.length) {
            return;
        }
        if (pageId * pageCount < allTableData.length) {
            const tempt = [...allTableData];
            const sliced = tempt.slice((pageId) * pageCount, (pageId + 1) * pageCount);
            setTableData(sliced);
            setPageId(pageId + 1);
            return;
        }

        const paginationIndex = allTableData[pageId * pageCount - 1]?.CardTextRecognizeID;
        if (!paginationIndex) {
            return;
        }

        var data = JSON.stringify({
            "CardTextRecognizeID": "",
            "UserID": "",
            "StartDate": moment(StartDate, "DD-MM-YYYY"),
            "EndDate": moment(EndDate, "DD-MM-YYYY"),
            "PaginationID": paginationIndex,
            "PageLength": `${pageCount}`
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "CardTextRecognize/GetCardTextRecognize",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        services.JsonValue(config).then(response => {

            console.log(response, 'response')

            if (response.data.status) {
                setTableData(response.data.data)
                setPageId(pageId + 1)
                setAllTableData([...allTableData, ...response.data.data]);
                setTotalCount(response.data.total[0].TotalCount);
            }
        }, error => { })
    };

    const OnFilterClick = (e) => {
        e.preventDefault();
        if (isfiltervisible === false) {
            setFilter(true);
            seticonFilter("fa fa-minus");
        }
        else {
            setFilter(false);
            seticonFilter("fa fa-plus");
        }
    }

    const handleInvitationStartchange = (date) => setStartDate(date);
    const handleEndDatechange = (date) => setEndDate(date);

    const CheckAlert = (id) => {
        debugger;
        Swal.fire({
            title: 'Are you Sure You Want to Delete?', icon: "warning", showCancelButton: true, confirmButtonText: 'Delete', cancelButtonText: 'Cancel'
        }).then((result) => {

            if (result.isConfirmed) {
                var data = JSON.stringify({
                    "CardTextRecognizeID": id
                });
                var config = {
                    method: 'POST',
                    url: services.API_URL + "CardTextRecognize/DeleteCardTextRecognize",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    console.log(response.data.status, 'staus')
                    if (response.data.status) {
                        GetCardTextRecognize(pageCount);
                        Swal.fire({
                            title: 'Successfully Deleted', icon: "success", width: "400px", showConfirmButton: false, timer: 1500
                        });
                    } else {
                        Swal.fire({ position: 'top-end', icon: 'error', title: response.data.message, showConfirmButton: false, timer: 1500 });
                    }
                }, error => { })
            } else if (result.isDenied) {
                Swal.close();
            }
        });


    }

    const onSearchClick = (e) => {
        e.preventDefault();
        if (StartDate && EndDate && StartDate > EndDate) {
            setDateError('Start Date Must Be Less Than End date');
        }
        GetCardTextRecognize(pageCount);
    }

    const OnSearchCancelClick = () => {
        setUserID({ 'label': '--Select User Name--', 'value': '--Select User Name--' });
        setStartDate(new Date())
        setEndDate(new Date())
        setDateError('')
        GetCardTextRecognize(pageCount, "cancle");
    }

    const ExcelClick = () => {
        handleExcelExport();
    }

    return (
        <>
            <div className="body-wrapper">
                <div className="container-fluid">

                    <div className='col-md-12'>
                        <FormWithConstraints
                            ref={form}
                            onSubmit={OnFilterClick}
                            noValidate>

                            {isfiltervisible &&
                                <div className="card">
                                    <div className="px-3 py-2 border-bottom">
                                        <h5>Searching</h5>
                                    </div>

                                    <div className="card-body">
                                        <div className='row'>

                                            <div className="col-md-3">
                                                <div className="form-group ">
                                                    <label htmlFor="User" className="form-label fw-semibold">User Name </label>
                                                    <Select options={[{ label: '--Select User --', value: '--Select User --' }, ...Useres]} value={UserID} onChange={onUserChange} placeholder="--Select User --" />
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="Name" className="form-label fw-semibold">Invitation Start Date</label>
                                                    <DatePicker
                                                        dateFormat="dd-MM-yyyy"
                                                        selected={StartDate}
                                                        autoComplete="off"
                                                        className="form-control readonly"
                                                        id="txtTaskDate"
                                                        value={StartDate}
                                                        placeholderText="dd-MM-yyyy"
                                                        onChange={handleInvitationStartchange}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                </div><br></br>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="Name" className="form-label fw-semibold">Invitation End Date</label>
                                                    <DatePicker
                                                        dateFormat="dd-MM-yyyy"
                                                        selected={EndDate}
                                                        autoComplete="off"
                                                        className="form-control readonly"
                                                        id="txtTaskDate"
                                                        value={EndDate}
                                                        placeholderText="dd-MM-yyyy"
                                                        onChange={handleEndDatechange}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                    {dateError && <p style={{ color: 'red' }}>{dateError}</p>}
                                                </div>
                                            </div>

                                            <div className="col-md-3" style={{ marginTop: '25px' }}>
                                                <button type="button" onClick={onSearchClick} className="btn btn-primary mt-12 mr-3 mobmt-2" style={{ marginRight: '5px', marginTop: '5px' }} >Search </button>
                                                <button type="button" onClick={OnSearchCancelClick} className="btn btn-danger mt-12 mobmt-1" style={{ marginRight: '5px', marginTop: '5px' }}>Cancel </button>
                                                <a className="btn btn-outline-success font-weight-bolder mt-12" onClick={ExcelClick} style={{ marginRight: '5px', marginTop: '5px' }}>
                                                    <i id="btnExcel" className="fa fa-file-excel" /> Excel
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                        </FormWithConstraints>
                    </div>

                    <div className='col-md-12'>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MaterialTable columns={cols} data={tableData}
                                actions={[
                                    // {
                                    //     icon: 'edit', tooltip: 'Edit', onClick: (e, r) => EditBanner(r.BannerID)
                                    // },
                                    {
                                        icon: 'delete', tooltip: 'Delete', onClick: (e, r) => CheckAlert(r.CardTextRecognizeID, r.Info)
                                    }
                                ]}

                                options={{
                                    headerStyle: { backgroundColor: '#B71C1C', color: 'white', fontFamily: 'system-ui' }, toolbar: true, actionsColumnIndex: -1, search: false,
                                    paging: false, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                                }}


                                components={{
                                    Toolbar: props => (
                                        <>
                                            <div className="row" >
                                                <div className="col-md-12" style={{ display: 'flex', alignItems: 'center', marginLeft: '5px', marginTop: '3px', marginBottom: '0.3%' }}>
                                                    <h5>View Card Text Recognize : <span className="badge bg-light-primary text-black fw-semibold fs-2">{TotalCount}</span></h5>

                                                    <div className="col-md-9" style={{ textAlign: 'right', width: '80%' }}>
                                                        <a className="btn btn-outline-primary font-weight-bolder" style={{ marginRight: '10px' }} onClick={OnFilterClick}>
                                                            <i id="btnFilter" className={iconFilter} isfiltervisible='false' /> Filter
                                                        </a>
                                                    </div>

                                                </div>
                                            </div>
                                        </>
                                    )
                                }}

                                detailPanel={[
                                    {
                                        icon: '',
                                        tooltip: 'Detail',
                                        render: rowData => {
                                            return (
                                                <>
                                                    <div style={{ border: '1px' }}>
                                                        <table className="table table-light-dark" align="center" cellSpacing="0" rules="all" border="1" style={{ width: '100%', bordercollapse: 'collapse', border: '1px solid black' }}>
                                                            <thead>
                                                                <tr>
                                                                    <td style={{ backgroundColor: '#B71C1C', color: 'white', border: '1px solid black', color: 'white', fontWeight: 'bold' }}>Address</td>
                                                                    <td style={{ backgroundColor: '#B71C1C', color: 'white', border: '1px solid black', color: 'white', fontWeight: 'bold' }}>Email</td>
                                                                    <td style={{ backgroundColor: '#B71C1C', color: 'white', border: '1px solid black', color: 'white', fontWeight: 'bold' }}>Website</td>
                                                                    <td style={{ backgroundColor: '#B71C1C', color: 'white', border: '1px solid black', color: 'white', fontWeight: 'bold' }}>Other</td>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th><span>{rowData.Address}</span></th>
                                                                    <th><span>{rowData.Email}</span></th>
                                                                    <th><span>{rowData.Website}</span></th>
                                                                    <th><span>{rowData.Other}</span></th>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </>
                                            )
                                        },
                                    }
                                ]}
                            />
                        </ThemeProvider>
                    </div>

                    <div id="ctl00_ContentPlaceHolder1_divPagination">
                        <div className="col-lg-12 col-md-12 col-12 cssPagination" style={{ boxShadow: '2px 2px 5px #808080' }}>
                            <nav aria-label="Page navigation">
                                <ul className="GridPager pagination" style={{ paddingTop: '2px' }}>
                                    <div style={{ marginTop: '0.5%', marginBottom: '0.5%', marginLeft: '3px' }}>
                                        <button type="button" onClick={setPreviousApi} className="btn btn-primary mt-12 mr-3 mobmt-2 page-disable" style={{ marginRight: '1.5%' }} >Previous </button>
                                    </div>
                                    <div style={{ marginTop: '0.5%', marginBottom: '0.5%', marginLeft: '3px' }} >
                                        <button type="button" onClick={setNextApi} className="btn btn-danger mt-12 mobmt-1 page-disable" style={{ marginRight: '1.5%' }}>Next </button>
                                    </div>
                                </ul>
                            </nav>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Viewcardtextrecognize
