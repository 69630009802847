import React, { useState, useEffect } from 'react'
import { Cookies, useCookies } from 'react-cookie'
import { Link, NavLink } from 'react-router-dom';

const Header = () => {

    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const [ckUserName, setckUserName] = useState(cookies.ckUserName);

    const [activeLink, setActiveLink] = useState('null');
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const handleSidebarToggle = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const handleLinkClick = (link) => {

        if (link === '/InvitationDetail' || link === '/GreetingDetail' || link === '/Other' || link === '/CalendarDetail') {
            setSidebarOpen(true);
        } else {
            setSidebarOpen(false);
        }

        if (link === '/addinvitation' || link === '/viewinvitation' ||
            link === '/viewschedule' || link === '/Sendinvitation' || link === '/viewsendinvitation' ||

            link === '/greetingwishes' || link === '/greetingdraft' || link === '/Viewdraft' ||
            link === '/CalenderEvent' || link === '/ViewCalendarEvent' ||

            link === '/addusermaster' || link === '/viewuser' ||
            link === '/bannermaster' || link === '/categorymaster' ||
            link === '/userdesignationmaster' || link === '/Designationmaster' ||
            link === '/viewcardtextrecognize'
        ) {
            setActiveLink('');
        } else {
            setActiveLink((prevLink) => (prevLink === link ? 'null' : link));
        }
    };



    const logoutHandler = () => {
        removeCookie('ckUserID')
        removeCookie('ckUserName')
        removeCookie('ckDesignationID')   
        window.location.href = "/splash"
    }

    return (
        <>
            <div className={`page-wrapper mini-sidebar ${sidebarOpen ? 'page-wrapper show-sidebar' : ''}`} id="main-wrapper" data-layout="horizontal" data-navbarbg="skin6" data-sidebartype={`mini-sidebar ${sidebarOpen ? 'full' : ''}`} data-sidebar-position="fixed" data-header-position="fixed">
                <header className="app-header">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <ul className="navbar-nav">
                            <li className="nav-item d-block d-lg-none">
                                <a className="nav-link sidebartoggler ms-n3" id="sidebarCollapse" onClick={handleSidebarToggle}>
                                    <i className="ti ti-menu-2" />
                                </a>
                            </li>

                            <li className="nav-item d-none d-lg-block">
                                <a href="" className="text-nowrap nav-link">
                                    <h5 style={{ color: '#B71C1C' }}>KP Group</h5>
                                </a>
                            </li>
                        </ul>

                        <div className="d-block d-lg-none">
                            <a className="text-nowrap">
                                <h5 style={{ color: '#B71C1C' }}>KP Group</h5>
                            </a>
                        </div>

                        <button className="navbar-toggler p-0 border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="p-2">
                                <i className="ti ti-dots fs-7" />
                            </span>
                        </button>

                        <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                            <div className="d-flex align-items-center justify-content-between px-0 px-lg-8">
                                <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-center">
                                    <li className="nav-item dropdown">
                                        <a className="nav-link pe-0" href="" id="drop2" data-bs-toggle="dropdown" aria-expanded="false">
                                            <div className="d-flex align-items-center">
                                                <div className="user-profile-img">
                                                    <img src="../dist/images/profile/kp.jpg" className="rounded-Square" width={50} height={50} alt="true" />
                                                </div>
                                            </div>
                                        </a>
                                        <div className="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up" aria-labelledby="drop2">

                                            <div className="d-flex align-items-center border-bottom">
                                                <img src="../../dist/images/profile/kp.jpg" className="rounded-circle" width={100} height={100} alt="true" style={{ textAlign: 'center' }} />
                                            </div>

                                            <div className="d-grid py-4 px-7 pt-8">
                                                <a href="/splash" className="btn btn-outline-primary" onClick={logoutHandler}>Log Out</a>
                                            </div>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>
                </header>

                <aside className="left-sidebar">
                    <nav className="sidebar-nav scroll-sidebar">
                        <ul id="sidebarnav" className="in">

                            {/* Invitation Detail */}
                            <li className="nav-small-cap">
                                <i className="ti ti-dots nav-small-cap-icon fs-4" />
                                <span className="hide-menu">Invitation Detail</span>
                            </li>

                            <li className="sidebar-item">
                                <a
                                    className={`sidebar-link has-arrow ${activeLink === '/InvitationDetail' ? 'active' : ''}`}
                                    aria-expanded={activeLink === '/InvitationDetail'}
                                    onClick={() => handleLinkClick('/InvitationDetail')}
                                >
                                    <span>
                                        <i className="ti ti-notebook" />
                                    </span>
                                    <span className="hide-menu">Invitation Detail</span>
                                </a>
                                <ul
                                    aria-expanded={activeLink === '/InvitationDetail'}
                                    className={`collapse first-level ${activeLink === '/InvitationDetail' ? 'in' : ''}`}
                                >
                                    <li className="sidebar-item">
                                        <NavLink
                                            to="/addinvitation"
                                            className={`sidebar-link ${activeLink === '/addinvitation' ? 'active' : ''}`}
                                            onClick={() => handleLinkClick('/addinvitation')}
                                        >
                                            <i className="ti ti-circle" />
                                            <span className="hide-menu">Add Invitation</span>
                                        </NavLink>
                                    </li>

                                    <li className="sidebar-item">
                                        <NavLink
                                            to="/viewinvitation"
                                            className={`sidebar-link ${activeLink === '/viewinvitation' ? 'active' : ''}`}
                                            onClick={() => handleLinkClick('/viewinvitation')}
                                        >
                                            <i className="ti ti-circle" />
                                            <span className="hide-menu">View Invitation</span>
                                        </NavLink>
                                    </li>

                                    <li className="sidebar-item">
                                        <NavLink
                                            to="/viewschedule"
                                            className={`sidebar-link ${activeLink === '/viewschedule' ? 'active' : ''}`}
                                            onClick={() => handleLinkClick('/viewschedule')}
                                        >
                                            <i className="ti ti-circle" />
                                            <span className="hide-menu">View Schedule</span>
                                        </NavLink>
                                    </li>

                                    <li className="sidebar-item">
                                        <NavLink
                                            to="/Sendinvitation"
                                            className={`sidebar-link ${activeLink === '/Sendinvitation' ? 'active' : ''}`}
                                            onClick={() => handleLinkClick('/Sendinvitation')}
                                        >
                                            <i className="ti ti-circle" />
                                            <span className="hide-menu">Send Invitation</span>
                                        </NavLink>
                                    </li>

                                    <li className="sidebar-item">
                                        <NavLink
                                            to="/viewsendinvitation"
                                            className={`sidebar-link ${activeLink === '/viewsendinvitation' ? 'active' : ''}`}
                                            onClick={() => handleLinkClick('/viewsendinvitation')}
                                        >
                                            <i className="ti ti-circle" />
                                            <span className="hide-menu">View Send Invitation</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>

                            {/* Contact Detail */}
                            <li className="nav-small-cap">
                                <i className="ti ti-dots nav-small-cap-icon fs-4" />
                                <span className="hide-menu">Contact Detail</span>
                            </li>

                            <li className="sidebar-item">
                                <a className={"sidebar-link sidebar-link" + (window.location.pathname === "/contactdetail" ? "sidebar-link sidebar-link active" : "")} href="/contactdetail" aria-expanded="false">
                                    <span className="rounded-3">
                                        <i className="ti ti-file-text" />
                                    </span>
                                    <span className="hide-menu">Contact Detail</span>
                                </a>
                            </li>

                            {/* CalendarEvent Detail */}
                            <li className="nav-small-cap">
                                <i className="ti ti-dots nav-small-cap-icon fs-4" />
                                <span className="hide-menu">Calendar Detail</span>
                            </li>

                            <li className="sidebar-item">
                                <a
                                    className={`sidebar-link has-arrow ${activeLink === '/CalendarDetail' ? 'active' : ''}`}
                                    aria-expanded={activeLink === '/CalendarDetail'}
                                    onClick={() => handleLinkClick('/CalendarDetail')}
                                >
                                    <span>
                                        <i className="ti ti-notebook" />
                                    </span>
                                    <span className="hide-menu">Calendar Detail</span>
                                </a>

                                <ul
                                    aria-expanded={activeLink === '/CalendarDetail'}
                                    className={`collapse first-level ${activeLink === '/CalendarDetail' ? 'in' : ''}`}
                                >

                                    <li className="sidebar-item">
                                        <NavLink
                                            to="/CalenderEvent"
                                            className={`sidebar-link ${activeLink === '/CalenderEvent' ? 'active' : ''}`}
                                            onClick={() => handleLinkClick('/CalenderEvent')}
                                        >
                                            <i className="ti ti-circle" />
                                            <span className="hide-menu">Add Calendar Event</span>
                                        </NavLink>
                                    </li>

                                    <li className="sidebar-item">
                                        <NavLink
                                            to="/ViewCalendarEvent"
                                            className={`sidebar-link ${activeLink === '/ViewCalendarEvent' ? 'active' : ''}`}
                                            onClick={() => handleLinkClick('/ViewCalendarEvent')}
                                        >
                                            <i className="ti ti-circle" />
                                            <span className="hide-menu">View Calendar Event</span>
                                        </NavLink>
                                    </li>

                                </ul>
                            </li>

                            {/* Contact Card */}
                            <li className="nav-small-cap">
                                <i className="ti ti-dots nav-small-cap-icon fs-4" />
                                <span className="hide-menu">Contact Card</span>
                            </li>

                            <li className="sidebar-item">
                                <a className={"sidebar-link sidebar-link" + (window.location.pathname === "/ContactCard" ? "sidebar-link sidebar-link active" : "")} href="/ContactCard" aria-expanded="false">
                                    <span className="rounded-3">
                                        <i className="ti ti-file-text" />
                                    </span>
                                    <span className="hide-menu">Contact Card</span>
                                </a>
                            </li>

                            {/* Greeting Detail */}
                            <li className="nav-small-cap">
                                <i className="ti ti-dots nav-small-cap-icon fs-4" />
                                <span className="hide-menu">Greeting Detail</span>
                            </li>

                            <li className="sidebar-item">
                                <a
                                    className={`sidebar-link has-arrow ${activeLink === '/GreetingDetail' ? 'active' : ''}`}
                                    aria-expanded={activeLink === '/GreetingDetail'}
                                    onClick={() => handleLinkClick('/GreetingDetail')}
                                >
                                    <span>
                                        <i className="ti ti-notebook" />
                                    </span>
                                    <span className="hide-menu">Greeting Detail</span>
                                </a>

                                <ul
                                    aria-expanded={activeLink === '/GreetingDetail'}
                                    className={`collapse first-level ${activeLink === '/GreetingDetail' ? 'in' : ''}`}
                                >

                                    <li className="sidebar-item">
                                        <NavLink
                                            to="/greetingdraft"
                                            className={`sidebar-link ${activeLink === '/greetingdraft' ? 'active' : ''}`}
                                            onClick={() => handleLinkClick('/greetingdraft')}
                                        >
                                            <i className="ti ti-circle" />
                                            <span className="hide-menu">Greeting Draft</span>
                                        </NavLink>
                                    </li>

                                    <li className="sidebar-item">
                                        <NavLink
                                            to="/greetingwishes"
                                            className={`sidebar-link ${activeLink === '/greetingwishes' ? 'active' : ''}`}
                                            onClick={() => handleLinkClick('/greetingwishes')}
                                        >
                                            <i className="ti ti-circle" />
                                            <span className="hide-menu">Greeting Wishes</span>
                                        </NavLink>
                                    </li>

                                    <li className="sidebar-item">
                                        <NavLink
                                            to="/Viewdraft"
                                            className={`sidebar-link ${activeLink === '/Viewdraft' ? 'active' : ''}`}
                                            onClick={() => handleLinkClick('/Viewdraft')}
                                        >
                                            <i className="ti ti-circle" />
                                            <span className="hide-menu">View Draft</span>
                                        </NavLink>
                                    </li>


                                </ul>
                            </li>
                                                        

                            {/* Festival Detail */}
                            <li className="nav-small-cap">
                                <i className="ti ti-dots nav-small-cap-icon fs-4" />
                                <span className="hide-menu">Festival Detail</span>
                            </li>

                            <li className="sidebar-item">
                                <a className={"sidebar-link sidebar-link" + (window.location.pathname === "/FestivalMaster" ? "sidebar-link sidebar-link active" : "")} href="/FestivalMaster" aria-expanded="false">
                                    <span className="rounded-3">
                                        <i className="ti ti-wand" />
                                    </span>
                                    <span className="hide-menu">Festival Detail</span>
                                </a>
                            </li>

                            {/* Today BirthDay */}
                            <li className="nav-small-cap">
                                <i className="ti ti-dots nav-small-cap-icon fs-4" />
                                <span className="hide-menu">Today BirthDay</span>
                            </li>

                            <li className="sidebar-item">
                                <a className={"sidebar-link sidebar-link" + (window.location.pathname === "/Viewuserbirthdaydata" ? "sidebar-link sidebar-link active" : "")} href="/Viewuserbirthdaydata" aria-expanded="false">
                                    <span className="rounded-3">
                                        <i className="ti ti-gift" />
                                    </span>
                                    <span className="hide-menu">Today BirthDay</span>
                                </a>
                            </li>


                            {/* Others */}
                            <li className="nav-small-cap">
                                <i className="ti ti-dots nav-small-cap-icon fs-4" />
                                <span className="hide-menu">Others</span>
                            </li>

                            <li className="sidebar-item">
                                <a
                                    className={`sidebar-link has-arrow ${activeLink === '/Other' ? 'active' : ''}`}
                                    aria-expanded={activeLink === '/Other'}
                                    onClick={() => handleLinkClick('/Other')}
                                >
                                    <span>
                                        <i className="ti ti-notebook" />
                                    </span>
                                    <span className="hide-menu">Other</span>
                                </a>
                                <ul
                                    aria-expanded={activeLink === '/Other'}
                                    className={`collapse first-level ${activeLink === '/Other' ? 'in' : ''}`}
                                >

                                    <li className="sidebar-item">
                                        <NavLink
                                            to="/addusermaster"
                                            className={`sidebar-link ${activeLink === '/addusermaster' ? 'active' : ''}`}
                                            onClick={() => handleLinkClick('/addusermaster')}
                                        >
                                            <i className="ti ti-circle" />
                                            <span className="hide-menu">Add User Master</span>
                                        </NavLink>
                                    </li>

                                    <li className="sidebar-item">
                                        <NavLink
                                            to="/viewuser"
                                            className={`sidebar-link ${activeLink === '/viewuser' ? 'active' : ''}`}
                                            onClick={() => handleLinkClick('/viewuser')}
                                        >
                                            <i className="ti ti-circle" />
                                            <span className="hide-menu">View User Master</span>
                                        </NavLink>
                                    </li>

                                    {/* <li className="sidebar-item">
                                        <NavLink
                                            to="/bannermaster"
                                            className={`sidebar-link ${activeLink === '/bannermaster' ? 'active' : ''}`}
                                            onClick={() => handleLinkClick('/bannermaster')}
                                        >
                                            <i className="ti ti-circle" />
                                            <span className="hide-menu">Banner Master</span>
                                        </NavLink>
                                    </li> */}

                                    <li className="sidebar-item">
                                        <NavLink
                                            to="/userdesignationmaster"
                                            className={`sidebar-link ${activeLink === '/userdesignationmaster' ? 'active' : ''}`}
                                            onClick={() => handleLinkClick('/userdesignationmaster')}
                                        >
                                            <i className="ti ti-circle" />
                                            <span className="hide-menu"> User Designation Master</span>
                                        </NavLink>
                                    </li>

                                    <li className="sidebar-item">
                                        <NavLink
                                            to="/Designationmaster"
                                            className={`sidebar-link ${activeLink === '/Designationmaster' ? 'active' : ''}`}
                                            onClick={() => handleLinkClick('/Designationmaster')}
                                        >
                                            <i className="ti ti-circle" />
                                            <span className="hide-menu">Designation Master</span>
                                        </NavLink>
                                    </li>


                                    <li className="sidebar-item">
                                        <NavLink
                                            to="/categorymaster"
                                            className={`sidebar-link ${activeLink === '/categorymaster' ? 'active' : ''}`}
                                            onClick={() => handleLinkClick('/categorymaster')}
                                        >
                                            <i className="ti ti-circle" />
                                            <span className="hide-menu">Category Master</span>
                                        </NavLink>
                                    </li>

                                    <li className="sidebar-item">
                                        <NavLink
                                            to="/viewcardtextrecognize"
                                            className={`sidebar-link ${activeLink === '/viewcardtextrecognize' ? 'active' : ''}`}
                                            onClick={() => handleLinkClick('/viewcardtextrecognize')}
                                        >
                                            <i className="ti ti-circle" />
                                            <span className="hide-menu">Card Text Recognize</span>
                                        </NavLink>
                                    </li>

                                </ul>
                            </li>
                        </ul>
                    </nav>
                </aside>
            </div>
        </>
    )
}

export default Header
