import React, { useState, useRef, useEffect } from 'react'
import { FormWithConstraints, FieldFeedbacks, FieldFeedback, } from 'react-form-with-constraints';
import { ThemeProvider, createTheme } from '@mui/material';
import MaterialTable from 'material-table';
import DatePicker from "react-datepicker";
import { allowOnlyNumbers, allowOnlyCharacters } from '../inputvalidation';
import services from "../Services/Services";
import Swal from "sweetalert2";
import moment from 'moment';
import XLSX from "xlsx-color";
import { MdDelete } from 'react-icons/md'


const defaultMaterialTheme = createTheme();

const Viewschedule = () => {

    const handleExcelExport = async (st) => {
        var NM = "";
        var MNo = "";

        if (ContactName != "") {
            NM = ContactName
        }

        if (ContactMobileNo != "") {
            MNo = ContactMobileNo
        }

        var data = JSON.stringify({
            "InvitationID": "",
            "ContactName": st == "cancle" ? "" : NM,
            "ContactMobileNo": st == "cancle" ? "" : MNo,
            "InvitationStatus": "Confirm",
            "Details": "",
            "EntryDate": st == "cancle" ? "" : moment(EntryDate, "DD-MM-YYYY"),
            "StartDate": st == "cancle" ? "" : moment(StartDate, "DD-MM-YYYY"),
            "EndDate": st == "cancle" ? "" : moment(EndDate, "DD-MM-YYYY"),
            "PaginationID": "0",
            "PageLength": `${pageCount}`
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "Invitation/GetInvitation",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                exportToExcel(response.data.data)
            }
        }, error => { })
    };
    const exportToExcel = (array) => {
        console.log(array, 'data');
        debugger;
        const header = ["Sr.No", "NatureOfProgram", "InvitationStartDate", "InvitationEndDate", "InvitationStartTime", "InvitationEndTime", "Venue", "Details", "ContactName", "ContactMobileNo", "ReferenceName", "ReferenceMobileNo", "InvitationStatus", "ConfirmDate",];
        const body = [];
        array?.forEach((col, index) => {
            body.push([index + 1, col.NatureOfProgram, col.InvitationStartDate, col.InvitationEndDate, col.InvitationStartTime, col.InvitationEndTime, col.Venue, col.Details, col.ContactName, col.ContactMobileNo, col.ReferenceName, col.ReferenceMobileNo, col.InvitationStatus, col.ConfirmDate]);
        });

        if (!body.length) {
            return Swal.fire({
                title: "No Data Found!",
                icon: "error",
                timer: 1500
            });
        }

        const ws1 = XLSX.utils.aoa_to_sheet([header, ...body]);

        header.forEach((col, index) => {
            ws1[XLSX.utils.encode_cell({ r: 0, c: index })].s = {

                font: { bold: true, color: { rgb: "f7f4ef" } }, // White font color
                fill: { patternType: 'solid', fgColor: { rgb: "#B71C1C" } }, // Blue background color
                alignment: { wrapText: false, horizontal: 'center' },
            };
        });


        ws1['!cols'] = [
            { width: 6 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 40 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 20 },

        ];

        let cell = {
            font: { bold: true, color: { rgb: "f7f4ef" } },
            fill: { patternType: 'solid', fgColor: { rgb: "#B71C1C" } },
            alignment: { wrapText: false, horizontal: 'center' },
            border: {
                left: { style: 'thin', color: { rgb: '000000' } },
                right: { style: 'thin', color: { rgb: '000000' } },
                top: { style: 'thin', color: { rgb: '000000' } },
                bottom: { style: 'thin', color: { rgb: '000000' } }
            }
        }
        ws1['A1'].s = cell;
        ws1['B1'].s = cell;
        ws1['C1'].s = cell;
        ws1['D1'].s = cell;
        ws1['E1'].s = cell;
        ws1['F1'].s = cell;
        ws1['G1'].s = cell;
        ws1['H1'].s = cell;
        ws1['I1'].s = cell;
        ws1['J1'].s = cell;
        ws1['K1'].s = cell;
        ws1['L1'].s = cell;
        ws1['M1'].s = cell;
        ws1['N1'].s = cell;


        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws1, 'ScheduleDetail');

        // Save the workbook
        XLSX.writeFile(wb, `ScheduleDetail_${moment().format('DD-MM-YYYY_HH:mm')}.xlsx`);

    };

    const headerTblStyle = { color: 'Black' };

    const cols = [
        { title: (<div style={{ color: 'white', width: '9px' }}><span>Sr.No</span></div>), field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{(pageId * pageCount - 10) + rowData.tableData.id + 1}</p>) } },
        { title: (<div style={{ width: '100%', color: 'white' }}><span> Nature Of Program</span></div>), width: '8%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '100px' }}>{rowData.NatureOfProgram}</p>) } },
        { title: (<div style={{ width: '150%', color: 'white' }}><span>Contact Name</span><hr style={{ marginTop: '1px', marginBottom: '1px' }} /><span>Contact MobileNo</span></div>), width: '8%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '110px' }}><div>{rowData.ContactName}</div>{rowData.ContactName && <hr />}<div>{rowData.ContactMobileNo}</div>{rowData.ContactMobileNo && <hr />}</p>) } },
        { title: (<div style={{ width: '120%', color: 'white' }}><span>Reference Name</span><hr style={{ marginTop: '1px', marginBottom: '1px' }} /><span>Reference MobileNo</span></div>), width: '8%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '110px' }}><div>{rowData.ReferenceName}</div>{rowData.ReferenceName && <hr />}<div>{rowData.ReferenceMobileNo}</div>{rowData.ReferenceMobileNo && <hr />}</p>) } },
        { title: (<div style={{ width: '100%', color: 'white' }}><span> Venus</span></div>), width: '8%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '100px' }}>{rowData.Venue}</p>) } },
        {
            title: (<div style={{ width: '100%', color: 'white' }}><span>Invitation PDF</span></div>), width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => {
                return (
                    <p style={{ marginBottom: '1px', width: '20px' }}>
                        {
                            (rowData.PDF != "" && rowData.PDF != null) ?
                                <a href={profileUrl + rowData.PDF} target="_blank">
                                    <img
                                        style={{ height: '40px', width: '40px' }}
                                        src="Images/pdf2.png" alt="Image Not"></img>
                                </a>
                                : ''
                        }</p>)
            }
        },
        {
            title: (<div style={{ color: 'white', width: '100%' }}><span>Google Map URL</span></div>), width: '10%', removable: false, headerStyle: headerTblStyle,
            render: rowData => {
                const hasDirectionLink = rowData.GoogleMapUrl && rowData.GoogleMapUrl !== '';
                const googleMapUrl = hasDirectionLink ? addProtocolIfMissing(rowData.GoogleMapUrl) : '';

                return (
                    <p style={{ marginBottom: '1px', width: '30px' }}>
                        {hasDirectionLink && (
                            <>
                                <a href={googleMapUrl} target="_blank">
                                    <img
                                        style={{ height: '40px', width: '40px' }}
                                        src="Images/Link.png"
                                        alt="Image Not"
                                        onClick={() => window.open(googleMapUrl, '_blank')}
                                    />
                                </a>
                            </>
                        )}

                    </p>
                );
            }
        },
        { title: (<div style={{ width: '100%', color: 'white' }}><span>InvitationStatus</span><hr style={{ marginTop: '1px', marginBottom: '1px' }} /><span>ConfirmDate</span></div>), width: '8%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}><div>{rowData.InvitationStatus}</div>{rowData.InvitationStatus && <hr />}<div>{rowData.ConfirmDate}</div>{rowData.ConfirmDate && <hr />}</p>) } },
        {
            title: (<div style={{ width: '125%', color: 'white' }}><span>Invitation Start Date</span><hr style={{ marginTop: '1px', marginBottom: '1px' }} /><span>Invitation End Date</span><hr style={{ marginTop: '1px', marginBottom: '1px' }} /><span>Start Time</span><hr style={{ marginTop: '1px', marginBottom: '1px' }} /><span>End Time</span></div>), width: '8%', removable: false, headerStyle: headerTblStyle, render: rowData => {
                return (
                    <p style={{ marginBottom: '1px', width: '100px' }}>
                        <div>{rowData.InvitationStartDate}</div>{rowData.InvitationStartDate && <hr />}
                        <div>{rowData.InvitationEndDate}</div>{rowData.InvitationEndDate && <hr />}
                        <div>{rowData.InvitationStartTime}</div>{rowData.InvitationStartTime && <hr />}
                        <div>{rowData.InvitationEndTime}</div>
                    </p>)
            }
        },
        { title: (<div style={{ width: '100%', color: 'white' }}><span>Date</span></div>), width: '8%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '75px' }}>{rowData.CreatedDate}</p>) } },
    ]

    const addProtocolIfMissing = (url) => {
        if (!/^(https?:\/\/)/i.test(url)) {
            return 'http://' + url;
        }
        return url;
    };
    const profileUrl = process.env.REACT_APP_Invitation_URL;
    const imageUrl = process.env.REACT_APP_Invitation_Image_URL;

    const [ContactName, setContactName] = useState('')
    const [ContactMobileNo, setContactMobileNo] = useState('')

    const [StartDate, setStartDate] = useState('')
    const [EndDate, setEndDate] = useState('')
    const [dateError, setDateError] = useState("");

    const [EntryDate, setEntryDate] = useState('')

    const [iconFilter, seticonFilter] = useState('fa fa-plus')
    const [isfiltervisible, setFilter] = useState(false)

    const [pageCount, setPageCount] = useState(10);
    const [pageId, setPageId] = useState(1);
    const [tableData, setTableData] = useState([]);
    const [allTableData, setAllTableData] = useState([]);
    const [TotalCount, setTotalCount] = useState('')

    const form = useRef(FormWithConstraints);

    useEffect(() => {
        GetInvitation(pageCount);
    }, []);

    const GetInvitation = (pageCount, st) => {
        debugger;

        var NM = "";
        var MNo = "";

        if (ContactName != "") {
            NM = ContactName
        }

        if (ContactMobileNo != "") {
            MNo = ContactMobileNo
        }

        var data = JSON.stringify({
            "InvitationID": "",
            "ContactName": st == "cancle" ? "" : NM,
            "ContactMobileNo": st == "cancle" ? "" : MNo,
            "InvitationStatus": "Confirm",
            "Details": "",
            "EntryDate": st == "cancle" ? "" : moment(EntryDate, "DD-MM-YYYY"),
            "StartDate": st == "cancle" ? "" : moment(StartDate, "DD-MM-YYYY"),
            "EndDate": st == "cancle" ? "" : moment(EndDate, "DD-MM-YYYY"),
            "PaginationID": "0",
            "PageLength": `${pageCount}`
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "Invitation/GetInvitation",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            console.log(response.data, 'data')
            if (response.data.status) {
                setTableData(response.data.data)
                setAllTableData(response.data.data)
                setPageId(1)
                setTotalCount(response.data.total[0].TotalCount);
            }
            else {
                setTableData([]);
            }
        }, error => { })
    }

    const setPreviousApi = () => {
        debugger;
        if (pageId == 1) {
            return
        }
        const tempt = [...allTableData]
        console.log((pageId - 2) * pageCount, (pageId - 1) * pageCount, 'alltabledata')
        const sliced = tempt.slice((pageId - 2) * pageCount, (pageId - 1) * pageCount)

        setTableData(sliced)
        setPageId(pageId - 1)

    };

    const setNextApi = () => {
        debugger;

        if (!tableData.length) {
            return;
        }
        if (pageId * pageCount < allTableData.length) {
            const tempt = [...allTableData];
            const sliced = tempt.slice((pageId) * pageCount, (pageId + 1) * pageCount);
            setTableData(sliced);
            setPageId(pageId + 1);
            return;
        }

        const paginationIndex = allTableData[pageId * pageCount - 1]?.InvitationID;
        if (!paginationIndex) {
            return;
        }


        var data = JSON.stringify({

            "InvitationID": "",
            "ContactName": "",
            "ContactMobileNo": "",
            "InvitationStatus": "",
            "Details": "",
            "EntryDate": "",
            "StartDate": "",
            "EndDate": "",
            "PaginationID": paginationIndex,
            "PageLength": `${pageCount}`
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "Invitation/GetInvitation",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        services.JsonValue(config).then(response => {
            if (response.data.status) {
                setTableData(response.data.data)
                setPageId(pageId + 1)
                setAllTableData([...allTableData, ...response.data.data])
                setTotalCount(response.data.total[0].TotalCount);
            }
        }, error => { })
    };

    const HandalContactname = (e) => {
        setContactName(e.target.value);
    };

    const HandleMobileNo = (e) => {
        setContactMobileNo(e.target.value)
    }

    const handleInvitationStartchange = (date) => setStartDate(date);
    const handleEndDatechange = (date) => setEndDate(date);
    const handleEntryDatechange = (date) => setEntryDate(date);

    const onSearchClick = (e) => {
        e.preventDefault();
        if (StartDate && EndDate && StartDate > EndDate) {
            setDateError('Start Date Must Be Less Than End date');
        }
        GetInvitation(pageCount);

    }

    const OnSearchCancelClick = () => {
        setContactName('')
        setContactMobileNo('')
        setStartDate('')
        setEndDate('')
        setDateError('')
        setEntryDate('')
        GetInvitation(pageCount, "cancle");
    }

    const ExcelClick = () => {
        handleExcelExport();
    }

    const OnFilterClick = (e) => {
        e.preventDefault();
        if (isfiltervisible === false) {
            setFilter(true);
            seticonFilter("fa fa-minus");
        }
        else {
            setFilter(false);
            seticonFilter("fa fa-plus");
        }
    }

    const CheckAlert = (id) => {
        Swal.fire({
            title: 'Are you Sure You Want to Delete?', icon: "warning", showCancelButton: true, confirmButtonText: 'Delete', cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {

                var data = JSON.stringify({
                    "InvitationID": id
                });
                var config = {
                    method: 'POST',
                    url: services.API_URL + "Invitation/DeleteInvitation",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    if (response.data.status) {
                        GetInvitation(pageCount);
                        Swal.fire({
                            title: 'Successfully Deleted', icon: "success", width: "400px", showConfirmButton: false, timer: 1500
                        });
                    } else {
                        Swal.fire({ position: 'top-end', icon: 'error', title: response.data.message, showConfirmButton: false, timer: 1500 });
                    }

                }, error => { })
            } else if (result.isDenied) {
                Swal.close();
            }
        });
    }

    const HandleImagedelete = (ImageDetailID, Image) => {

        Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                var data = JSON.stringify({
                    "ImageDetailID": ImageDetailID,
                    "Image": Image,
                });

                var config = {
                    method: 'POST',
                    url: services.API_URL + "ImageDetail/DeleteImages",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };

                services.JsonValue(config).then(response => {
                    if (response.data.status) {
                        GetInvitation(pageCount);
                        Swal.fire({
                            title: 'Successfully Deleted',
                            icon: "success",
                            width: "400px",
                            showConfirmButton: false,
                            timer: 1500
                        });
                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 1500
                        });
                    }
                }).catch(error => {
                    console.error("Error deleting image:", error);
                });
            }
        });
    };

    return (
        <>
            <div className="body-wrapper">
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <FormWithConstraints
                                ref={form}
                                onSubmit={OnFilterClick}
                                noValidate>

                                {isfiltervisible &&
                                    <div className="card">
                                        <div className="px-3 py-2 border-bottom">
                                            <h5>Searching</h5>
                                        </div>

                                        <div className="card-body">
                                            <div className='row'>

                                                <div className='col-md-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="ContactName" className="form-label fw-semibold">Contact Name</label>
                                                        <input type="ContactName" onChange={HandalContactname} required value={ContactName} onKeyPress={allowOnlyCharacters} type1="textnm" className="form-control" placeholder="Enter Contact Name"></input>
                                                    </div><br></br>
                                                </div>

                                                <div className='col-md-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="ContactMobileNo" className="form-label fw-semibold">Contact Mobile No</label>
                                                        <input type="ContactMobileNo" onChange={HandleMobileNo} required value={ContactMobileNo} maxLength={10} onKeyPress={allowOnlyNumbers} type1="textnm" className="form-control" placeholder="Enter Contact Mobile No"></input>
                                                    </div><br></br>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label htmlFor="Name" className="form-label fw-semibold">Invitation Start Date</label>
                                                        <DatePicker
                                                            dateFormat="dd-MM-yyyy"
                                                            selected={StartDate}
                                                            autoComplete="off"
                                                            className="form-control readonly"
                                                            id="txtTaskDate"
                                                            value={StartDate}
                                                            placeholderText="dd-MM-yyyy"
                                                            onChange={handleInvitationStartchange}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                        />
                                                    </div><br></br>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label htmlFor="Name" className="form-label fw-semibold">Invitation End Date</label>
                                                        <DatePicker
                                                            dateFormat="dd-MM-yyyy"
                                                            selected={EndDate}
                                                            autoComplete="off"
                                                            className="form-control readonly"
                                                            id="txtTaskDate"
                                                            value={EndDate}
                                                            placeholderText="dd-MM-yyyy"
                                                            onChange={handleEndDatechange}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                        />
                                                        {dateError && <p style={{ color: 'red' }}>{dateError}</p>}
                                                    </div>
                                                </div>

                                                <div className="col-md-3" style={{ marginTop: '25px' }}>
                                                    <button type="button" onClick={onSearchClick} className="btn btn-primary mt-12 mr-3 mobmt-2" style={{ marginRight: '5px', marginTop: '5px' }} >Search </button>
                                                    <button type="button" onClick={OnSearchCancelClick} className="btn btn-danger mt-12 mobmt-1" style={{ marginRight: '5px', marginTop: '5px' }}>Cancel </button>
                                                    <a className="btn btn-outline-success font-weight-bolder mt-12" onClick={ExcelClick} style={{ marginRight: '5px', marginTop: '5px' }}>
                                                        <i id="btnExcel" className="fa fa-file-excel" /> Excel
                                                    </a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                }
                            </FormWithConstraints>
                        </div>

                        <div className='col-md-12'>
                            <ThemeProvider theme={defaultMaterialTheme}>

                                <MaterialTable columns={cols} data={tableData}
                                    actions={[
                                        {
                                            icon: 'delete', tooltip: 'Delete', onClick: (e, r) => CheckAlert(r.InvitationID, r.UserName)
                                        }
                                    ]}

                                    components={{
                                        Toolbar: props => (
                                            <div className="row" >
                                                <div className="col-md-10.5" style={{ display: 'flex', alignItems: 'center', marginLeft: '0.5%', marginTop: '0.5%', marginBottom: '0.5%' }}>
                                                    <h5 style={{ marginLeft: '5px' }}>View Schedule Detail : <span className="badge bg-light-primary text-black fw-semibold fs-2" >{TotalCount}</span></h5>

                                                    <div className="col-md-10" style={{ textAlign: 'right' }}>
                                                        <a className="btn btn-outline-primary font-weight-bolder" style={{ marginLeft: '75px', marginRight: '4px' }} onClick={OnFilterClick}>
                                                            <i id="btnFilter" className={iconFilter} isfiltervisible='false' /> Filter
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }}

                                    detailPanel={[
                                        {
                                            icon: '',
                                            tooltip: 'Image Detail',
                                            render: rowData => {
                                                return (
                                                    <>
                                                        <div style={{ border: '1px' }}>
                                                            <table className="table table-light-dark" align="center" cellSpacing="0" rules="all" border="1" style={{ width: '100%', bordercollapse: 'collapse', border: '1px solid black' }}>
                                                                <thead>
                                                                    <tr>
                                                                        <td style={{ backgroundColor: '#B71C1C', border: '1px solid black', color: 'white', fontWeight: 'bold' }}>Invitation Images</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th>

                                                                            <span style={{ display: "flex" }}>
                                                                                {JSON.parse(rowData.ImageDetail)?.length > 0 ? (
                                                                                    JSON.parse(rowData.ImageDetail)?.map((image, index) => (
                                                                                        <div key={index} style={{ position: 'relative' }}>
                                                                                            <a href={imageUrl + image.Image} target="_blank" rel="noopener noreferrer">
                                                                                                <img
                                                                                                    src={imageUrl + image.Image}
                                                                                                    alt={`Image ${index + 1}`}
                                                                                                    style={{ width: '80px', height: '80px', marginRight: '5px', border: '1px solid black' }}
                                                                                                    onError={(e) => {
                                                                                                        console.error(`Error loading image ${index + 1}:`, e);
                                                                                                    }}
                                                                                                />
                                                                                            </a>
                                                                                            <MdDelete
                                                                                                onClick={() => HandleImagedelete(image.ImageDetailID, "Image")}
                                                                                                style={{ fontSize: '1.3em', position: 'absolute', zIndex: '2', left: '5px', top: '5px', cursor: 'pointer' }}
                                                                                            />
                                                                                        </div>
                                                                                    ))
                                                                                ) : (
                                                                                    <div>
                                                                                        <img
                                                                                            src="Images/Noimage.png"
                                                                                            alt="No Image Available"
                                                                                            style={{ width: '80px', height: '80px', marginRight: '5px', border: '1px solid black' }}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </span>
                                                                        </th>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div style={{ border: '1px' }}>
                                                            <table className="table table-light-dark" align="center" cellSpacing="0" rules="all" border="1" style={{ width: '100%', bordercollapse: 'collapse', border: '1px solid black' }}>
                                                                <thead>
                                                                    <tr>
                                                                        <td style={{ backgroundColor: '#B71C1C', color: 'white', border: '1px solid black', color: 'white', fontWeight: 'bold' }}>Details</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th><span>{rowData.Details}</span></th>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </>
                                                )
                                            },
                                        }
                                    ]}

                                    options={{
                                        headerStyle: { backgroundColor: '#B71C1C', color: 'white', fontFamily: 'system-ui' }, toolbar: true, actionsColumnIndex: -1,
                                        paging: false, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                                    }}

                                />
                            </ThemeProvider>
                        </div>
                    </div>

                    <div id="ctl00_ContentPlaceHolder1_divPagination">
                        <div className="col-lg-12 col-md-12 col-12 cssPagination" style={{ boxShadow: '2px 2px 5px #808080' }}>
                            <nav aria-label="Page navigation">
                                <ul className="GridPager pagination" style={{ paddingTop: '2px' }}>
                                    <div style={{ marginTop: '0.5%', marginBottom: '0.5%', marginLeft: '3px' }}>
                                        <button type="button" onClick={setPreviousApi} className="btn btn-primary mt-12 mr-3 mobmt-2 page-disable" style={{ marginRight: '1.5%' }} >Previous </button>
                                    </div>
                                    <div style={{ marginTop: '0.5%', marginBottom: '0.5%', marginLeft: '3px' }} >
                                        <button type="button" onClick={setNextApi} className="btn btn-danger mt-12 mobmt-1 page-disable" style={{ marginRight: '1.5%' }}>Next </button>
                                    </div>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Viewschedule




