import React from "react";
import { useEffect, useState } from "react"
import { useLocation } from 'react-router-dom'


const MybeshowNavbar = ({ children }) => {

	const location = useLocation();
	const [showNavbar, setshowNavbar] = useState(false);

	useEffect(() => {

		// console.log('this is location:', location) 
		if (location.pathname === '/splash' || location.pathname === '/privacyandpolicy') {
			setshowNavbar(false)
		}
		else {
			setshowNavbar(true)
		}
	}, [location])


	return (
		<div>
			{showNavbar && children}
		</div>
	)
}

export default MybeshowNavbar
