export const allowOnlyCharacters = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("^[a-zA-Z ]+$").test(keyValue)) event.preventDefault();
    return;
};

export const allowOnlyNumbers = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
};

export const allowOnlyNumbersGem = (e) => {
    var code = ("charCode" in e) ? e.charCode : e.keyCode;
    if (!(code > 47 && code < 58) && //numeric (0-9)
        !(code === 58)) { //colon character (:)
        e.preventDefault();
        return
    }
};