import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material';
import MaterialTable from 'material-table';
import { useState, useRef, useEffect } from "react";
import { FormWithConstraints, FieldFeedbacks, FieldFeedback, } from 'react-form-with-constraints';
import services from "../Services/Services";
import Swal from "sweetalert2";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from 'react-select'
import DOMPurify from "dompurify"
import { useCookies } from 'react-cookie';
import XLSX from "xlsx-color";
import moment from 'moment';
import { allowOnlyCharacters } from '../inputvalidation';

const defaultMaterialTheme = createTheme();

const Viewdraft = () => {

  const [cookies, setCookie] = useCookies(['user']);
  const [ckUserID, setckUserID] = useState(cookies.ckUserID);

  const headerTblStyle = { color: 'Black' };
  const cols = [
    { title: (<div style={{ color: 'white' }}><span>Sr.No</span></div>), field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{(pageId * pageCount - 10) + rowData.tableData.id + 1}</p>) } },
    { title: (<div style={{ color: 'white', width: '150%' }}><span>User Name</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '100px' }}>{rowData.UserName}</p>) } },
    { title: (<div style={{ color: 'white', width: '150%' }}><span>Draft</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '1000px' }}>{rowData.Draft}</p>) } },
  ]

  const handleExcelExport = async (pageCount, st) => {
    var UI = UserID;

    if (UserID.value === "--Select User Name--") {
      UI = "";
    }
    else {
      UI = UserID.value;
    }

    var data = JSON.stringify({
      "DraftID": "",
      "Draft": "",
      "UserID": st == "cancle" ? "" : UI,
      "UserName": "",
      "StartDate": "",
      "EndDate": "",
      "PaginationID": "0",
      "PageLength": `${pageCount}`
    });

    var config = {
      method: 'POST',
      url: services.API_URL + "Draft/GetDraft",
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    services.JsonValue(config).then(response => {
      if (response.data.status) {
        exportToExcel(response.data.data)
      }

    }, error => { })
  };

  const exportToExcel = (array) => {
    console.log(array, 'data');
    debugger;
    const header = ["Sr.No", "User Name", "Draft"];
    const body = [];
    array?.forEach((col, index) => {
      body.push([index + 1, col.UserName, col.Draft]);
    });

    if (!body.length) {
      return Swal.fire({
        title: "No Data Found!",
        icon: "error",
        timer: 1500
      });
    }

    const ws1 = XLSX.utils.aoa_to_sheet([header, ...body]);

    header.forEach((col, index) => {
      ws1[XLSX.utils.encode_cell({ r: 0, c: index })].s = {
        font: { bold: true, color: { rgb: "f7f4ef" } }, // White font color
        fill: { patternType: 'solid', fgColor: { rgb: "#B71C1C" } }, // Blue background color
        alignment: { wrapText: index === 3, horizontal: 'center' }, // Enable wrap text for the "Draft" column
      };
    });

    ws1['!cols'] = [
      { width: 6 },
      { width: 20 },
      { width: 50 },
    ];


    let cell = {
      font: { bold: true, color: { rgb: "f7f4ef" } },
      fill: { patternType: 'solid', fgColor: { rgb: "#B71C1C" } },
      alignment: { wrapText: true, horizontal: 'center' }, // Enable wrap text for the "Draft" column
      border: {
        left: { style: 'thin', color: { rgb: '000000' } },
        right: { style: 'thin', color: { rgb: '000000' } },
        top: { style: 'thin', color: { rgb: '000000' } },
        bottom: { style: 'thin', color: { rgb: '000000' } }
      }
    };
    ws1['A1'].s = cell;
    ws1['B1'].s = cell;
    ws1['C1'].s = cell;


    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, 'Draftdetail');

    XLSX.writeFile(wb, `Draftdetail_${moment().format('DD-MM-YYYY_HH:mm')}.xlsx`);
  };

  const [UserID, setUserID] = useState({ 'label': '--Select User Name--', 'value': '--Select User Name--' });
  const [Useres, getUser] = useState([])

  const [pageCount, setPageCount] = useState(10);
  const [pageId, setPageId] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [allTableData, setAllTableData] = useState([]);
  const [TotalCount, setTotalCount] = useState('')

  const [iconFilter1, seticonFilter1] = useState('fa fa-plus')
  const [isfiltervisible1, setFilter1] = useState(false)

  const [issubmitvisible, setIssubmitvisible] = useState(true)
  const [isUpdatevisible, setIsUpdatevisible] = useState(false)
  const ref = useRef();
  const [data, getData] = useState([])
  const form = useRef(FormWithConstraints);



  useEffect(() => {
    GetDraft(pageCount);
    GetUserMaster();
  }, []);

  const GetDraft = (pageCount, st) => {

    var UI = UserID;

    if (UserID.value === "--Select User Name--") {
      UI = "";
    }
    else {
      UI = UserID.value;
    }

    var data = JSON.stringify({
      "DraftID": "",
      "Draft": "",
      "UserID": st == "cancle" ? "" : UI,
      "UserName": "",
      "StartDate": "",
      "EndDate": "",
      "PaginationID": "0",
      "PageLength": `${pageCount}`
    });
    var config = {
      method: 'POST',
      url: services.API_URL + "Draft/GetDraft",
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    services.JsonValue(config).then(response => {
      if (response.data.status) {
        setTableData(response.data.data)
        setAllTableData(response.data.data)
        setPageId(1)
        setIssubmitvisible(true)
        setIsUpdatevisible(false)
        setTotalCount(response.data.total[0].TotalCount);
      }
      else {
        setTableData([]);
      }
    }, error => { })
  }

  const GetUserMaster = () => {
    var data = JSON.stringify({
      "UserID": "",
      "UserName": "",
      "MobileNo": "",
      "BirthDate": "",
      "StartDate": "",
      "EndDate": "",
      "PaginationID": "-1",
      "PageLength": ""
    });
    var config = {
      method: 'POST',
      url: services.API_URL + "User/GetUser",
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    services.JsonValue(config).then(response => {

      if (response.data.status) {
        getUser(response.data.data.map(item => ({ value: item.UserID, label: item.UserName })))
      }
      else {
        getUser([]);
      }
    }, error => { })
  }

  const onUserChange = (e) => {
    setUserID(e);
  }
  const setPreviousApi = () => {

    if (pageId == 1) {
      return
    }
    const tempt = [...allTableData]
    console.log((pageId - 2) * pageCount, (pageId - 1) * pageCount, 'alltabledata')
    const sliced = tempt.slice((pageId - 2) * pageCount, (pageId - 1) * pageCount)

    setTableData(sliced)
    setPageId(pageId - 1)

  };
  const setNextApi = () => {
    console.log(pageId, "pageID", pageCount)

    if (!tableData.length) {
      return;
    }
    if (pageId * pageCount < allTableData.length) {
      const tempt = [...allTableData];
      const sliced = tempt.slice((pageId) * pageCount, (pageId + 1) * pageCount);
      setTableData(sliced);
      setPageId(pageId + 1);
      return;
    }

    const paginationIndex = allTableData[pageId * pageCount - 1]?.DraftID;
    if (!paginationIndex) {
      return;
    }
    var data = JSON.stringify({
      "DraftID": "",
      "Draft": "",
      "UserID": "",
      "UserName": "",
      "StartDate": "",
      "EndDate": "",
      "PaginationID": paginationIndex,
      "PageLength": `${pageCount}`
    });
    var config = {
      method: 'POST',
      url: services.API_URL + "Draft/GetDraft",
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    services.JsonValue(config).then(response => {
      if (response.data.status) {
        setTableData(response.data.data)
        setPageId(pageId + 1)
        setAllTableData([...allTableData, ...response.data.data])
        setTotalCount(response.data.total[0].TotalCount);
      }

    }, error => { })
  };

  const CheckAlert = (id) => {
    Swal.fire({
      title: 'Are you Sure You Want to Delete?', icon: "warning", showCancelButton: true, confirmButtonText: 'Delete', cancelButtonText: 'Cancel'
    }).then((result) => {

      if (result.isConfirmed) {
        var data = JSON.stringify({
          "DraftID": id
        });
        var config = {
          method: 'POST',
          url: services.API_URL + "Draft/DeleteDraft",
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };
        services.JsonValue(config).then(response => {
          console.log(response.data.status, 'staus')
          if (response.data.status) {
            GetDraft(pageCount);
            Swal.fire({
              title: 'Successfully Deleted', icon: "success",  width:"400px", showConfirmButton: false, timer: 1500
            });
          } else {
            Swal.fire({ position: 'top-end', icon: 'error', title: response.data.message, showConfirmButton: false, timer: 1500 });
          }
       
        }, error => { })
      } else if (result.isDenied) {
        Swal.close();
      }
    });
  }
  
  const ExcelClick = () => {
    handleExcelExport(pageCount);
  }

  const OnFilterClick1 = (e) => {

    setFilter1(true);

    e.preventDefault();
    if (isfiltervisible1 === false) {
      setFilter1(true);
      seticonFilter1("fa fa-minus");
    }
    else {
      setFilter1(false);
      seticonFilter1("fa fa-plus");
    }
  }
  const onSearchClick = (e) => {
    e.preventDefault();
    GetDraft(pageCount)
  }

  const OnSearchCancelClick = () => {
    setUserID({ 'label': '--Select User Name--', 'value': '--Select User Name--' });
    GetDraft(pageCount, "cancle");
  }
  return (
    <>
      <div className="body-wrapper">
        <div className="container-fluid">
          
          {isfiltervisible1 &&
            <div className="card">
              <div className="border-bottom">
                <h4 style={{ marginLeft: '15px', marginTop: '5px' }}>Searching</h4>
              </div>
              <div className="card-body">

                <div className='row'>

                  <div className="col-md-3">
                    <div className="form-group ">
                      <label htmlFor="User" className="form-label fw-semibold">User Name </label>
                      <Select options={[{ label: '--Select User --', value: '--Select User --' }, ...Useres]} value={UserID} onChange={onUserChange} placeholder="--Select User --" />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <button type="button" onClick={onSearchClick} className="btn btn-outline-primary font-weight-bolder ml-2 " style={{ marginRight: '3%', marginTop: '28px' }} >Search </button>
                    <button type="button" onClick={OnSearchCancelClick} className="btn btn-outline-danger font-weight-bolder ml-2" style={{ marginRight: '1%', marginTop: '28px' }}>Cancel </button>
                    <a className="btn btn-outline-success font-weight-bolder mt-12" onClick={ExcelClick} style={{ marginRight: '1%', marginTop: '28px', marginLeft: '2px' }}>
                      <i id="btnExcel" className="fa fa-file-excel" /> Excel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          }

          <div className='col-md-12'>
            <ThemeProvider theme={defaultMaterialTheme}>
              <MaterialTable columns={cols} data={tableData}
                actions={[
                  // {
                  //   icon: 'edit', tooltip: 'Edit', onClick: (e, r) => EditGreetingDraft(r.GreetingDraftID)
                  // },
                  {
                    icon: 'delete', tooltip: 'Delete', onClick: (e, r) => CheckAlert(r.DraftID, r.Draft)
                  }
                ]}

                options={{
                  headerStyle: { backgroundColor: '#B71C1C', color: 'white', fontFamily: 'system-ui' }, toolbar: true, actionsColumnIndex: -1, search: false,
                  paging: false, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                }}

                components={{
                  Toolbar: props => (
                    <>
                      <div className="row" >
                        <div className="col-md-12" style={{ display: 'flex', alignItems: 'center', marginLeft: '1%', marginTop: '0.3%', marginBottom: '0.3%' }}>
                          <h5>View Draft : <span className="badge bg-light-primary text-black fw-semibold fs-2">{TotalCount}</span></h5>

                          <div className="col-md-9" style={{ textAlign: 'right', marginLeft: '150px' }}>
                            <a className="btn btn-outline-dark font-weight-bolder" onClick={OnFilterClick1} style={{ marginTop: '5px', marginRight: '1px' }}>
                              <i id="btnFilter" className={iconFilter1} isfiltervisible='false' /> Filter
                            </a>
                          </div>

                        </div>
                      </div>
                    </>
                  )
                }}

              />
            </ThemeProvider>
          </div>

          <div id="ctl00_ContentPlaceHolder1_divPagination">
            <div className="col-lg-12 col-md-12 col-12 cssPagination" style={{ boxShadow: '2px 2px 5px #808080' }}>
              <nav aria-label="Page navigation">
                <ul className="GridPager pagination" style={{ paddingTop: '2px' }}>
                  <div style={{ marginTop: '0.5%', marginBottom: '0.5%', marginLeft: '3px' }}>
                    <button type="button" onClick={setPreviousApi} className="btn btn-primary mt-12 mr-3 mobmt-2 page-disable" style={{ marginRight: '1.5%' }} >Previous </button>
                  </div>
                  <div style={{ marginTop: '0.5%', marginBottom: '0.5%', marginLeft: '3px' }} >
                    <button type="button" onClick={setNextApi} className="btn btn-danger mt-12 mobmt-1 page-disable" style={{ marginRight: '1.5%' }}>Next </button>
                  </div>
                </ul>
              </nav>
            </div>
          </div>


        </div>
      </div>
    </>
  )
}

export default Viewdraft

