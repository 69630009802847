import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material';
import MaterialTable from 'material-table';
import { useState, useRef, useEffect } from "react";
import { FormWithConstraints, FieldFeedbacks, FieldFeedback, } from 'react-form-with-constraints';
import services from "../Services/Services";
import Swal from "sweetalert2";


const defaultMaterialTheme = createTheme();

const FestivalMaster = () => {

    const headerTblStyle = { color: 'Black' };

    const cols = [
        { title: (<div style={{ color: 'white', width: '9px' }}><span>Sr.No</span></div>), field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px'}}>{(pageId * pageCount - 10) + rowData.tableData.id + 1}</p>) } },
        { title: (<div style={{ color: 'white' }}><span>Festival Name</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '1100px' }}>{rowData.FestivalName}</p>) } },
    ]

    const form = useRef(FormWithConstraints);

    const [FestivalID, setFestivalID] = useState('')
    const [FestivalName, setFestivalName] = useState('')
    const [FestivalNameerror, setFestivalNameerror] = useState('')

    const [pageCount, setPageCount] = useState(10);
    const [pageId, setPageId] = useState(1);
    const [tableData, setTableData] = useState([]);
    const [allTableData, setAllTableData] = useState([]);
    const [TotalCount, setTotalCount] = useState('')

    const [issubmitvisible, setIssubmitvisible] = useState(true)
    const [isUpdatevisible, setIsUpdatevisible] = useState(false)
    const ref = useRef();

    useEffect(() => {
        GetFestivalMaster(pageCount);
    }, []);

    const GetFestivalMaster = (pageCount) => {
        debugger;
        var data = JSON.stringify({

            "FestivalID": "",
            "FestivalName": "",
            "PaginationID": "0",
            "PageLength": `${pageCount}`
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "FestivalMaster/GetFestivalMaster",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                setTableData(response.data.data)
                setAllTableData(response.data.data)
                setPageId(1)
                setIssubmitvisible(true)
                setIsUpdatevisible(false)
                setTotalCount(response.data.total[0].TotalCount);
            }

        }, error => { })
    }

    const setPreviousApi = () => {
        debugger;
        if (pageId == 1) {
            return
        }
        const tempt = [...allTableData]
        console.log((pageId - 2) * pageCount, (pageId - 1) * pageCount, 'alltabledata')
        const sliced = tempt.slice((pageId - 2) * pageCount, (pageId - 1) * pageCount)

        setTableData(sliced)
        setPageId(pageId - 1)

    };
    const setNextApi = () => {
        debugger;
        if (!tableData.length) {
            return;
        }
        if (pageId * pageCount < allTableData.length) {
            const tempt = [...allTableData];
            const sliced = tempt.slice((pageId) * pageCount, (pageId + 1) * pageCount);
            setTableData(sliced);
            setPageId(pageId + 1);
            return;
        }

        const paginationIndex = allTableData[pageId * pageCount - 1]?.FestivalID;
        if (!paginationIndex) {
            return;
        }

        var data = JSON.stringify({
            "FestivalID": "",
            "FestivalName": "",
            "PaginationID": paginationIndex,
            "PageLength": `${pageCount}`
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "FestivalMaster/GetFestivalMaster",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        services.JsonValue(config).then(response => {
            if (response.data.status) {
                setTableData(response.data.data)
                setPageId(pageId + 1)
                setAllTableData([...allTableData, ...response.data.data])
                setTotalCount(response.data.total[0].TotalCount);
            }

        }, error => { })
    };

    const handleFestivalNameChange = (e) => {
        setFestivalName(e.target.value);
        setFestivalNameerror('')
    }

    const submitHandler = (e) => {
        e.preventDefault();
        var data = "";
        var allvalid = true;

        if (FestivalName.length === 0) {
            setFestivalNameerror(true);
            allvalid = false;
        }

        if (allvalid) {
            Swal.fire({
                title: 'Are you sure?',
                // text: 'You are about to submit the form.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, submit it!',
            }).then((result) => {
                if (result.isConfirmed) {
                    // Continue with the submission logic
                    if (FestivalID !== "") {
                        // Update operation
                        data = JSON.stringify({
                            "FestivalID": FestivalID,
                            "FestivalName": FestivalName,
                        });
                    } else {
                        // Insert operation
                        data = JSON.stringify({
                            "FestivalID": "",
                            "FestivalName": FestivalName,
                        });
                    }
                    
                    var config = {
                        method: 'POST',
                        url: services.API_URL + "FestivalMaster/SetFestivalMaster",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: data
                    };

                    services.JsonValue(config).then(response => {
                        if (FestivalID && response.data.status === 1) {
                            // Successfully Updated
                            Swal.fire({
                                title: 'Successfully Updated',
                                icon: "success",
                                width:"400px",
                                showConfirmButton: false,
                                timer: 1500
                            });

                        } else if (!FestivalID && response.data.status === 1) {
                            // Successfully Inserted
                            Swal.fire({
                                title: 'Successfully Inserted',
                                icon: "success",
                                width:"400px",
                                showConfirmButton: false,
                                timer: 1500
                            });
                        } else if (response.data.status === 0) {
                            // Record already exists (show error message)
                            Swal.fire({
                                title: 'Error',
                                text: 'Record already exists!',
                                icon: 'error'
                            });
                        }
                        ClearData();
                        GetFestivalMaster(pageCount);
                    });
                }
            });
        }
    };

    const CloseClick = () => {
        setIssubmitvisible(true)
        setIsUpdatevisible(false)
        setFestivalID('')
        setFestivalName('')
        setFestivalNameerror('')
    }

    const ClearData = () => {
        setFestivalID('')
        setFestivalName('')
        setFestivalNameerror('')
    }

    const EditBanner = (id) => {
        debugger;
        setFestivalNameerror('')
        setIsUpdatevisible(true)
        setIssubmitvisible(false)

        var data = JSON.stringify({

            "FestivalID": id,
            "FestivalName": "",
            "PaginationID": "-1",
            "PageLength": ""
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "FestivalMaster/GetFestivalMaster",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {

            setFestivalID(response.data.data[0].FestivalID)
            setFestivalName(response.data.data[0].FestivalName)

        }, error => { })
    }

    const CheckAlert = (id) => {
        debugger;
        Swal.fire({
            title: 'Are you Sure You Want to Delete?', icon: "warning", showCancelButton: true, confirmButtonText: 'Delete', cancelButtonText: 'Cancel'
        }).then((result) => {

            if (result.isConfirmed) {
                var data = JSON.stringify({

                });
                var config = {
                    method: 'GET',
                    url: services.API_URL + "FestivalMaster/DeleteFestivalMaster/" + id,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    console.log(response.data.status, 'staus')
                    if (response.data.status) {
                        GetFestivalMaster(pageCount);
                        Swal.fire({
                            title: 'Successfully Deleted', icon: "success",  width:"400px", showConfirmButton: false, timer: 1500
                        });
                    } else {
                        Swal.fire({ position: 'top-end', icon: 'error', title: response.data.message, showConfirmButton: false, timer: 1500 });
                    }
                    setFestivalName('')
                }, error => { })
            } else if (result.isDenied) {
                Swal.close();
            }
        });


    }

    return (
        <>
            <div className="body-wrapper">
                <div className="container-fluid">

                    <div className='col-md-12'>
                        <div className="card">
                            <div className="px-3 py-2 border-bottom">
                                <h5>Festival Master</h5>
                            </div>
                            <FormWithConstraints
                                ref={form}
                                // onSubmit={submitHandler}
                                noValidate>

                                <div className="card-body">

                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className="form-group">
                                                <label htmlFor="FestivalName" className="form-label fw-semibold"> Festival Name <span className="text-danger">*</span> </label>
                                                <input type="text" onChange={handleFestivalNameChange} required value={FestivalName} type1="textnm" className="form-control" placeholder="Enter Festival Name"></input>
                                                {FestivalNameerror && <span style={{ color: 'red' }}>{FestivalName.length <= 0 ? 'Please Enter Festival Name' : ''}</span>}
                                            </div><br></br>
                                        </div>

                                        <div className="col-md-3" style={{ marginTop: '2%' }}>
                                            {issubmitvisible &&
                                                <button type="button" onClick={submitHandler} className="btn btn-outline-primary font-weight-bolder ml-2" style={{ marginRight: '5px' }}>Submit</button>
                                            }
                                            {isUpdatevisible &&
                                                <button type="button" onClick={submitHandler} className="btn btn-outline-primary font-weight-bolder ml-2" style={{ marginRight: '5px' }}>Update</button>
                                            }
                                            <button type="button" onClick={CloseClick} className="btn btn-outline-danger font-weight-bolder ml-2">Cancel</button>
                                        </div>
                                    </div>
                                </div>

                            </FormWithConstraints>
                        </div>
                    </div>

                    <div className='col-md-12'>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MaterialTable columns={cols} data={tableData}
                                actions={[
                                    {
                                        icon: 'edit', tooltip: 'Edit', onClick: (e, r) => EditBanner(r.FestivalID)
                                    },
                                    {
                                        icon: 'delete', tooltip: 'Delete', onClick: (e, r) => CheckAlert(r.FestivalID, r.FestivalName)
                                    }
                                ]}

                                options={{
                                    headerStyle: { backgroundColor: '#B71C1C', color: 'white', fontFamily: 'system-ui' }, toolbar: true, actionsColumnIndex: -1, search: false,
                                    paging: false, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                                }}


                                components={{
                                    Toolbar: props => (
                                        <>
                                            <div className="row" >
                                                <div className="col-md-12" style={{ display: 'flex', alignItems: 'center', marginLeft: '5px', marginTop: '5px', marginBottom: '0.3%' }}>
                                                    <h5>View Festival : <span className="badge bg-light-primary text-black fw-semibold fs-2">{TotalCount}</span></h5>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }}
                            />
                        </ThemeProvider>
                    </div>

                    <div id="ctl00_ContentPlaceHolder1_divPagination">
                        <div className="col-lg-12 col-md-12 col-12 cssPagination" style={{ boxShadow: '2px 2px 5px #808080' }}>
                            <nav aria-label="Page navigation">
                                <ul className="GridPager pagination" style={{ paddingTop: '2px' }}>
                                    <div style={{ marginTop: '0.5%', marginBottom: '0.5%', marginLeft: '3px' }}>
                                        <button type="button" onClick={setPreviousApi} className="btn btn-primary mt-12 mr-3 mobmt-2 page-disable" style={{ marginRight: '1.5%' }} >Previous </button>
                                    </div>
                                    <div style={{ marginTop: '0.5%', marginBottom: '0.5%', marginLeft: '3px' }} >
                                        <button type="button" onClick={setNextApi} className="btn btn-danger mt-12 mobmt-1 page-disable" style={{ marginRight: '1.5%' }}>Next </button>
                                    </div>
                                </ul>
                            </nav>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

export default FestivalMaster
