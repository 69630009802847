import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material';
import MaterialTable from 'material-table';
import { useState, useRef, useEffect } from "react";
import { FormWithConstraints, FieldFeedbacks, FieldFeedback, } from 'react-form-with-constraints';
import { allowOnlyNumbers } from '../inputvalidation';
import services from "../Services/Services";
import Swal from "sweetalert2";
import XLSX from "xlsx-color";
import moment from 'moment';

const defaultMaterialTheme = createTheme();

const Viewuserbirthdaydata = () => {

  const handleExcelExport = async (st) => {

    var MNo = "";

    if (MobileNo != "") {
      MNo = MobileNo
    }

    var data = JSON.stringify({
      "MobileNo": st == "cancle" ? "" : MNo,
      "PaginationID": "0",
      "PageLength": `${pageCount}`
    });

    var config = {
      method: 'POST',
      url: services.API_URL + "TodayBirthday/GetTodayBirthday",
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    services.JsonValue(config).then(response => {
      if (response.data.status) {
        exportToExcel(response.data.data)
      }

    }, error => { })
  };

  const exportToExcel = (array) => {
    console.log(array, 'data');
    debugger;
    const header = ["Sr.No", "Name", "MobileNo", "Address", "BirthDate"];
    const body = [];
    array?.forEach((col, index) => {
      body.push([index + 1, col.Name, col.MobileNo, col.Address, col.BirthDate]);
    });

    if (!body.length) {
      return Swal.fire({
        title: "No Data Found!",
        icon: "error",
        timer: 1500
      });
    }

    const ws1 = XLSX.utils.aoa_to_sheet([header, ...body]);

    header.forEach((col, index) => {
      ws1[XLSX.utils.encode_cell({ r: 0, c: index })].s = {

        font: { bold: true, color: { rgb: "f7f4ef" } }, // White font color
        fill: { patternType: 'solid', fgColor: { rgb: "#B71C1C" } }, // Blue background color
        alignment: { wrapText: false, horizontal: 'center' },
      };
    });


    ws1['!cols'] = [
      { width: 6 },
      { width: 20 },
      { width: 20 },
      { width: 50 },
      { width: 20 },
      { width: 20 },
    ];

    let cell = {
      font: { bold: true, color: { rgb: "f7f4ef" } },
      fill: { patternType: 'solid', fgColor: { rgb: "#B71C1C" } },
      alignment: { wrapText: false, horizontal: 'center' },
      border: {
        left: { style: 'thin', color: { rgb: '000000' } },
        right: { style: 'thin', color: { rgb: '000000' } },
        top: { style: 'thin', color: { rgb: '000000' } },
        bottom: { style: 'thin', color: { rgb: '000000' } }
      }
    }
    ws1['A1'].s = cell;
    ws1['B1'].s = cell;
    ws1['C1'].s = cell;
    ws1['D1'].s = cell;
    ws1['E1'].s = cell;
    ws1['F1'].s = cell;


    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, 'BirthdayDetails');

    // Save the workbook
    XLSX.writeFile(wb, `BirthdayDetails_${moment().format('DD-MM-YYYY_HH:mm')}.xlsx`);

  };
  const headerTblStyle = { color: 'Black' };

  const cols = [
    { title: (<div style={{ color: 'white', width: '9px' }}><span>Sr.No</span></div>), field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{(pageId * pageCount - 10) + rowData.tableData.id + 1}</p>) } },
    { title: (<div style={{ width: '100%', color: 'white' }}><span>Contact Name</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '80px' }}>{rowData.Name}</p>) } },
    {
      title: (<div style={{ color: 'white' }}><span> Mobile No</span></div>), width: '8%', removable: false, headerStyle: headerTblStyle, render: rowData => {
        return (<p style={{ marginBottom: '1px' }}>{
          <table id="MobileDetail" style={{ border: 'solid 1px #000000', width: '50%' }}>
            <tbody><tr style={{ border: 'solid 1px #000000', background: '#ffffff' }}>
              <td style={{ border: 'solid 1px #000000', width: '110%' }}>Mobile No</td>
              <td style={{ border: 'solid 1px #000000', width: '110%', padding: 2, fontWeight: 'bold', color: 'black' }}>
                <span id="MobileNo" />{rowData.MobileNo}<br />
              </td>
            </tr>
              <tr style={{ border: 'solid 1px #000000', background: '#ffffff' }}>
                <td style={{ border: 'solid 1px #000000', width: '110%', marginLeft: '5px' }}>Mobile No 2</td>
                <td style={{ border: 'solid 1px #000000', width: '110%', padding: 2, fontWeight: 'bold', color: 'black' }}>
                  <span id="MobileNo2" />{rowData.MobileNo2}<br />
                </td>
              </tr>
              <tr style={{ border: 'solid 1px #000000', background: '#ffffff' }}>
                <td style={{ border: 'solid 1px #000000', width: '110%', padding: 2 }}>Telephone No</td>
                <td style={{ border: 'solid 1px #000000', width: '110%', padding: 2, fontWeight: 'bold', color: 'black' }}>
                  <span id="MobileNo3" />{rowData.TelephoneNo}<br />
                </td>
              </tr>
            </tbody></table>}</p>)
      }
    },
    { title: (<div style={{ width: '100%', color: 'white' }}><span> Address</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '120px' }}>{rowData.Address}</p>) } },
    { title: (<div style={{ width: '100%', color: 'white' }}><span> Birth Date</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '100px' }}>{rowData.BirthDate}</p>) } },

  ]

  const form = useRef(FormWithConstraints);

  const [ContactDetailID, setContactDetailID] = useState('')

  const [MobileNo, setMobileNo] = useState('')

  const [pageCount, setPageCount] = useState(10);
  const [pageId, setPageId] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [allTableData, setAllTableData] = useState([]);
  const [TotalCount, setTotalCount] = useState('')

  const [issubmitvisible, setIssubmitvisible] = useState(true)
  const [isUpdatevisible, setIsUpdatevisible] = useState(false)

  useEffect(() => {
    GetTodayBirthday(pageCount);
  }, []);

  const GetTodayBirthday = (pageCount, st) => {
    debugger;
    var MNo = "";

    if (MobileNo != "") {
      MNo = MobileNo
    }

    var data = JSON.stringify({
      "MobileNo": st == "cancle" ? "" : MNo,
      "PaginationID": "0",
      "PageLength": `${pageCount}`
    });
    var config = {
      method: 'POST',
      url: services.API_URL + "TodayBirthday/GetTodayBirthday",
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    services.JsonValue(config).then(response => {
      if (response.data.status) {
        setTableData(response.data.data)
        setAllTableData(response.data.data)
        setPageId(1)
        setIssubmitvisible(true)
        setIsUpdatevisible(false)
      }
      else {
        setTableData([]);
      }
    }, error => { })
  }

  const setPreviousApi = () => {
    debugger;
    if (pageId == 1) {
      return
    }
    const tempt = [...allTableData]
    console.log((pageId - 2) * pageCount, (pageId - 1) * pageCount, 'alltabledata')
    const sliced = tempt.slice((pageId - 2) * pageCount, (pageId - 1) * pageCount)

    setTableData(sliced)
    setPageId(pageId - 1)

  };
  const setNextApi = () => {
    console.log(pageId, "pageID", pageCount)

    if (!tableData.length) {
      return;
    }
    if (pageId * pageCount < allTableData.length) {
      const tempt = [...allTableData];
      const sliced = tempt.slice((pageId) * pageCount, (pageId + 1) * pageCount);
      setTableData(sliced);
      setPageId(pageId + 1);
      return;
    }

    const paginationIndex = allTableData[pageId * pageCount - 1]?.ContactDetailID;
    if (!paginationIndex) {
      return;
    }

    var data = JSON.stringify({
      "MobileNo": "",
      "PaginationID": paginationIndex,
      "PageLength": `${pageCount}`
    });
    var config = {
      method: 'POST',
      url: services.API_URL + "TodayBirthday/GetTodayBirthday",
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    services.JsonValue(config).then(response => {
      if (response.data.status) {
        setTableData(response.data.data)
        setPageId(pageId + 1)
        setAllTableData([...allTableData, ...response.data.data])
        setTotalCount(response.data.total[0].TotalCount);
      }

    }, error => { })
  };

  const HandleMobileNo = (e) => {
    setMobileNo(e.target.value)
  }
  const onSearchClick = (e) => {
    e.preventDefault();
    GetTodayBirthday(pageCount);

  }

  const OnSearchCancelClick = () => {
    setMobileNo('')

    GetTodayBirthday(pageCount, "cancle");
  }

  const ExcelClick = () => {
    handleExcelExport();
  }

  return (
    <>
      <div className="body-wrapper">
        <div className="container-fluid">

          <div className='col-md-12'>
            <div className="card">
              <div className="px-3 py-2 border-bottom">
                <h5>Searching</h5>
              </div>
              <FormWithConstraints
                ref={form}
                // onSubmit={submitHandler}
                noValidate>

                <div className="card-body">

                  <div className='row'>

                    <div className='col-md-3'>
                      <div className="form-group">
                        <label htmlFor="MobileNo" className="form-label fw-semibold">Contact Mobile No</label>
                        <input type="MobileNo" onChange={HandleMobileNo} required value={MobileNo} maxLength={10} onKeyPress={allowOnlyNumbers} type1="textnm" className="form-control" placeholder="Enter Contact Mobile No"></input>
                      </div>
                    </div>

                    <div className='col-md-3'>
                      <button type="button" onClick={onSearchClick} className="btn btn-primary mt-12 mr-3 mobmt-2" style={{ marginRight: '5px', marginTop: '30px' }} >Search </button>
                      <button type="button" onClick={OnSearchCancelClick} className="btn btn-danger mt-12 mobmt-1" style={{ marginRight: '5px', marginTop: '30px' }}>Cancel </button>
                      <a className="btn btn-outline-success font-weight-bolder mt-12" onClick={ExcelClick} style={{ marginRight: '5px', marginTop: '30px' }}>
                        <i id="btnExcel" className="fa fa-file-excel" /> Excel
                      </a>
                    </div>
                  </div>
                </div>

              </FormWithConstraints>
            </div>
          </div>

          <div className='col-md-12'>
            <ThemeProvider theme={defaultMaterialTheme}>
              <MaterialTable columns={cols} data={tableData}
                actions={[

                ]}

                options={{
                  headerStyle: { backgroundColor: '#B71C1C', color: 'white', fontFamily: 'system-ui' }, toolbar: true, actionsColumnIndex: -1, search: false,
                  paging: false, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                }}


                components={{
                  Toolbar: props => (
                    <>
                      <div className="row" >
                        <div className="col-md-12" style={{ display: 'flex', alignItems: 'center', marginLeft: '5px', marginTop: '5px', marginBottom: '3px' }}>
                          <h5>View User Birthday : <span className="badge bg-light-primary text-black fw-semibold fs-2">{TotalCount}</span></h5>
                        </div>
                      </div>
                    </>
                  )
                }}
              />
            </ThemeProvider>
          </div>

          <div id="ctl00_ContentPlaceHolder1_divPagination">
            <div className="col-lg-12 col-md-12 col-12 cssPagination" style={{ boxShadow: '2px 2px 5px #808080' }}>
              <nav aria-label="Page navigation">
                <ul className="GridPager pagination" style={{ paddingTop: '2px' }}>
                  <div style={{ marginTop: '0.5%', marginBottom: '0.5%', marginLeft: '3px' }}>
                    <button type="button" onClick={setPreviousApi} className="btn btn-primary mt-12 mr-3 mobmt-2 page-disable" style={{ marginRight: '1.5%' }} >Previous </button>
                  </div>
                  <div style={{ marginTop: '0.5%', marginBottom: '0.5%', marginLeft: '3px' }} >
                    <button type="button" onClick={setNextApi} className="btn btn-danger mt-12 mobmt-1 page-disable" style={{ marginRight: '1.5%' }}>Next </button>
                  </div>
                </ul>
              </nav>
            </div>
          </div>


        </div>
      </div>
    </>
  )
}

export default Viewuserbirthdaydata
