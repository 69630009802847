import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material';
import MaterialTable from 'material-table';
import { useState, useRef, useEffect } from "react";
import { FormWithConstraints, FieldFeedbacks, FieldFeedback, } from 'react-form-with-constraints';
import services from "../Services/Services";
import Swal from "sweetalert2";
import Select from 'react-select'
import { useCookies } from 'react-cookie';
import XLSX from "xlsx-color";
import moment from 'moment';
import { allowOnlyNumbers, allowOnlyCharacters } from '../inputvalidation';
import DatePicker from "react-datepicker";



const defaultMaterialTheme = createTheme();

const Contactdetail = () => {

    const [cookies, setCookie] = useCookies(['user']);
    const [ckUserID, setckUserID] = useState(cookies.ckUserID);
    const [ckDesignationID, setckDesignationID] = useState(cookies.ckDesignationID);


    const headerTblStyle = { color: 'Black' };
    const cols = [
        { title: (<div style={{ color: 'white', width: '9px' }}><span>Sr.No</span></div>), field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '5px' }}>{(pageId * pageCount - 10) + rowData.tableData.id + 1}</p>) } },
        { title: (<div style={{ width: '140%', color: 'white' }}><span>Category Name</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '80px' }}>{rowData.CategoryName}</p>) } },
        { title: (<div style={{ width: '140%', color: 'white' }}><span>Designation Name</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '60px' }}>{rowData.DesignationName}</p>) } },
        { title: (<div style={{ width: '100%', color: 'white' }}><span>Name</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '80px' }}>{rowData.Name}</p>) } },
        {
            title: (<div style={{ color: 'white', width: '150px' }}><span> Mobile No</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => {
                return (
                    <div style={{ marginBottom: '1px', width: '185px' }}>{
                        <table id="MobileDetail" style={{ border: 'solid 1px #000000', marginRight: '5px' }}>
                            <tbody>
                                <tr style={{ border: 'solid 1px #000000', background: '#ffffff' }}>
                                    <td style={{ border: 'solid 1px #000000' }}>Mobile No</td>
                                    <td style={{ border: 'solid 1px #000000', padding: 2, fontWeight: 'bold', color: 'black' }}>
                                        <span id="MobileNo" />{rowData.MobileNo}<br />
                                    </td>
                                </tr>
                                <tr style={{ border: 'solid 1px #000000', background: '#ffffff' }}>
                                    <td style={{ border: 'solid 1px #000000', marginLeft: '5px' }}>Mobile No 2</td>
                                    <td style={{ border: 'solid 1px #000000', padding: 2, fontWeight: 'bold', color: 'black' }}>
                                        <span id="MobileNo2" />{rowData.MobileNo2}<br />
                                    </td>
                                </tr>
                                <tr style={{ border: 'solid 1px #000000', background: '#ffffff' }}>
                                    <td style={{ border: 'solid 1px #000000' }}>Telephone No</td>
                                    <td style={{ border: 'solid 1px #000000', padding: 2, fontWeight: 'bold', color: 'black' }}>
                                        <span id="MobileNo3" />{rowData.TelephoneNo}<br />
                                    </td>
                                </tr>
                            </tbody>
                        </table>}</div>)
            }
        },
        { title: (<div style={{ color: 'white' }}> <span>Address</span></div>), headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '120px' }}>{rowData.Address}</p>) } },
        { title: (<div style={{ color: 'white', width: '60px' }}> <span>Email ID</span></div>), headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.EmailID}</p>) } },
        { title: (<div style={{ color: 'white' }}> <span>Birth Date</span></div>), headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '95px' }}>{rowData.Birthdate}</p>) } },
        { title: (<div style={{ color: 'white', width: '85px' }}> <span>State</span></div>), headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '70px' }}>{rowData.StateName}</p>) } },
        { title: (<div style={{ color: 'white', width: '80px' }}> <span>City</span></div>), headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '60px' }}>{rowData.CityName}</p>) } },
    ]

    const form = useRef(FormWithConstraints);

    const handleExcelExport = async (st) => {

        var NM = "";
        var MNo = "";
        var CT = CategoryID;
        var DI = st === "cancle" ? "" :
            (DesignationID1.value === "--Select Designation--" ? "" : DesignationID1.value);

        if (Name1 != "") {
            NM = Name1
        }

        if (MobileNo1 != "") {
            MNo = MobileNo1
        }

        if (CategoryID1.value === "--Select Category--") {
            CT = "";
        }
        else {
            CT = CategoryID1.value;
        }

        var data = JSON.stringify({

            "ContactDetailID": "",
            "Name": st == "cancle" ? "" : NM,
            "UserID": "",
            "MobileNo": st == "cancle" ? "" : MNo,
            "BirthDate": "",
            "CityID": "",
            "StateID": "",
            "CategoryID": st == "cancle" ? "" : CT,
            "DesignationID": DI !== "" ? DI : ckDesignationID,
            "StartDate": "",
            "EndDate": "",
            "PaginationID": "0",
            "PageLength": `${pageCount}`
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "ContactDetail/GetContactDetail",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                exportToExcel(response.data.data)
            }

        }, error => { })
    };

    const exportToExcel = (array) => {
        console.log(array, 'data');
        debugger;
        const header = ["Sr.No", "Category Name", "Designation Name", "Name", "Mobile No", "Mobile No 2", "Birthdate", "Address", "EmailID", "TelephoneNo", "CityName", "StateName"];
        const body = [];
        array?.forEach((col, index) => {
            body.push([index + 1, col.CategoryName, col.DesignationName, col.Name, col.MobileNo, col.MobileNo2, col.Birthdate, col.Address, col.EmailID, col.TelephoneNo, col.CityName, col.StateName]);
        });

        if (!body.length) {
            return Swal.fire({
                title: "No Data Found!",
                icon: "error",
                timer: 1500
            });
        }

        const ws1 = XLSX.utils.aoa_to_sheet([header, ...body]);

        header.forEach((col, index) => {
            ws1[XLSX.utils.encode_cell({ r: 0, c: index })].s = {
                font: { bold: true, color: { rgb: "f7f4ef" } }, // White font color
                fill: { patternType: 'solid', fgColor: { rgb: "#B71C1C" } }, // Blue background color
                alignment: { wrapText: index === 3, horizontal: 'center' }, // Enable wrap text for the "Draft" column
            };
        });

        ws1['!cols'] = [
            { width: 6 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 20 },

        ];


        let cell = {
            font: { bold: true, color: { rgb: "f7f4ef" } },
            fill: { patternType: 'solid', fgColor: { rgb: "#B71C1C" } },
            alignment: { wrapText: true, horizontal: 'center' }, // Enable wrap text for the "Draft" column
            border: {
                left: { style: 'thin', color: { rgb: '000000' } },
                right: { style: 'thin', color: { rgb: '000000' } },
                top: { style: 'thin', color: { rgb: '000000' } },
                bottom: { style: 'thin', color: { rgb: '000000' } }
            }
        };
        ws1['A1'].s = cell;
        ws1['B1'].s = cell;
        ws1['C1'].s = cell;
        ws1['D1'].s = cell;
        ws1['E1'].s = cell;
        ws1['F1'].s = cell;
        ws1['G1'].s = cell;
        ws1['H1'].s = cell;
        ws1['I1'].s = cell;
        ws1['J1'].s = cell;
        ws1['K1'].s = cell;
        ws1['L1'].s = cell;


        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws1, 'Contactdetail');

        XLSX.writeFile(wb, `Contactdetail_${moment().format('DD-MM-YYYY_HH:mm')}.xlsx`);
    };

    const [ContactDetailID, setContactDetailID] = useState('')

    const [StateID, setStateID] = useState({ 'label': '--Select State--', 'value': '--Select State--' });
    const [Statees, getState] = useState([])
    const [StateError, setStateError] = useState('');

    const [CityID, setCityID] = useState({ 'label': '--Select City--', 'value': '--Select City--' });
    const [Cityes, getCity] = useState([])
    const [CityError, setCityError] = useState('');

    const [CategoryID, setCategoryID] = useState({ 'label': '--Select Category--', 'value': '--Select Category--' });
    const [Categoryes, getCategory] = useState([])
    const [CategoryError, setCategoryError] = useState('');

    const [DesignationID, setDesignationID] = useState({ 'label': '--Select Designation--', 'value': '--Select Designation--' });
    const [Designationes, getDesignation] = useState([])
    const [DesignationError, setDesignationError] = useState('');

    //---------------Searching ---------------//
    const [CategoryID1, setCategoryID1] = useState({ 'label': '--Select Category--', 'value': '--Select Category--' });
    const [Categoryes1, getCategory1] = useState([])

    const [DesignationID1, setDesignationID1] = useState({ 'label': '--Select Designation--', 'value': '--Select Designation--' });
    const [Designationes1, getDesignation1] = useState([])

    const [Name1, setName1] = useState('')
    const [MobileNo1, setMobileNo1] = useState('')
    //----------------------------------------//

    const [Name, setName] = useState('')
    const [Nameerror, setNameerror] = useState('')

    const [MobileNo, setMobileNo] = useState('')
    const [MobileNoerror, setMobileNoerror] = useState('')

    const [MobileNo2, setMobileNo2] = useState('')
    const [MobileNo2error, setMobileNo2error] = useState('')

    const [TelephoneNo, setTelephoneNo] = useState('')
    const [TelephoneNoerror, setTelephoneNoerror] = useState('')

    const [Birthdate, setBirthdate] = useState('')
    const [BdateError, setBdateError] = useState('');
    const [Birthdateerror, setBirthdateerror] = useState('')

    const [Address, setAddress] = useState('')

    const [EmailID, setEmailID] = useState('')
    const [emailError, setEmailError] = useState('')

    const [pageCount, setPageCount] = useState(10);
    const [pageId, setPageId] = useState(1);
    const [tableData, setTableData] = useState([]);
    const [allTableData, setAllTableData] = useState([]);
    const [TotalCount, setTotalCount] = useState('')

    const [issubmitvisible, setIssubmitvisible] = useState(true)
    const [isUpdatevisible, setIsUpdatevisible] = useState(false)
    const ref = useRef();

    const [iconFilter, seticonFilter] = useState('fa fa-plus')
    const [isfiltervisible, setFilter] = useState(false)

    const [iconFilter1, seticonFilter1] = useState('fa fa-plus')
    const [isfiltervisible1, setFilter1] = useState(false)

    useEffect(() => {
        GetContactDetail(pageCount);
        GetState();
        GetCity();
        GetCategory();
        GetDesignation();
        GetCategory1();
        GetDesignation1();

    }, []);

    const GetContactDetail = (pageCount, st) => {
        debugger;

        var NM = "";
        var MNo = "";
        var CT = CategoryID1;

        var DI = st === "cancle" ? "" :
            (DesignationID1.value === "--Select Designation--" ? "" : DesignationID1.value);

        if (Name1 != "") {
            NM = Name1
        }

        if (MobileNo1 != "") {
            MNo = MobileNo1
        }

        if (CategoryID1.value === "--Select Category--") {
            CT = "";
        }
        else {
            CT = CategoryID1.value;
        }


        var data = JSON.stringify({
            "ContactDetailID": "",
            "Name": st == "cancle" ? "" : NM,
            "UserID": "",
            "MobileNo": st == "cancle" ? "" : MNo,
            "BirthDate": "",
            "CityID": "",
            "StateID": "",
            "CategoryID": st == "cancle" ? "" : CT,
            "DesignationID": DI !== "" ? DI : ckDesignationID,
            "StartDate": "",
            "EndDate": "",
            "PaginationID": "0",
            "PageLength": `${pageCount}`
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "ContactDetail/GetContactDetail",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                setTableData(response.data.data)
                setAllTableData(response.data.data)
                setPageId(1)
                setIssubmitvisible(true)
                setIsUpdatevisible(false)
                setTotalCount(response.data.total[0].TotalCount);
            }
            else {
                setTableData([]);
            }

        }, error => { })
    }
    const setPreviousApi = () => {
        debugger;
        if (pageId == 1) {
            return
        }
        const tempt = [...allTableData]
        console.log((pageId - 2) * pageCount, (pageId - 1) * pageCount, 'alltabledata')
        const sliced = tempt.slice((pageId - 2) * pageCount, (pageId - 1) * pageCount)

        setTableData(sliced)
        setPageId(pageId - 1)

    };
    const setNextApi = () => {
        console.log(pageId, "pageID", pageCount)

        if (!tableData.length) {
            return;
        }
        if (pageId * pageCount < allTableData.length) {
            const tempt = [...allTableData];
            const sliced = tempt.slice((pageId) * pageCount, (pageId + 1) * pageCount);
            setTableData(sliced);
            setPageId(pageId + 1);
            return;
        }

        const paginationIndex = allTableData[pageId * pageCount - 1]?.ContactDetailID;
        if (!paginationIndex) {
            return;
        }

        var data = JSON.stringify({

            "ContactDetailID": "",
            "Name": "",
            "UserID": "",
            "MobileNo": "",
            "BirthDate": "",
            "CityID": "",
            "StateID": "",
            "CategoryID": "",
            "DesignationID": "",
            "StartDate": "",
            "EndDate": "",
            "PaginationID": paginationIndex,
            "PageLength": `${pageCount}`
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "ContactDetail/GetContactDetail",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        services.JsonValue(config).then(response => {
            if (response.data.status) {
                setTableData(response.data.data)
                setPageId(pageId + 1)
                setAllTableData([...allTableData, ...response.data.data])
                setTotalCount(response.data.total[0].TotalCount);
            }

        }, error => { })
    };
    const GetState = () => {
        var data = JSON.stringify({
            "StateID": "",
            "StateName": ""
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "StateCity/GetState",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            debugger;
            if (response.data.status) {
                getState(response.data.data.map(item => ({ value: item.StateID, label: item.StateName })))
            }
            else {
                getState([]);
            }
        }, error => { })
    }
    const onStateChange = (e) => {
        setStateID(e);
        setStateError('')
        setCityID({
            label: '--Select City--',
            value: '--Select City--',
        });
        GetCity(e.value)
    }
    const GetCity = (StateID) => {
        var data = JSON.stringify({
            "CityID": "",
            "CityName": "",
            "StateID": StateID
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "StateCity/GetCity",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            debugger;
            if (response.data.status) {
                getCity(response.data.data.map(item => ({ value: item.CityID, label: item.CityName })))
            }
            else {
                getCity([]);
            }
        }, error => { })
    }
    const onCityChange = (e) => {
        setCityID(e);
        setCityError('')
    }
    const GetCategory = (DesignationID) => {
        debugger;
        var data = JSON.stringify({
            "CategoryID": "",
            "CategoryName": "",
            "DesignationID": DesignationID,
            "PaginationID": "-1",
            "PageLength": ""
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "Category/GetCategory",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            debugger;
            if (response.data.status) {
                getCategory(response.data.data.map(item => ({ value: item.CategoryID, label: item.CategoryName })))
            }
            else {
                getCategory([]);
            }
        }, error => { })
    }
    const onCategoryChange = (e) => {
        setCategoryID(e);
        setCategoryError('')
    }
    const GetDesignation = () => {
        debugger;
        var data = JSON.stringify({
            "DesignationID": "",
            "DesignationName": "",
            "UserID": "",
            "StartDate": "",
            "EndDate": "",
            "PaginationID": "-1",
            "PageLength": ""
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "Designation/GetDesignation",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            debugger;
            if (response.data.status) {
                getDesignation(response.data.data.map(item => ({ value: item.DesignationID, label: item.DesignationName })))
            }
            else {
                getDesignation([]);
            }
        }, error => { })
    }
    const onDesignationChange = (e) => {
        setDesignationID(e);
        setDesignationError('')

        setCategoryID({
            label: '--Select Category--',
            value: '--Select Category--',
        });
        GetCategory(e.value)
    }
    const GetCategory1 = () => {
        debugger;
        var data = JSON.stringify({
            "CategoryID": "",
            "PaginationID": "-1",
            "PageLength": ""
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "Category/GetCategory",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            debugger;
            if (response.data.status) {
                getCategory1(response.data.data.map(item => ({ value: item.CategoryID, label: item.CategoryName })))
            }
            else {
                getCategory1([]);
            }
        }, error => { })
    }
    const onCategoryChange1 = (e) => {
        setCategoryID1(e);
    }
    const GetDesignation1 = () => {
        debugger;
        var data = JSON.stringify({

            "DesignationID": "",
            "UserID": "",
            "DesignationName": "",
            "CategoryID": "",
            "StartDate": "",
            "EndDate": "",
            "PaginationID": "-1",
            "PageLength": ""
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "Designation/GetDesignation",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            debugger;
            if (response.data.status) {
                getDesignation1(response.data.data.map(item => ({ value: item.DesignationID, label: item.DesignationName })))
            }
            else {
                getDesignation1([]);
            }
        }, error => { })
    }
    const onDesignationChange1 = (e) => {
        setDesignationID1(e);
    }
    const handleNameChange = (e) => {
        setName(e.target.value);
        setNameerror('')
    }
    const handleNameChange1 = (e) => {
        setName1(e.target.value);
    }

    const handleMobileChange1 = (e) => {
        setMobileNo1(e.target.value);
    }
    const handleMobileChange = (e) => {
        setMobileNoerror('')
        setMobileNo(e.target.value);

        const inputReferenceMobileNoNumber = e.target.value;
        const MoNo = inputReferenceMobileNoNumber.replace(/\D/g, '');
        setMobileNo(MoNo);
        setMobileNoerror('');
    }

    const handleMobileChange2 = (e) => {
        if (e != null) {
            setMobileNo2(e);
        } else {
            setMobileNo2('');
        }
        const inputMobileNoNumber2 = e.target.value;
        // Remove any non-digit characters from the input
        const MoNo2 = inputMobileNoNumber2.replace(/\D/g, '');
        setMobileNo2(MoNo2);
        setMobileNo2error('');
    }
    const handleTelephoneNoChange = (e) => {
        if (e != null) {
            setTelephoneNo(e);
        } else {
            setTelephoneNo('');
        }
        const inputTelephoneNo = e.target.value;
        // Remove any non-digit characters from the input
        const TellNo = inputTelephoneNo.replace(/\D/g, '');
        setTelephoneNo(TellNo);
        setTelephoneNoerror('');
    }
    const handleBirthdatechange = (date) => {
        setBirthdate(date);
        setBdateError('')
    };
    const validateEmailID = () => {
        if (EmailID.length) {
            const EmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (EmailID.match(EmailRegex)) {
                setEmailError('');
                return true;
            } else {
                setEmailError('Please Enter Valid Email ID');
                return false;
            }
        }
        return true;
    };
    const submitHandler = (e) => {
        debugger;

        var allvalid = true;

        if (StateID.value === "--Select State--") {
            setStateError('Please Select State');
            allvalid = false;
        }

        if (CityID.value === "--Select City--") {
            setCityError('Please Select City');
            allvalid = false;
        }

        if (CategoryID.value === "--Select Category--") {
            setCategoryError('Please Select Category');
            allvalid = false;
        }

        if (DesignationID.value === "--Select Designation--") {
            setDesignationError('Please Select Designation');
            allvalid = false;
        }

        if (Name.length === 0) {
            setNameerror(true)
            allvalid = false;
        }

        if (MobileNo?.trim() !== '') {
            if (/^\d{10}$/.test(MobileNo)) {
                setMobileNoerror('');
            } else {
                setMobileNoerror('Please Enter Valid 10 Digit Mobile Number');
                allvalid = false;
            }
        } else {
            setMobileNoerror('Please Enter Mobile No');
            allvalid = false;
        }

        if (MobileNo2.length !== 10 && MobileNo2.length) {
            setMobileNo2error('Please Enter Valid 10 Digit Mobile Number');
            allvalid = false;
        }

        if (TelephoneNo.length !== 10 && TelephoneNo.length) {
            setTelephoneNoerror('Please Enter Valid 10 Digit Telephone No');
            allvalid = false;
        }


        if (!Birthdate) {
            setBdateError('Please Select Birth Date');
            allvalid = false;
        } else {
            setBdateError('');
        }

        var InvBirthdt;
        if (Birthdate !== "") {
            InvBirthdt = moment(Birthdate).format('DD-MM-YYYY');
        }

        const isEmailValid = validateEmailID();
        if (!isEmailValid) {
            allvalid = false;
        }

        const currentDate = new Date();
        if (Birthdate > currentDate) {
            setBirthdateerror('Please Select Date In The Past.');
            return;
        }

        if (allvalid) {

            Swal.fire({
                title: 'Are you sure?',
                // text: 'Do you want to submit?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, submit!',
            }).then((result) => {
                if (result.isConfirmed) {

                    var data = "";

                    if (ContactDetailID != "") {
                        data = JSON.stringify({
                            "ContactDetailID": ContactDetailID,
                            "UserID": ckUserID,
                            "Name": Name,
                            "MobileNo": MobileNo,
                            "MobileNo2": MobileNo2,
                            "BirthDate": InvBirthdt,
                            "Address": Address,
                            "EmailID": EmailID,
                            "TelephoneNo": TelephoneNo,
                            "CityID": CityID.value,
                            "StateID": StateID.value,
                            "CategoryID": CategoryID.value,
                            "DesignationID": DesignationID.value
                        });
                    }
                    else {
                        data = JSON.stringify({
                            "ContactDetailID": "",
                            "UserID": ckUserID,
                            "Name": Name,
                            "MobileNo": MobileNo,
                            "MobileNo2": MobileNo2,
                            "BirthDate": InvBirthdt,
                            "Address": Address,
                            "EmailID": EmailID,
                            "TelephoneNo": TelephoneNo,
                            "CityID": CityID.value,
                            "StateID": StateID.value,
                            "CategoryID": CategoryID.value,
                            "DesignationID": DesignationID.value
                        });
                    }

                    var config = {
                        method: 'POST',
                        url: services.API_URL + "ContactDetail/SetContactDetail",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: data
                    };
                    services.JsonValue(config).then(response => {
                        //// debugger;
                        if (response.data.status) {
                            // debugger;
                            if (ContactDetailID != "") {
                                // Swal.fire({ position: 'top-end', toast: true, icon: 'success', title: 'Successfully Updated', showConfirmButton: false, timer: 1500 });
                                Swal.fire({
                                    title: 'Successfully Updated', icon: "success", width: "400px", showConfirmButton: false, timer: 1500
                                });
                            }
                            else {
                                Swal.fire({
                                    title: 'Successfully Inserted', icon: "success", width: "400px", showConfirmButton: false, timer: 1500
                                });
                            }
                            ClearData();
                            setFilter(false);
                            GetContactDetail(pageCount);
                        }
                        else {
                            Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                        }
                    }, error => { })
                }
            })
        }

    }
    const EditContactDetail = (id) => {
        setFilter1(false)
        if (isfiltervisible === false) {
            setFilter(true);
            seticonFilter("fa fa-minus");
            seticonFilter1("fa fa-plus");
        }
        setIsUpdatevisible(true)
        setIssubmitvisible(false)

        debugger;
        setStateError('')
        setCityError('')
        setDesignationError('')
        setCategoryError('')
        setMobileNoerror('')
        setBirthdateerror('')


        var data = JSON.stringify({
            "ContactDetailID": id,
            "Name": "",
            "MobileNo": "",
            "BirthDate": "",
            "CityID": "",
            "StateID": "",
            "StartDate": "",
            "EndDate": "",
            "PaginationID": "-1",
            "PageLength": ""
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "ContactDetail/GetContactDetail",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        services.JsonValue(config).then(response => {

            var date = "";
            var dateString = response.data.data[0].Birthdate;
            if (dateString != null) {
                var dateMomentObject = moment(dateString, "DD-MM-YYYY"); // 1st argument - string, 2nd argument - format
                date = dateMomentObject.toDate();
            }
            else {
                date = "";
            }

            setContactDetailID(response.data.data[0].ContactDetailID)
            setStateID({ 'label': response.data.data[0].StateName, 'value': response.data.data[0].StateID })
            setCityID({ 'label': response.data.data[0].CityName, 'value': response.data.data[0].CityID })
            setCategoryID({ 'label': response.data.data[0].CategoryName, 'value': response.data.data[0].CategoryID })
            setDesignationID({ 'label': response.data.data[0].DesignationName, 'value': response.data.data[0].DesignationID })
            setName(response.data.data[0].Name)
            setMobileNo(response.data.data[0].MobileNo)
            setMobileNo2(response.data.data[0]?.MobileNo2 || '')
            setTelephoneNo(response.data.data[0]?.TelephoneNo || '')
            setEmailID(response.data.data[0]?.EmailID || '')
            setAddress(response.data.data[0]?.Address || '')
            setBirthdate(date)

        }, error => { })
    }
    const CheckAlert = (id) => {
        debugger;
        Swal.fire({
            title: 'Are you Sure You Want to Delete?', icon: "warning", showCancelButton: true, confirmButtonText: 'Delete', cancelButtonText: 'Cancel'
        }).then((result) => {

            if (result.isConfirmed) {
                var data = JSON.stringify({
                });
                var config = {
                    method: 'GET',
                    url: services.API_URL + "ContactDetail/DeleteContactDetail/" + id,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    console.log(response.data.status, 'staus')
                    if (response.data.status) {
                        GetContactDetail(pageCount);
                        Swal.fire({
                            title: 'Successfully Deleted', icon: "success", width: "400px", showConfirmButton: false, timer: 1500
                        });
                    } else {
                        Swal.fire({ position: 'top-end', icon: 'error', title: response.data.message, showConfirmButton: false, timer: 1500 });
                    }
                    ClearData();
                }, error => { })
            } else if (result.isDenied) {
                Swal.close();
            }
        });


    }
    const CloseClick = () => {
        setIssubmitvisible(true)
        setIsUpdatevisible(false)
        setStateID({ 'label': '--Select State--', 'value': '--Select State--' });
        setStateError('')
        setCityID({ 'label': '--Select City--', 'value': '--Select City--' });
        setCityError('')
        setCategoryID({ 'label': '--Select Category--', 'value': '--Select Category--' });
        setCategoryError('')
        setDesignationID({ 'label': '--Select Designation--', 'value': '--Select Designation--' });
        setDesignationError('')
        setName('')
        setNameerror('')
        setMobileNo('')
        setMobileNoerror('')
        setMobileNo2('')
        setMobileNo2error('')
        setTelephoneNo('')
        setTelephoneNoerror('')
        setAddress('')
        setBirthdate('')
        setBdateError('')
        setBirthdateerror('')
        setEmailID('')
        setEmailError('')
    }
    const ClearData = () => {
        setContactDetailID('')
        setStateID({ 'label': '--Select State--', 'value': '--Select State--' });
        setStateError('')
        setCityID({ 'label': '--Select City--', 'value': '--Select City--' });
        setCityError('')
        setCategoryID({ 'label': '--Select Category--', 'value': '--Select Category--' });
        setCategoryError('')
        setDesignationID({ 'label': '--Select Designation--', 'value': '--Select Designation--' });
        setDesignationError('')
        setName('')
        setNameerror('')
        setMobileNo('')
        setMobileNoerror('')
        setMobileNo2('')
        setMobileNo2error('')
        setBirthdateerror('')
        setBdateError('')
        setTelephoneNo('')
        setAddress('')
        setBirthdate('')
        setEmailID('')
    }

    const OnFilterClick = (e) => {
        setFilter1(false)
        setFilter(true);

        e.preventDefault();
        if (isfiltervisible === false) {
            setFilter(true);
            seticonFilter("fa fa-minus");
            seticonFilter1("fa fa-plus");
        }
        else {
            setFilter(false);
            seticonFilter("fa fa-plus");
            seticonFilter1("fa fa-minus");
        }
    }
    const OnFilterClick1 = (e) => {
        setFilter(false)
        setFilter1(true);

        e.preventDefault();
        if (isfiltervisible1 === false) {
            setFilter1(true);
            seticonFilter1("fa fa-minus");
            seticonFilter("fa fa-plus");
        }
        else {
            setFilter1(false);
            seticonFilter1("fa fa-plus");
            seticonFilter("fa fa-minus");
        }
    }

    const onSearchClick = (e) => {
        e.preventDefault();
        GetContactDetail(pageCount)
    }

    const OnSearchCancelClick = () => {
        // setSearch('')
        setCategoryID1({ 'label': '--Select Category--', 'value': '--Select Category--' });
        setDesignationID1({ 'label': '--Select Designation--', 'value': '--Select Designation--' });
        setMobileNo1('')
        setName1('')
        GetContactDetail(pageCount, "cancle");
    }

    const ExcelClick = () => {
        handleExcelExport();
    }

    return (
        <>
            <div className="body-wrapper">
                <div className="container-fluid">
                    <div className='col-md-12'>
                        {isfiltervisible &&
                            <div className="card">
                                <div className="px-3 py-2 border-bottom">
                                    <h5>Contact Detail</h5>
                                </div>
                                <FormWithConstraints
                                    ref={form}
                                    onSubmit={OnFilterClick}
                                    noValidate>
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className="col-md-3">
                                                <div className="form-group ">
                                                    <label htmlFor="State" className="form-label fw-semibold">State <span className="text-danger"> *</span></label>
                                                    <Select options={[{ label: '--Select State--', value: '--Select State--' }, ...Statees]} value={StateID} onChange={onStateChange} />
                                                    {StateError && <p style={{ color: 'red' }}>{StateError}</p>}
                                                </div><br></br>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group ">
                                                    <label htmlFor="City" className="form-label fw-semibold">City <span className="text-danger"> *</span></label>
                                                    <Select options={[{ label: '--Select City--', value: '--Select City--' }, ...Cityes]} value={CityID} onChange={onCityChange} placeholder="--Select City--" />
                                                    {CityError && <p style={{ color: 'red' }}>{CityError}</p>}
                                                </div> <br></br>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group ">
                                                    <label htmlFor="Designation" className="form-label fw-semibold">Designation <span className="text-danger"> *</span></label>
                                                    <Select options={[{ label: '--Select Designation--', value: '--Select Designation--' }, ...Designationes]} value={DesignationID} onChange={onDesignationChange} placeholder="--Select Designation--" />
                                                    {DesignationError && <p style={{ color: 'red' }}>{DesignationError}</p>}
                                                </div> <br></br>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group ">
                                                    <label htmlFor="Category" className="form-label fw-semibold">Category <span className="text-danger"> *</span></label>
                                                    <Select options={[{ label: '--Select Category--', value: '--Select Category--' }, ...Categoryes]} value={CategoryID} onChange={onCategoryChange} placeholder="--Select Category--" />
                                                    {CategoryError && <p style={{ color: 'red' }}>{CategoryError}</p>}
                                                </div> <br></br>
                                            </div>


                                            <div className='col-md-3'>
                                                <div className="form-group">
                                                    <label htmlFor="Name" className="form-label fw-semibold">Name <span className="text-danger"> *</span></label>
                                                    <input type="Name" onChange={handleNameChange} required value={Name} maxLength={50} onKeyPress={allowOnlyCharacters} type1="textnm" className="form-control" placeholder="Enter Name"></input>
                                                    {Nameerror && <span style={{ color: 'red' }}>{Name.length <= 0 ? 'Please Enter Name' : ''}</span>}
                                                </div>  <br></br>
                                            </div>

                                            <div className='col-md-3'>
                                                <div className="form-group">
                                                    <label htmlFor="MobileNo" className="form-label fw-semibold"> Mobile No <span className="text-danger"> *</span></label>
                                                    <input type="MobileNo" onChange={handleMobileChange} required value={MobileNo} maxLength={10} onKeyPress={allowOnlyNumbers} type1="textnm" className="form-control" placeholder="Enter Mobile No"></input>
                                                    <p style={{ color: 'red' }}>{MobileNoerror}</p>
                                                </div>
                                            </div>

                                            <div className='col-md-3'>
                                                <div className="form-group">
                                                    <label htmlFor="MobileNo2" className="form-label fw-semibold"> Mobile No 2</label>
                                                    <input type="MobileNo2" onChange={handleMobileChange2} required value={MobileNo2} maxLength={10} onKeyPress={allowOnlyNumbers} type1="textnm" className="form-control" placeholder="Enter Mobile No 2"></input>
                                                    <p style={{ color: 'red' }}>{MobileNo2error}</p>
                                                </div>
                                            </div>

                                            <div className='col-md-3'>
                                                <div className="form-group">
                                                    <label htmlFor="TelephoneNo" className="form-label fw-semibold"> Telephone No</label>
                                                    <input type="TelephoneNo" onChange={handleTelephoneNoChange} required value={TelephoneNo} maxLength={10} onKeyPress={allowOnlyNumbers} type1="textnm" className="form-control" placeholder="Enter Telephone No"></input>
                                                    <p style={{ color: 'red' }}>{TelephoneNoerror}</p>
                                                </div>
                                            </div>

                                            <div className='col-md-3'>
                                                <div className="form-group">
                                                    <label htmlFor="Address" className="form-label fw-semibold">Address</label>
                                                    <textarea name="Address" ref={ref} onChange={e => setAddress(e.target.value)} required value={Address} rows={2} cols={20} className="form-control" placeholder="Enter Address" />
                                                </div><br></br>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="Name" className="form-label fw-semibold">Birth Date <span className="text-danger"> *</span></label>
                                                    <DatePicker
                                                        selected={Birthdate}
                                                        onChange={handleBirthdatechange}
                                                        dateFormat="dd-MM-yyyy"
                                                        value={Birthdate}
                                                        placeholderText="dd-MM-yyyy"
                                                        className="form-control readonly"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                    {BdateError && <div style={{ color: 'red' }}>{BdateError}</div>}<br></br>
                                                </div>
                                                <p style={{ color: 'red' }}>{Birthdateerror}</p>
                                            </div>

                                            <div className='col-md-3'>
                                                <div className="form-group">
                                                    <label htmlFor="EmailID" className="form-label fw-semibold">Email ID</label>
                                                    <input type="EmailID" onChange={(event) => setEmailID(event.target.value)} required value={EmailID} maxLength={100} type1="textnm" className="form-control" placeholder="Enter Email ID"></input>
                                                    {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
                                                </div>
                                            </div>


                                            <div className='col-md-3' style={{ marginTop: '30px' }}>
                                                {issubmitvisible &&
                                                    <button type="button" onClick={submitHandler} className="btn btn-outline-primary font-weight-bolder ml-2" style={{ marginRight: '5px' }}>Submit</button>
                                                }
                                                {isUpdatevisible &&
                                                    <button type="button" onClick={submitHandler} className="btn btn-outline-primary font-weight-bolder ml-2" style={{ marginRight: '5px' }}>Update</button>
                                                }
                                                <button type="button" onClick={CloseClick} className="btn btn-outline-danger font-weight-bolder ml-2">Cancel</button>

                                            </div>
                                        </div>
                                    </div>
                                </FormWithConstraints>
                            </div>
                        }
                    </div>

                    {isfiltervisible1 &&
                        <div className="card">
                            <div className="border-bottom">
                                <h4 style={{ marginLeft: '15px', marginTop: '5px' }}>Searching</h4>
                            </div>
                            <div className="card-body">

                                <div className='row'>

                                    <div className="col-md-3">
                                        <div className="form-group ">
                                            <label htmlFor="Category1" className="form-label fw-semibold">Category</label>
                                            <Select options={[{ label: '--Select Category--', value: '--Select Category--' }, ...Categoryes1]} value={CategoryID1} onChange={onCategoryChange1} placeholder="--Select Category--" />
                                        </div><br></br>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group ">
                                            <label htmlFor="Designation" className="form-label fw-semibold">Designation</label>
                                            <Select options={[{ label: '--Select Designation--', value: '--Select Designation--' }, ...Designationes1]} value={DesignationID1} onChange={onDesignationChange1} placeholder="--Select Designation--" />
                                        </div>
                                    </div>

                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor="Name" className="form-label fw-semibold">Name</label>
                                            <input type="Name" onChange={handleNameChange1} required value={Name1} maxLength={50} onKeyPress={allowOnlyCharacters} type1="textnm" className="form-control" placeholder="Enter Name"></input>
                                        </div><br></br>
                                    </div>

                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label htmlFor="MobileNo1" className="form-label fw-semibold"> Mobile No </label>
                                            <input type="MobileNo1" onChange={handleMobileChange1} required value={MobileNo1} maxLength={10} onKeyPress={allowOnlyNumbers} type1="textnm" className="form-control" placeholder="Enter Mobile No"></input>
                                        </div>
                                    </div>


                                    {/* <div className="col-md-3">
                                        <div className="form-group ">
                                            <label htmlFor="Search" className="form-label fw-semibold">Search</label>
                                            <input type="Search" onChange={e => setSearch(e.target.value)} required value={Search} maxLength={50} type1="textnm" className="form-control" placeholder="Enter Search "></input>
                                            <span style={{ color: '#B71C1C', fontWeight: 'bold'}}> (Category Name, Designation, Name, MobileNo)</span>
                                        </div><br></br>
                                    </div> */}

                                    <div className="col-md-3">
                                        <button type="button" onClick={onSearchClick} className="btn btn-outline-primary font-weight-bolder ml-2 " style={{ marginRight: '3%', marginTop: '28px' }} >Search </button>
                                        <button type="button" onClick={OnSearchCancelClick} className="btn btn-outline-danger font-weight-bolder ml-2" style={{ marginRight: '1%', marginTop: '28px' }}>Cancel </button>
                                        <a className="btn btn-outline-success font-weight-bolder mt-12" onClick={ExcelClick} style={{ marginRight: '1%', marginTop: '28px', marginLeft: '2px' }}>
                                            <i id="btnExcel" className="fa fa-file-excel" /> Excel
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className='col-md-12'>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MaterialTable columns={cols} data={tableData}
                                actions={[
                                    {
                                        icon: 'edit', tooltip: 'Edit', onClick: (e, r) => EditContactDetail(r.ContactDetailID)
                                    },
                                    {
                                        icon: 'delete', tooltip: 'Delete', onClick: (e, r) => CheckAlert(r.ContactDetailID, r.Name)
                                    }
                                ]}

                                options={{
                                    headerStyle: { backgroundColor: '#B71C1C', color: 'white', fontFamily: 'system-ui' }, toolbar: true, actionsColumnIndex: -1, search: false,
                                    paging: false, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                                }}

                                components={{
                                    Toolbar: props => (
                                        <>
                                            <div className="row" >
                                                <div className="col-md-12" style={{ display: 'flex', alignItems: 'center', marginLeft: '1%', marginTop: '0.3%', marginBottom: '0.3%' }}>
                                                    <h5 style={{ marginRight: '5px' }}>View Contact Detail : <span className="badge bg-light-primary text-black fw-semibold fs-2">{TotalCount}</span></h5>

                                                    <div className="col-md-10" style={{ textAlign: 'right' }}>

                                                        <a className="btn btn-outline-dark font-weight-bolder" onClick={OnFilterClick1} style={{ marginTop: '5px', marginRight: '5px' }}>
                                                            <i id="btnFilter" className={iconFilter1} isfiltervisible='false' /> Filter
                                                        </a>

                                                        <a className="btn btn-outline-primary font-weight-bolder" onClick={OnFilterClick} style={{ marginRight: '8px', marginTop: '5px' }} >
                                                            <i id="btnFilter" className={iconFilter} isfiltervisible='false' /> Add Contact
                                                        </a>
                                                    </div>

                                                </div>
                                            </div>
                                        </>
                                    )
                                }}
                            />
                        </ThemeProvider>
                    </div>

                    <div id="ctl00_ContentPlaceHolder1_divPagination">
                        <div className="col-lg-12 col-md-12 col-12 cssPagination" style={{ boxShadow: '2px 2px 5px #808080' }}>
                            <nav aria-label="Page navigation">
                                <ul className="GridPager pagination" style={{ paddingTop: '2px' }}>
                                    <div style={{ marginTop: '0.5%', marginBottom: '0.5%', marginLeft: '3px' }}>
                                        <button type="button" onClick={setPreviousApi} className="btn btn-primary mt-12 mr-3 mobmt-2 page-disable" style={{ marginRight: '1.5%' }} >Previous </button>
                                    </div>
                                    <div style={{ marginTop: '0.5%', marginBottom: '0.5%', marginLeft: '3px' }} >
                                        <button type="button" onClick={setNextApi} className="btn btn-danger mt-12 mobmt-1 page-disable" style={{ marginRight: '1.5%' }}>Next </button>
                                    </div>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contactdetail

