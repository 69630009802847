import './App.css';
// import Routes from './Routing';
import Header from './component/Header';
import { Route, Routes } from 'react-router-dom'
import Addinvitation from './component/addinvitation';
import Viewinvitation from './component/viewinvitation';
import Bannermaster from './component/bannermaster';
import Addusermaster from './component/addusermaster';
import Viewuser from './component/viewuser';
import Home from './component/Home';
import Splash from './component/splash';
import MybeshowNavbar from './component/MybeshowNavbar';
import HandleLoginRoute from './component/HandleLoginRoute';
import Viewuserbirthdaydata from './component/Viewuserbirthdaydata';
import FestivalMaster from './component/FestivalMaster';
import Greetingwishes from './component/greetingwishes';
import Contactdetail from './component/contactdetail';
import Viewschedule from './component/viewschedule';
import Viewcardtextrecognize from './component/viewcardtextrecognize';
import Greetingdraft from './component/greetingdraft';
import Categorymaster from './component/categorymaster';
import UserDesignationmaster from './component/userdesignationmaster';
import Sendinvitation from './component/sendinvitation';
import Viewsendinvitation from './component/viewsendinvitation';
import ContactCard from './component/ContactCard';
import CalenderEvent from './component/CalenderEvent';
import ViewCalendarEvent from './component/ViewCalendarEvent';
import Viewdraft from './component/Viewdraft';
import Designationmaster from './component/Designationmaster';
import Privacypolicy from './component/privacypolicy';

function App() {
  return (
    <>
      <MybeshowNavbar>
        <Header />
      </MybeshowNavbar>

      <Routes>
        <Route path='/*' element={<HandleLoginRoute >
          <Routes>
            <Route path='/' element={<Home />}></Route>

            <Route path='/addinvitation' element={<Addinvitation />}></Route>
            <Route path='/viewinvitation' element={<Viewinvitation />}></Route>
            <Route path='/bannermaster' element={<Bannermaster />}></Route>
            <Route path='/addusermaster' element={<Addusermaster />}></Route>
            <Route path='/viewuser' element={<Viewuser />}></Route>
            <Route path='/home' element={<Home />}></Route>
            <Route path='/splash' element={<Splash />}></Route>
            <Route path='/Viewuserbirthdaydata' element={<Viewuserbirthdaydata />}></Route>
            <Route path='/FestivalMaster' element={<FestivalMaster />}></Route>
            <Route path='/greetingdraft' element={<Greetingdraft />}></Route>
            <Route path='/greetingwishes' element={<Greetingwishes />}></Route>
            <Route path='/contactdetail' element={<Contactdetail />}></Route>
            <Route path='/viewschedule' element={<Viewschedule />}></Route>
            <Route path='/viewcardtextrecognize' element={<Viewcardtextrecognize />}></Route>
            <Route path='/categorymaster' element={<Categorymaster />}></Route>
            <Route path='/userdesignationmaster' element={<UserDesignationmaster />}></Route>
            <Route path='/Designationmaster' element={<Designationmaster />}></Route>
            <Route path='/sendinvitation' element={<Sendinvitation />}></Route>
            <Route path='/viewsendinvitation' element={<Viewsendinvitation />}></Route>
            <Route path='/ContactCard' element={<ContactCard />}></Route>
            <Route path='/CalenderEvent' element={<CalenderEvent />}></Route>
            <Route path='/ViewCalendarEvent' element={<ViewCalendarEvent />}></Route>
            <Route path='/Viewdraft' element={<Viewdraft />}></Route>

          </Routes>
        </HandleLoginRoute>} />
        <Route path='/splash' element={<Splash />} />
        <Route exact path='/privacyandpolicy' element={
          <Privacypolicy />
        } />
      </Routes>
    </>
  );
}

export default App;
