import React from "react";
import { useState, useRef, useEffect } from "react";
import { FormWithConstraints, FieldFeedbacks, FieldFeedback, } from 'react-form-with-constraints';
import services from "../Services/Services";
import Swal from "sweetalert2";
// import { useCookies } from 'react-cookie';
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Button from '@mui/material/Button';
import { usePopupState, bindTrigger, bindDialog, } from 'material-ui-popup-state/hooks'
import { allowOnlyNumbers } from '../inputvalidation';
import { useCookies } from 'react-cookie';
import '../style.css';


const Splash = () => {

    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const [UserID, setUserID] = useState('')
    const [UserName, setUserName] = useState('')
    const [DesignationID, setDesignationID] = useState('')    

    const [MobileNo, setMobileNo] = useState('');
    const [Password, setPassword] = useState('');
    const [Error, setError] = useState(false)
    const [invalidUP, setinvalidUP] = useState(false);

    const [isOpen, setopen] = useState(false)
    const [isclose, setclose] = useState(false)

    const [OTP, setOTP] = useState('')
    const [OTPError, setOTPError] = useState('')
    const [invalidOTP, setinvalidOTP] = useState(false);


    const [data, getData] = useState([])
    const form = useRef(FormWithConstraints);

    const submitHandle = (e) => {
        debugger;
        e.preventDefault();
        setOTPError('')
        setinvalidOTP('')
        setinvalidUP(false)
        setError(false)
        form.current.validateFields();
        var allvalid = true;

        if (MobileNo.length == 0 || Password.length == 0) {
            setError(true)
            allvalid = false;
        }

        if (allvalid) {
            const data = JSON.stringify({
                "MobileNo": MobileNo,
                "Password": Password
            });

            var config = {
                method: 'POST',
                url: services.API_URL + "User/UserLoginPanel",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            services.JsonValue(config).then(response => {
                // debugger;
                if (response.data.status) {
                    setUserID(response.data.data[0].UserID)
                    setUserName(response.data.data[0].UserName)
                    setDesignationID(response.data.data[0].UserDesignationDetail)                   
                    setopen({ isOpen: true });
                    Swal.fire({ position: 'top-end', toast: true, icon: 'success', title: response.data.data[0].OTP, showConfirmButton: false, timer: 5000 });
                }
                else {
                    setinvalidUP({ invalidUP: true })
                }
                cleardata(); 

            }, error => { })
        }
    }
    const VerifyOTP = () => {
        setOTPError('')
        setinvalidOTP(false)

        var allvalid = true;
        debugger;
        if (OTP.length == 0) {
            setOTPError(true)
            allvalid = false;
        }

        if (allvalid == true) {
            const data = JSON.stringify({
                "UserID": UserID,
                "OTP": OTP,
            });

            var config = {
                method: 'POST',
                url: services.API_URL + "User/VerifyOTP",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            services.JsonValue(config).then(response => {
                // debugger;
                if (response.data.status) {
                    debugger;
                    if (OTP !== "") {

                        setopen(false);

                        Swal.fire({
                            title: 'Successfully Login', icon: "success", showConfirmButton: false, width:"400px" ,timer: 5000
                        });
                        setTimeout(() => {
                            setCookie('ckUserID', response.data.data[0].UserID);
                            setCookie('ckUserName', response.data.data[0].UserName);
                          
                            const designationDetail = Array.isArray(response.data.data[0].UserDesignationDetail) ?
                                response.data.data[0].UserDesignationDetail : JSON.parse(response.data.data[0].UserDesignationDetail);

                            const designationID = designationDetail?.map(item => item.DesignationID);

                            const commaSeparatedID = designationID?.join(', ');

                            setCookie('ckDesignationID', commaSeparatedID);

                            window.location.href = "/FestivalMaster";
                        }, 1500);
                        setMobileNo('')
                        setPassword('')
                    }
                }
                else {
                    setinvalidOTP(true);
                }
            }, error => { })
        }
    }
    const resend = () => {
        debugger;

        const data = JSON.stringify({
            "MobileNo": MobileNo
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "User/ResendOTP",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        services.JsonValue(config).then(response => {
            // debugger;
            if (response.data.status) {

                setopen({ isOpen: true });
                Swal.fire({ position: 'top-end', toast: true, icon: 'success', title: response.data.data[0].OTP, showConfirmButton: false, timer: 4000 });
            }
            else {
                Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
            }
            // cleardata();
        }, error => { })
    }
    const cleardata = () => {
        setOTP('')
        // setMobileNo('')
        // setPassword('')
    }
    const handlechangeOTP = (e) => {
        setOTP(e.target.value);
    }
    const handlechangeusername = (e) => {
        setMobileNo(e.target.value);
    }

    return (
        <>
            <div className="w3layouts-main">
                <div className="bg-layer">
                    <h1>KP Group</h1>
                    <div className="header-main">
                        <div className="main-icon">
                            <span className="fa fa-user" />
                        </div>
                        
                        <FormWithConstraints
                            ref={form}
                            onSubmit={submitHandle}
                            noValidate>
                            <div className="header-left-bottom">
                                <form action="#" method="post">
                                    <div className="icon1">
                                        <span className="fa fa-user" />
                                        <input name="txtUserName" type="text" id="txtUserName" className="input" value={MobileNo} onChange={handlechangeusername}  onKeyPress={allowOnlyNumbers} maxLength={10} placeholder="Enter Mobile No" /><br></br>
                                        {Error && <span className="text-danger">{MobileNo.length <= 0 ? 'Enter Mobile No' : ''}</span>}
                                    </div>

                                    <div className="icon1">
                                        <span className="fa fa-lock" />
                                        <input name="txtPassword" type="password" value={Password} onChange={(e) => setPassword(e.target.value)}  id="txtPassword" className="input" maxLength={10} placeholder="Enter Password" /><br></br>
                                        {Error && <span className="text-danger">{Password.length <= 0 ? 'Enter Password' : ''}</span>}
                                    </div>

                                    <div className="bottom">
                                        <button className="btn" onClick={submitHandle}>Log In</button>
                                        {invalidUP && <span className="text-danger" style={{ textAlign: 'center' }}>Invalid Username & Password</span>}
                                    </div>
                                </form>

                                <Dialog
                                    Backdrop
                                    open={isOpen}
                                    onClose={isclose}
                                    PaperProps={{ sx: { width: "400%", maxWidth: "400px!important", }, }}
                                >
                                   <DialogContent>
                                        <DialogContentText id="alert-dialog-description" > 
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <div className='card card-custom gutter-b example example-compact'>
                                                        <div className='card-body'>
                                                            <div className='row'>
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <label htmlFor="OTP" className="text-center">OTP</label>
                                                                        <input type="OTP" onChange={handlechangeOTP} value={OTP} type1="textnm" className="form-control" onKeyPress={allowOnlyNumbers} maxLength={4} placeholder="Enter OTP"></input>
                                                                        {OTPError && <span className="text-danger">{OTP.length <= 0 ? 'Please Enter OTP' : ''}</span>}
                                                                        {invalidOTP && <span className="text-danger">Invalid OTP</span>}
                                                                    </div>
                                                                </div>
                                                            </div><br></br>
                                                            <button type="submit" onClick={VerifyOTP} className="btn btn-outline-primary font-weight-bolder ml-2" style={{ marginRight: '2%' }} data-menu-toggle="click">Verify</button>
                                                            <button onClick={() => setopen(false)} className="btn btn-outline-danger font-weight-bolder ml-2" style={{ marginRight: '2%' }}>Cancel</button>
                                                            <button type="resend" onClick={resend} className="btn btn-outline-primary font-weight-bolder ml-2" style={{ marginRight: '2%' }}>Resend</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div >

                                         </DialogContentText>
                                    </DialogContent>
                                </Dialog>

                            </div>
                        </FormWithConstraints>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Splash
