import React, { useState, useRef, useEffect } from 'react'
import { FormWithConstraints, FieldFeedbacks, FieldFeedback, } from 'react-form-with-constraints';
import { ThemeProvider, createTheme } from '@mui/material';
import MaterialTable from 'material-table';
import { allowOnlyCharacters } from '../inputvalidation';
import services from "../Services/Services";
import Swal from "sweetalert2";
import { allowOnlyNumbers } from '../inputvalidation';
import { IoEyeSharp } from "react-icons/io5";
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { useNavigate } from 'react-router-dom';

const defaultMaterialTheme = createTheme();

const Viewuser = () => {

    const headerTblStyle = { color: 'Black' };
    const cols = [
        { title: (<div style={{ color: 'white', width: '8px' }}><span>Sr.No</span></div>), field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{(pageId * pageCount - 10) + rowData.tableData.id + 1}</p>) } },
        { title: (<div style={{ color: 'white', width: '120px' }}> <span>User Name</span></div>), headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '150px' }}>{rowData.UserName}</p>) } },
        {
            title: (<div style={{ color: 'white', width: '40px' }}> <span>Password</span></div>), headerStyle: headerTblStyle, render: rowData => {
                return (
                    <p style={{ marginBottom: '1px', size: '10px' }}>
                        <IoEyeSharp onClick={() => openDialog(rowData.UserID)} />

                        <Dialog
                            Backdrop
                            open={isOpen}
                            onClick={setclose}
                            PaperProps={{ sx: { width: "400%", maxWidth: "400px!important", }, }}

                        >
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description" >

                                    <div className='col-md-12'>
                                        {/* <label htmlFor="password" className="form-label fw-semibold">
                                            Show Password
                                        </label> */}
                                        <div className='card card-custom gutter-b example example-compact'>
                                            <div className='card-body'>
                                                <div className='row'>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label htmlFor="Pin" className="form-label fw-semibold">Enter Pin</label>
                                                            <input type="Pin" onChange={handlechangePin} value={Pin} className="form-control" onKeyPress={allowOnlyNumbers} maxLength={4} placeholder="Enter Pin"></input>
                                                            {isShowPassword && !Pin.length && <span className="text-danger">{'Please Enter Pin'}</span>}
                                                            {!checkpass && (Pin?.length > 0) && isShowPassword && <span className="text-danger">Invalid Pin</span>}
                                                        </div>
                                                    </div>
                                                    {checkpass && <span className="text-danger">Your password is : {Password}</span>}
                                                </div><br></br>
                                                <button type="submit" onClick={() => showPassword(rowData.UserID)} className="btn btn-outline-primary font-weight-bolder ml-2" style={{ marginRight: '2%' }} data-menu-toggle="click">Show Password</button>
                                                <button onClick={closeDialog} className="btn btn-outline-danger font-weight-bolder ml-2" style={{ marginRight: '2%' }}>Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </DialogContentText>
                            </DialogContent>
                        </Dialog>
                    </p >)
            }
        },
        { title: (<div style={{ color: 'white', width: '120px' }}> <span>User Designation Name</span></div>), headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '100px' }}>{rowData.DesignationName}</p>) } },
        // { title: (<div style={{ color: 'white', width: '120px' }}> <span>User Designation</span></div>), headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '100px' }}>{rowData.DesignationName}</p>) } },
        { title: (<div style={{ color: 'white', width: '110px' }}> <span>Mobile No</span></div>), headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.MobileNo}</p>) } },
        { title: (<div style={{ color: 'white', width: '140px' }}> <span>Address</span></div>), headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '200px' }}>{rowData.Address}</p>) } },
        { title: (<div style={{ color: 'white', width: '110%' }}> <span>Birth Date</span></div>), field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '85px' }}>{rowData.BirthDate}</p>) } },
        { title: (<div style={{ color: 'white', width: '40%' }}> <span>Date</span></div>), field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '85px' }}>{rowData.CreatedDate}</p>) } },
    ]

    const openDialog = (UserID) => {
        console.log(UserID, 'useid')
        setPin('');
        setPinError('');
        setopen(true);

        const data = JSON.stringify({
            "UserID": UserID,
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "User/ShowPassword",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        services.JsonValue(config).then(response => {
            // debugger;
            console.log(response.data, 'data')
            if (response.data.status) {
                debugger;
                setPassword(response.data.data[0]?.Password || "")
            }
        }, error => { })
    };
    const closeDialog = () => {
        setIsAShowPassword(false)
        setopen(false);
        setCheckPass(false)
        setPinError('')
    };
    const showPassword = () => {
        setIsAShowPassword(true)
        if (Pin === "6713") {
            setCheckPass(true)
        } else {
            setCheckPass(false)
        }
    }
    const handlechangePin = (e) => {
        setPin(e.target.value);
        setPinError('')
    }

    const [checkpass, setCheckPass] = useState(false)
    const [isShowPassword, setIsAShowPassword] = useState(false)

    const [Password, setPassword] = useState("")

    const [Name, setName] = useState('')
    const [MobileNo, setMobileNo] = useState('')

    const [iconFilter, seticonFilter] = useState('fa fa-plus')
    const [isfiltervisible, setFilter] = useState(false)

    const [iconFilter1, seticonFilter1] = useState('fa fa-plus')

    const [isOpen, setopen] = useState(false)
    const [isclose, setclose] = useState(false)

    const [Pin, setPin] = useState('')
    const [PinError, setPinError] = useState('')

    const [pageCount, setPageCount] = useState(10);
    const [pageId, setPageId] = useState(1);
    const [tableData, setTableData] = useState([]);
    const [allTableData, setAllTableData] = useState([]);
    const [TotalCount, setTotalCount] = useState('')

    const form = useRef(FormWithConstraints);
    const navigate = useNavigate();
    useEffect(() => {
        GetUser(pageCount);
    }, []);

    const GetUser = (pageCount, st) => {
        debugger;

        var NM = "";
        var MNo = "";

        if (Name != "") {
            NM = Name
        }

        if (MobileNo != "") {
            MNo = MobileNo
        }

        var data = JSON.stringify({

            "UserID": "",
            "UserName": st == "cancle" ? "" : NM,
            "MobileNo": st == "cancle" ? "" : MNo,
            "BirthDate": "",
            "StartDate": "",
            "EndDate": "",
            "PaginationID": "0",
            "PageLength": `${pageCount}`
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "User/GetUser",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            console.log(response.data, 'data')
            if (response.data.status) {
                setTableData(response.data.data)
                setAllTableData(response.data.data)
                setPageId(1)
                setTotalCount(response.data.total[0].TotalCount);
            }
            else {
                setTableData([]);
            }
        }, error => { })
    }

    const setPreviousApi = () => {
        debugger;
        if (pageId == 1) {
            return
        }
        const tempt = [...allTableData]
        console.log((pageId - 2) * pageCount, (pageId - 1) * pageCount, 'alltabledata')
        const sliced = tempt.slice((pageId - 2) * pageCount, (pageId - 1) * pageCount)

        setTableData(sliced)
        setPageId(pageId - 1)

    };
    const setNextApi = () => {
        debugger;

        if (!tableData.length) {
            return;
        }
        if (pageId * pageCount < allTableData.length) {
            const tempt = [...allTableData];
            const sliced = tempt.slice((pageId) * pageCount, (pageId + 1) * pageCount);
            setTableData(sliced);
            setPageId(pageId + 1);
            return;
        }

        const paginationIndex = allTableData[pageId * pageCount - 1]?.UserID;
        if (!paginationIndex) {
            return;
        }


        var data = JSON.stringify({
            "UserID": "",
            "UserName": "",
            "MobileNo": "",
            "BirthDate": "",
            "StartDate": "",
            "EndDate": "",
            "PaginationID": paginationIndex,
            "PageLength": `${pageCount}`
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "User/GetUser",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        services.JsonValue(config).then(response => {
            if (response.data.status) {
                setTableData(response.data.data)
                setPageId(pageId + 1)
                setAllTableData([...allTableData, ...response.data.data])
                setTotalCount(response.data.total[0].TotalCount);
            }
        }, error => { })
    };
    const OnFilterClick = (e) => {
        e.preventDefault();
        if (isfiltervisible === false) {
            setFilter(true);
            seticonFilter("fa fa-minus");
        }
        else {
            setFilter(false);
            seticonFilter("fa fa-plus");
        }
    }
    const onSearchClick = (e) => {
        e.preventDefault();
        GetUser(pageCount);
    }
    const OnSearchCancelClick = () => {
        setName('')
        setMobileNo('')
        GetUser(pageCount, "cancle");
    }
    const CloseClick = () => {
       navigate('/addusermaster')
    }
    const CheckAlert = (id) => {
        debugger;
        Swal.fire({
            title: 'Are you Sure You Want to Delete?', icon: "warning", showCancelButton: true, confirmButtonText: 'Delete', cancelButtonText: 'Cancel'
        }).then((result) => {

            if (result.isConfirmed) {
                var data = JSON.stringify({

                });
                var config = {
                    method: 'GET',
                    url: services.API_URL + "User/DeleteUser/" + id,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    console.log(response.data.status, 'staus')
                    if (response.data.status) {

                        Swal.fire({
                            title: 'Successfully Deleted', icon: "success", width: "400px", showConfirmButton: false, timer: 1500
                        });
                    } else {
                        Swal.fire({ position: 'top-end', icon: 'error', title: response.data.message, showConfirmButton: false, timer: 1500 });
                    }
                    GetUser(pageCount);


                }, error => { })
            } else if (result.isDenied) {
                Swal.close();
            }
        });
    }

    const EditUser = (e) => {
        debugger;
        navigate("/addusermaster", {
            state: e
        });
    };

    return (
        <>
            <div className="body-wrapper">
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <FormWithConstraints
                                ref={form}
                                onSubmit={OnFilterClick}
                                noValidate>

                                {isfiltervisible &&
                                    <div className="card">
                                        <div className="px-3 py-2 border-bottom">
                                            <h5>Searching</h5>
                                        </div>
                                        <div className="card-body">
                                            <div className='row'>

                                                <div className='col-md-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="Name" className="form-label fw-semibold">Name</label>
                                                        <input type="Name" onChange={(e) => setName(e.target.value)} required value={Name} maxLength={10} onKeyPress={allowOnlyCharacters} type1="textnm" className="form-control" placeholder="Enter Name"></input>
                                                    </div><br></br>
                                                </div>

                                                <div className='col-md-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="MobileNo" className="form-label fw-semibold">Mobile No</label>
                                                        <input type="MobileNo" onChange={(event) => setMobileNo(event.target.value)} required value={MobileNo} maxLength={10} onKeyPress={allowOnlyNumbers} type1="textnm" className="form-control" placeholder="Enter Mobile No"></input>
                                                    </div>
                                                </div>

                                                <div className="col-md-3" style={{ marginTop: '30px' }}>
                                                    <button type="button" onClick={onSearchClick} className="btn btn-outline-primary font-weight-bolder ml-2" style={{ marginRight: '2%' }} >Search </button>
                                                    <button type="button" onClick={OnSearchCancelClick} className="btn btn-outline-danger font-weight-bolder ml-2" style={{ marginRight: '2%' }}>Cancel </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </FormWithConstraints>
                        </div>
                    </div>

                    <div className='col-md-12'>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MaterialTable columns={cols} data={tableData}

                                actions={[
                                    {
                                        icon: 'edit', tooltip: 'Edit', onClick: (e, r) => EditUser(r.UserID)
                                    },
                                    {
                                        icon: 'delete', tooltip: 'Delete', onClick: (e, r) => CheckAlert(r.UserID, r.Name)
                                    }
                                ]}

                                options={{
                                    headerStyle: { backgroundColor: '#B71C1C', color: 'white', fontFamily: 'system-ui' }, toolbar: true, actionsColumnIndex: -1,
                                    paging: false, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                                }}

                                components={{
                                    Toolbar: props => (

                                        <div className="row" >
                                            <div className="col-md-12" style={{ display: 'flex', alignItems: 'center', marginLeft: '1%', marginTop: '0.2%', marginBottom: '0.3%' }}>
                                                <h5 style={{ marginRight: '5.5%' }}> View User Detail : <span className="badge bg-light-primary text-black fw-semibold fs-2">{TotalCount}</span></h5>

                                                <div className="col-md-9" style={{ textAlign: 'right', width: '80%' }}>
                                                    <a className="btn btn-outline-primary font-weight-bolder" style={{ marginTop: '5px', marginRight: '6px' }} onClick={OnFilterClick}>
                                                        <i id="btnFilter" className={iconFilter} isfiltervisible='false' /> Filter
                                                    </a>

                                                    <a className="btn btn-outline-dark font-weight-bolder" style={{ marginRight: '3%', marginTop: '5px' }} onClick={CloseClick}>
                                                        <i id="btnFilter" className={iconFilter1} isfiltervisible='false' /> Add User Master
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }}

                                detailPanel={[
                                    {
                                        icon: '', tooltip: 'DesignationDetail',
                                        render: rowData => {
                                            debugger;
                                            return (
                                                rowData.UserDesignationDetail !== null ? (
                                                    <table className="table table-light-dark" align="center" cellSpacing="0" rules="all" border="1" style={{ width: '100%', bordercollapse: 'collapse', border: '1px solid black' }}>
                                                        <thead>
                                                            <tr>
                                                                <td style={{ backgroundColor: '#B71C1C', border: '1px solid black', color: 'white', fontWeight: 'bold' }}>Designation Name</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th>
                                                                    {rowData.UserDesignationDetail?.map((value, index) => (
                                                                        <span key={index}
                                                                            className="label font-weight-bold label-lg label-light-Success label-inline"
                                                                            style={{ border: '1px solid black', padding: '5px', marginRight: '5px', marginBottom: '5px', display: 'inline-block' }}>
                                                                            {value.DesignationName}
                                                                        </span>
                                                                    ))}
                                                                </th>
                                                            </tr>
                                                        </tbody>
                                                    </table>


                                                ) : null
                                            )

                                        }
                                    },


                                ]}

                            />
                        </ThemeProvider>
                    </div>

                    <div id="ctl00_ContentPlaceHolder1_divPagination">
                        <div className="col-lg-12 col-md-12 col-12 cssPagination" style={{ boxShadow: '2px 2px 5px #808080' }}>
                            <nav aria-label="Page navigation">
                                <ul className="GridPager pagination" style={{ paddingTop: '2px' }}>
                                    <div style={{ marginTop: '0.5%', marginBottom: '0.5%', marginLeft: '3px' }}>
                                        <button type="button" onClick={setPreviousApi} className="btn btn-primary mt-12 mr-3 mobmt-2 page-disable" style={{ marginRight: '1.5%' }} >Previous </button>
                                    </div>
                                    <div style={{ marginTop: '0.5%', marginBottom: '0.5%', marginLeft: '3px' }} >
                                        <button type="button" onClick={setNextApi} className="btn btn-danger mt-12 mobmt-1 page-disable" style={{ marginRight: '1.5%' }}>Next </button>
                                    </div>
                                </ul>
                            </nav>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default Viewuser
