import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material';
import MaterialTable from 'material-table';
import { useState, useRef, useEffect } from "react";
import { FormWithConstraints, FieldFeedbacks, FieldFeedback, } from 'react-form-with-constraints';
import services from "../Services/Services";
import Swal from "sweetalert2";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from 'react-select'
import DOMPurify from "dompurify"
import { useCookies } from 'react-cookie';
import XLSX from "xlsx-color";
import moment from 'moment';
import { allowOnlyNumbers, allowOnlyCharacters } from '../inputvalidation';
import { common } from '@material-ui/core/colors';


const defaultMaterialTheme = createTheme();

const Greetingwishes = () => {

    const [cookies, setCookie] = useCookies(['user']);
    const [ckUserID, setckUserID] = useState(cookies.ckUserID);

    const headerTblStyle = { color: 'Black' };
    const cols = [
        { title: (<div style={{ color: 'white', width: '9px' }}><span>Sr.No</span></div>), field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '5px' }}>{(pageId * pageCount - 10) + rowData.tableData.id + 1}</p>) } },
        // { title: (<div style={{ width: '100%', color: 'white' }}><span>User Name</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '100px' }}>{rowData.UserName}</p>) } },
        { title: (<div style={{ color: 'white' }}><span>Festival Name</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '150px' }}>{rowData.FestivalName}</p>) } },
        { title: (<div style={{ color: 'white' }}><span>Name</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '150px' }}>{rowData.Name}</p>) } },
        { title: (<div style={{ color: 'white' }}><span>Mobile No</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '100px' }}>{rowData.MobileNo}</p>) } },
        { title: (<div style={{ color: 'white' }}><span>Email ID</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '300px' }}>{rowData.EmailID}</p>) } },
    ]

    const form = useRef(FormWithConstraints);

    const handleExcelExport = async () => {
        var data = JSON.stringify({
            "FestivalCardID": "",
            "UserID": "",
            "FestivalID": "",
            "MobileNo": "",
            "StartDate": "",
            "EndDate": "",
            "PaginationID": "-1",
            "PageLength": ""
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "FestivalCard/GetFestivalCard",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                exportToExcel(response.data.data)
            }

        }, error => { })
    };

    const exportToExcel = (array) => {
        console.log(array, 'data');
        debugger;
        const header = ["Sr.No", "Festival Name", "Name", "Mobile No"];
        const body = [];
        array?.forEach((col, index) => {
            body.push([index + 1, col.FestivalName, col.Name, col.MobileNo,]);
        });

        if (!body.length) {
            return Swal.fire({
                title: "No Data Found!",
                icon: "error",
                timer: 1500
            });
        }

        const ws1 = XLSX.utils.aoa_to_sheet([header, ...body]);

        header.forEach((col, index) => {
            ws1[XLSX.utils.encode_cell({ r: 0, c: index })].s = {
                font: { bold: true, color: { rgb: "f7f4ef" } }, // White font color
                fill: { patternType: 'solid', fgColor: { rgb: "#B71C1C" } }, // Blue background color
                alignment: { wrapText: index === 3, horizontal: 'center' }, // Enable wrap text for the "Draft" column
            };
        });

        ws1['!cols'] = [
            { width: 6 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
        ];


        let cell = {
            font: { bold: true, color: { rgb: "f7f4ef" } },
            fill: { patternType: 'solid', fgColor: { rgb: "#B71C1C" } },
            alignment: { wrapText: true, horizontal: 'center' }, // Enable wrap text for the "Draft" column
            border: {
                left: { style: 'thin', color: { rgb: '000000' } },
                right: { style: 'thin', color: { rgb: '000000' } },
                top: { style: 'thin', color: { rgb: '000000' } },
                bottom: { style: 'thin', color: { rgb: '000000' } }
            }
        };

        ws1['A1'].s = cell;
        ws1['B1'].s = cell;
        ws1['C1'].s = cell;
        ws1['D1'].s = cell;

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws1, 'GreetingWishes');

        XLSX.writeFile(wb, `GreetingWishes_${moment().format('DD-MM-YYYY_HH:mm')}.xlsx`);
    };


    const [FestivalCardID, setFestivalCardID] = useState('')

    const [FestivalID, setFestivalID] = useState({ 'label': '--Select Festival Type--', 'value': '--Select Festival Type--' });
    const [Festivales, getFestival] = useState([])
    const [FestivalErrMsg, setFestivalErrMsg] = useState('');

    const [Name, setName] = useState('')
    const [Nameerror, setNameerror] = useState('')

    const [MobileNo, setMobileNo] = useState('')
    const [MobileNoerror, setMobileNoerror] = useState('')

    const [EmailID, setEmailID] = useState('')
    const [emailError, setEmailError] = useState('')

    const [pageCount, setPageCount] = useState(10);
    const [pageId, setPageId] = useState(1);
    const [tableData, setTableData] = useState([]);
    const [allTableData, setAllTableData] = useState([]);
    const [TotalCount, setTotalCount] = useState('')

    const [issubmitvisible, setIssubmitvisible] = useState(true)
    const [isUpdatevisible, setIsUpdatevisible] = useState(false)
    const ref = useRef();


    useEffect(() => {
        GetFestivalCard(pageCount);
        GetFestivalMaster();
    }, []);

    const GetFestivalCard = (pageCount) => {
        debugger;
        var data = JSON.stringify({
            "FestivalCardID": "",
            "UserID": "",
            "FestivalID": "",
            "MobileNo": "",
            "StartDate": "",
            "EndDate": "",
            "PaginationID": "0",
            "PageLength": `${pageCount}`

        });
        var config = {
            method: 'POST',
            url: services.API_URL + "FestivalCard/GetFestivalCard",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                setTableData(response.data.data)
                setAllTableData(response.data.data)
                setPageId(1)
                setIssubmitvisible(true)
                setIsUpdatevisible(false)
                setTotalCount(response.data.total[0].TotalCount);
            }

        }, error => { })
    }

    const setPreviousApi = () => {
        debugger;
        if (pageId == 1) {
            return
        }
        const tempt = [...allTableData]
        console.log((pageId - 2) * pageCount, (pageId - 1) * pageCount, 'alltabledata')
        const sliced = tempt.slice((pageId - 2) * pageCount, (pageId - 1) * pageCount)

        setTableData(sliced)
        setPageId(pageId - 1)

    };
    const setNextApi = () => {
        debugger;
        // console.log(pageId, "pageID", pageCount)

        if (!tableData.length) {
            return;
        }
        if (pageId * pageCount < allTableData.length) {
            const tempt = [...allTableData];
            const sliced = tempt.slice((pageId) * pageCount, (pageId + 1) * pageCount);
            setTableData(sliced);
            setPageId(pageId + 1);
            return;
        }

        const paginationIndex = allTableData[pageId * pageCount - 1]?.FestivalCardID;
        if (!paginationIndex) {
            return;
        }

        var data = JSON.stringify({
            "FestivalCardID": "",
            "UserID": "",
            "FestivalID": "",
            "MobileNo": "",
            "StartDate": "",
            "EndDate": "",
            "PaginationID": paginationIndex,
            "PageLength": `${pageCount}`
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "FestivalCard/GetFestivalCard",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        services.JsonValue(config).then(response => {
            if (response.data.status) {
                setTableData(response.data.data)
                setPageId(pageId + 1)
                setAllTableData([...allTableData, ...response.data.data])
                setTotalCount(response.data.total[0].TotalCount);
            }

        }, error => { })
    };

    // const GetUserMaster = () => {
    //     var data = JSON.stringify({
    //         "UserID": "",
    //         "UserName": "",
    //         "MobileNo": "",
    //         "BirthDate": "",
    //         "StartDate": "",
    //         "EndDate": "",
    //         "PaginationID": "0",
    //         "PageLength": "10"
    //     });
    //     var config = {
    //         method: 'POST',
    //         url: services.API_URL + "User/GetUser",
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         data: data
    //     };
    //     services.JsonValue(config).then(response => {
    //         debugger;
    //         if (response.data.status) {
    //             getUser(response.data.data.map(item => ({ value: item.UserID, label: item.UserName })))
    //         }
    //         else {
    //             getUser([]);
    //         }
    //     }, error => { })
    // }
    // const onUserChange = (e) => {
    //     setUserID(e);
    //     setUserErrMsg('')
    // }

    const GetFestivalMaster = () => {
        var data = JSON.stringify({
            "FestivalID": "",
            "FestivalName": "",
            "PaginationID": "-1",
            "PageLength": ""
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "FestivalMaster/GetFestivalMaster",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            debugger;
            if (response.data.status) {
                getFestival(response.data.data.map(item => ({ value: item.FestivalID, label: item.FestivalName })))
            }
            else {
                getFestival([]);
            }
        }, error => { })
    }
    const onFestivalChange = (e) => {
        setFestivalID(e);
        setFestivalErrMsg('')
    }

    const handleNameChange = (e) => {
        setName(e.target.value);
        setNameerror('')
    }


    const handleMobileChange = (e) => {
        setMobileNoerror('')
        setMobileNo(e.target.value);

        const inputReferenceMobileNoNumber = e.target.value;
        const MoNo = inputReferenceMobileNoNumber.replace(/\D/g, '');
        setMobileNo(MoNo);
        setMobileNoerror('');
    }

    const validateEmailID = () => {
        if (EmailID.length) {
            const EmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (EmailID.match(EmailRegex)) {
                setEmailError('');
                return true;
            } else {
                setEmailError('Please Enter Valid Email ID');
                return false;
            }
        }
        return true;
    };

    const submitHandler = (e) => {
        debugger;

        var allvalid = true;

        if (FestivalID.value === "--Select Festival Type--") {
            setFestivalErrMsg('Please Select Festival Type');
            allvalid = false;
        }

        if (Name.length === 0) {
            setNameerror(true)
            allvalid = false;
        }

        if (MobileNo?.trim() !== '') {
            if (/^\d{10}$/.test(MobileNo)) {
                setMobileNoerror('');
            } else {
                setMobileNoerror('Please Enter Valid 10 Digit Mobile Number');
                allvalid = false;
            }
        } else {
            setMobileNoerror('Please Enter Mobile No');
            allvalid = false;
        }

        const isEmailValid = validateEmailID();
        if (!isEmailValid) {
            allvalid = false;
        }

        if (allvalid) {

            Swal.fire({
                title: 'Are you sure?',
                // text: 'Do you want to submit?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, submit!',
            }).then((result) => {
                if (result.isConfirmed) {

                    var data = "";
                    if (FestivalCardID != "") {
                        data = JSON.stringify({
                            "FestivalCardID": FestivalCardID,
                            "UserID": ckUserID,
                            "FestivalID": FestivalID.value,
                            "Name": Name,
                            "MobileNo": MobileNo,
                            "EmailID": EmailID
                        });
                    }
                    else {
                        data = JSON.stringify({
                            "FestivalCardID": "",
                            "UserID": ckUserID,
                            "FestivalID": FestivalID.value,
                            "Name": Name,
                            "MobileNo": MobileNo,
                            "EmailID": EmailID
                        });
                    }

                    var config = {
                        method: 'POST',
                        url: services.API_URL + "FestivalCard/SetFestivalCard",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: data
                    };
                    services.JsonValue(config).then(response => {
                        //// debugger;
                        if (response.data.status) {
                            // debugger;
                            if (FestivalCardID != "") {
                                // Swal.fire({ position: 'top-end', toast: true, icon: 'success', title: 'Successfully Updated', showConfirmButton: false, timer: 1500 });
                                Swal.fire({
                                    title: 'Successfully Updated', icon: "success",  width:"400px", showConfirmButton: false, timer: 1500
                                });
                            }
                            else {
                                Swal.fire({
                                    title: 'Successfully Inserted', icon: "success",  width:"400px", showConfirmButton: false, timer: 1500
                                });
                            }
                            ClearData();
                            GetFestivalCard(pageCount);
                        }
                        else {
                            Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                        }
                    }, error => { })
                }
            })
        }

    }

    const EditGreetingwishes = (id) => {
        debugger;

        setIsUpdatevisible(true)
        setIssubmitvisible(false)
        setNameerror('')
        setMobileNoerror('')
        setFestivalErrMsg('')

        var data = JSON.stringify({
            "FestivalCardID": id,
            "UserID": "",
            "FestivalID": "",
            "MobileNo": "",
            "StartDate": "",
            "EndDate": "",
            "PaginationID": "-1",
            "PageLength": ""
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "FestivalCard/GetFestivalCard",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        services.JsonValue(config).then(response => {

            setFestivalCardID(response.data.data[0].FestivalCardID)
            // setUserID({ 'label': response.data.data[0].UserName, 'value': response.data.data[0].UserID })
            setFestivalID({ 'label': response.data.data[0].FestivalName, 'value': response.data.data[0].FestivalID })
            setName(response.data.data[0].Name)
            setMobileNo(response.data.data[0].MobileNo)
        }, error => { })
    }

    const CheckAlert = (id) => {
        debugger;
        Swal.fire({
            title: 'Are you Sure You Want to Delete?', icon: "warning", showCancelButton: true, confirmButtonText: 'Delete', cancelButtonText: 'Cancel'
        }).then((result) => {

            if (result.isConfirmed) {
                var data = JSON.stringify({
                    "FestivalCardID": id
                });
                var config = {
                    method: 'POST',
                    url: services.API_URL + "FestivalCard/DeleteFestivalCard",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    console.log(response.data.status, 'staus')
                    if (response.data.status) {
                        GetFestivalCard(pageCount);
                        Swal.fire({
                            title: 'Successfully Deleted', icon: "success",  width:"400px", showConfirmButton: false, timer: 1500
                        });
                    } else {
                        Swal.fire({ position: 'top-end', icon: 'error', title: response.data.message, showConfirmButton: false, timer: 1500 });
                    }
                    ClearData();
                }, error => { })
            } else if (result.isDenied) {
                Swal.close();
            }
        });


    }

    const CloseClick = () => {
        setIssubmitvisible(true)
        setIsUpdatevisible(false)
        setFestivalID({ 'label': '--Select Festival Type--', 'value': '--Select Festival Type--' });
        setFestivalErrMsg('')
        setName('')
        setNameerror('')
        setMobileNo('')
        setMobileNoerror('')
        setEmailID('')
        setEmailError('')

    }
    const ClearData = () => {
        setFestivalCardID('')
        setFestivalID({ 'label': '--Select Festival Type--', 'value': '--Select Festival Type--' });
        setFestivalErrMsg('')
        setName('')
        setNameerror('')
        setMobileNo('')
        setMobileNoerror('')
        setEmailID('')
        setEmailError('')
    }

    const ExcelClick = () => {
        handleExcelExport();
    }


    return (
        <>
            <div className="body-wrapper">
                <div className="container-fluid">

                    <div className='col-md-12'>
                        <div className="card">
                            <div className="px-3 py-2 border-bottom">
                                <h5>Greeting wishes </h5>
                            </div>
                            <FormWithConstraints
                                ref={form}
                                // onSubmit={submitHandler}
                                noValidate>

                                <div className="card-body">

                                    <div className='row'>

                                        <div className="col-md-3">
                                            <div className="form-group ">
                                                <label htmlFor="Festival" className="form-label fw-semibold">Festival Type <span className="text-danger"> *</span></label>
                                                <Select options={[{ label: '--Select Festival Type--', value: '--Select Festival Type--' }, ...Festivales]} value={FestivalID} onChange={onFestivalChange} placeholder="--Select Festival Type--" />
                                                {FestivalErrMsg && <p style={{ color: 'red' }}>{FestivalErrMsg}</p>}
                                            </div><br></br>
                                        </div>

                                        <div className='col-md-3'>
                                            <div className="form-group">
                                                <label htmlFor="Name" className="form-label fw-semibold">Name<span className="text-danger"> *</span></label>
                                                <input type="Name" onChange={handleNameChange} required value={Name} maxLength={50} onKeyPress={allowOnlyCharacters} type1="textnm" className="form-control" placeholder="Enter Name"></input>
                                                {Nameerror && <span style={{ color: 'red' }}>{Name.length <= 0 ? 'Please Enter Name' : ''}</span>}
                                            </div><br></br>
                                        </div>

                                        <div className='col-md-3'>
                                            <div className="form-group">
                                                <label htmlFor="MobileNo" className="form-label fw-semibold"> Mobile No <span className="text-danger"> *</span></label>
                                                <input type="MobileNo" onChange={handleMobileChange} required value={MobileNo} maxLength={10} onKeyPress={allowOnlyNumbers} type1="textnm" className="form-control" placeholder="Enter Mobile No"></input>
                                                <p style={{ color: 'red' }}>{MobileNoerror}</p>
                                            </div>
                                        </div>

                                        <div className='col-md-3'>
                                            <div className="form-group">
                                                <label htmlFor="EmailID" className="form-label fw-semibold">Email ID</label>
                                                <input type="EmailID" onChange={(event) => setEmailID(event.target.value)} required value={EmailID} maxLength={100} type1="textnm" className="form-control" placeholder="Enter Email ID"></input>
                                                {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
                                            </div>
                                        </div>

                                        <div className='col-md-3' style={{ marginTop: '30px' }}>
                                            {issubmitvisible &&
                                                <button type="button" onClick={submitHandler} className="btn btn-outline-primary font-weight-bolder ml-2" style={{ marginRight: '5px' }}>Submit</button>
                                            }
                                            {isUpdatevisible &&
                                                <button type="button" onClick={submitHandler} className="btn btn-outline-primary font-weight-bolder ml-2" style={{ marginRight: '5px' }}>Update</button>
                                            }
                                            <button type="button" onClick={CloseClick} className="btn btn-outline-danger font-weight-bolder ml-2">Cancel</button>

                                            <a className="btn btn-outline-success font-weight-bolder mt-12" onClick={ExcelClick} style={{ marginLeft: '5px' }}>
                                                <i id="btnExcel" className="fa fa-file-excel" /> Excel
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </FormWithConstraints>
                        </div>
                    </div>

                    <div className='col-md-12'>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MaterialTable columns={cols} data={tableData}
                                actions={[
                                    {
                                        icon: 'edit', tooltip: 'Edit', onClick: (e, r) => EditGreetingwishes(r.FestivalCardID)
                                    },
                                    {
                                        icon: 'delete', tooltip: 'Delete', onClick: (e, r) => CheckAlert(r.FestivalCardID, r.FestivalName)
                                    }
                                ]}

                                options={{
                                    headerStyle: { backgroundColor: '#B71C1C', color: 'white', fontFamily: 'system-ui' }, toolbar: true, actionsColumnIndex: -1, search: false,
                                    paging: false, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                                }}


                                components={{
                                    Toolbar: props => (
                                        <>
                                            <div className="row" >
                                                <div className="col-md-12" style={{ display: 'flex', alignItems: 'center', marginLeft: '1%', marginTop: '5px', marginBottom: '0.3%' }}>
                                                    <h5>View Greeting Wishes : <span className="badge bg-light-primary text-black fw-semibold fs-2">{TotalCount}</span></h5>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }}
                            />
                        </ThemeProvider>
                    </div>

                    <div id="ctl00_ContentPlaceHolder1_divPagination">
                        <div className="col-lg-12 col-md-12 col-12 cssPagination" style={{ boxShadow: '2px 2px 5px #808080' }}>
                            <nav aria-label="Page navigation">
                                <ul className="GridPager pagination" style={{ paddingTop: '2px' }}>
                                    <div style={{ marginTop: '0.5%', marginBottom: '0.5%', marginLeft: '3px' }}>
                                        <button type="button" onClick={setPreviousApi} className="btn btn-primary mt-12 mr-3 mobmt-2 page-disable" style={{ marginRight: '1.5%' }} >Previous </button>
                                    </div>
                                    <div style={{ marginTop: '0.5%', marginBottom: '0.5%', marginLeft: '3px' }} >
                                        <button type="button" onClick={setNextApi} className="btn btn-danger mt-12 mobmt-1 page-disable" style={{ marginRight: '1.5%' }}>Next </button>
                                    </div>
                                </ul>
                            </nav>
                        </div>
                    </div>


                </div >
            </div >
        </>
    )
}

export default Greetingwishes

