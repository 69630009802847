import React, { useState, useRef, useEffect } from 'react'
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import { FormWithConstraints, FieldFeedbacks, FieldFeedback, } from 'react-form-with-constraints';
import { allowOnlyNumbers, allowOnlyCharacters } from '../inputvalidation';
import '../style.css';
import services from "../Services/Services";
import moment from 'moment';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const CalenderEvent = () => {

  const [cookies, setCookie] = useCookies(['user']);
  const [ckUserID, setckUserID] = useState(cookies.ckUserID);

  const [CalendarEventID, setCalendarEventID] = useState('')

  const [Title, setTitle] = useState('')
  const [TitleError, setTitleError] = useState('')

  const [Description, setDescription] = useState('')
  const [DescriptionError, setDescriptionError] = useState('')

  const [CalendarDate, setCalendarDate] = useState('')
  const [CalendarDateError, setCalendarDateError] = useState('');

  const [CalendarImage, setCalendarImage] = useState([])
  const [ImgerrorMsg, setImgErrorMsg] = useState('')
  const [CalendarImageerrorMsg, setCalendarImageerrorMsg] = useState(true)

  const form = useRef(FormWithConstraints);
  const { state } = useLocation();
  const navigate = useNavigate();

  const [issubmitvisible, setIssubmitvisible] = useState(true)
  const [isUpdatevisible, setIsUpdatevisible] = useState(false)

  const ref2 = useRef();
  
  useEffect(() => {
    if (state != null) {
      EditCalendarEvent(state);
  }   
}, []);
 
  const handleCalendarDatechange = (date) => {
    setCalendarDate(date);
    setCalendarDateError('');
  };

  const handleDescriptionchange = (e) =>{
    setDescription(e.target.value);
    setDescriptionError('')
  }

  const HandleCalendarImage = (e) => {
    if (['png', 'jpg', 'jpeg', 'PNG'].includes(e.target.value.split('.')[e.target.value.split('.').length - 1])) {
      setCalendarImageerrorMsg(true)
      const files = e.target.files;
      setCalendarImage([...CalendarImage, ...files]);
      setImgErrorMsg('')
    }
    else {
      setCalendarImageerrorMsg(false)
    }
  }
  const ViewHandler = () => {
    window.location.href = "/ViewCalendarEvent"
  }

  const submitHandler = (e) => {

    e.preventDefault();
    var allvalid = true;

    if (Title.length === 0) {
      setTitleError(true)
      allvalid = false;
    }

    if (Description.length === 0) {
      setDescriptionError(true)
      allvalid = false;
    }

    if (!CalendarDate) {
      setCalendarDateError('Please Select Calendar Date');
      allvalid = false;
    } else {
      setCalendarDateError('');
    }

    var Calendardt;
    if (CalendarDate !== "") {
      Calendardt = moment(CalendarDate).format('DD-MM-YYYY');
    }

    if (!CalendarImageerrorMsg) {
      return
    }

    if (allvalid) {
      Swal.fire({
        title: 'Are you sure?',
        // text: 'You are about to submit the form.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, submit it!',
      }).then((result) => {
        if (result.isConfirmed) {

          var form = new FormData();
          if (CalendarEventID !== "") {
            form.append('CalendarEventID', CalendarEventID);
            form.append('UserID', ckUserID);
            form.append('Title', Title);
            form.append('Description', Description);
            form.append('CalendarDate', Calendardt);

            for (let i = 0; i < CalendarImage.length; i++) {
              form.append('CalendarImage', CalendarImage[i]);
            }
          }
          else {

            form.append('CalendarEventID', "");
            form.append('UserID', ckUserID);
            form.append('Title', Title);
            form.append('Description', Description);
            form.append('CalendarDate', Calendardt);

            for (let i = 0; i < CalendarImage.length; i++) {
              form.append('CalendarImage', CalendarImage[i]);
            }

          }

          services.SetCalendarEvent(form).then(response => {
            debugger;
            if (response.data.status) {

              if (CalendarEventID !== "") {
                Swal.fire({
                  title: 'Successfully Updated', icon: "success",  width:"400px", showConfirmButton: false,timer: 1500
                });
                window.location.href = "/ViewCalendarEvent"
                setIssubmitvisible(true);
                setIsUpdatevisible(false);
              }
              else {
                Swal.fire({
                  title: 'Successfully Inserted', icon: "success",  width:"400px", showConfirmButton: false, timer: 1500
                });
                window.location.href = "/ViewCalendarEvent"
              }
              ClearData();
            }

          }, error => { console.log(error, 'error') });
        }
      })
    }
  }

  const EditCalendarEvent = (id) => {
    debugger;
    setIsUpdatevisible(true)
    setIssubmitvisible(false)

    var data = JSON.stringify({

      "CalendarEventID": id,
      "UserID":"",
      "Title": "",
      "StartDate": "",
      "EndDate": "",
      "PaginationID": "-1",
      "PageLength": ""
    });

    var config = {
      method: 'POST',
      url: services.API_URL + "CalendarEvent/getCalendarEvent",
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    services.JsonValue(config).then(response => {
      
      if (response.data.status) {

        var date = "";
        var dateString = response.data.data[0].CalendarDate;
        if (dateString != null) {
          var dateMomentObject = moment(dateString, "DD-MM-YYYY");
          date = dateMomentObject.toDate();
        }
        else {
          date = "";
        }

        setCalendarEventID(response.data.data[0].CalendarEventID)

        setTitle(response.data.data[0]?.Title || "")
        setDescription(response.data.data[0]?.Description || "")
        setCalendarDate(date)
      }
    }, error => { })
  }

  const ClearData = () => {
    setCalendarEventID('')
    setTitle('')
    setTitleError('')
    setDescription('')
    setDescriptionError('')

    setCalendarDate('')

    setImgErrorMsg('')

    
    ref2.current.value = ''


  }

  const CloseClick = () => {
    setIssubmitvisible(true)
    setIsUpdatevisible(false)
    setTitle('')
    setTitleError('')
    // setCalendarDate('')
    setCalendarDateError('')
    setCalendarImageerrorMsg(true)

    setDescription('')
    setDescriptionError('')

    setImgErrorMsg('') 

    ref2.current.value = ''
  }

  return (
    <div className="body-wrapper">
      <div className="container-fluid">
        <div className='row'>
          <div className='col-md-12'>

            <div className="card">
              <div className="px-3 py-2 border-bottom">
                <h5>Add Calendar Event</h5>
              </div>

              <FormWithConstraints
                ref={form}
                // onSubmit={submitHandler}
                noValidate>

                <div className="card-body">
                  <div className='row'>

                    <div className='col-md-3'>
                      <div className="form-group">
                        <label htmlFor="Title" className="form-label fw-semibold"> Title <span className="text-danger">*</span> </label>
                        <input type="text" onChange={e => setTitle(e.target.value)} required value={Title} type1="textnm" maxLength={30} className="form-control" placeholder="Enter Title "></input>
                        {TitleError && <span style={{ color: 'red' }}>{Title.length <= 0 ? 'Please Enter Title' : ''}</span>}
                      </div><br></br>
                    </div>

                    <div className='col-md-3'>
                      <div className="form-group">
                        <label htmlFor="Description" className="form-label fw-semibold">Description <span className="text-danger">*</span></label>
                        <textarea name="Description" onChange={handleDescriptionchange} required value={Description} cols={40} className="form-control" placeholder="Enter Description" />
                        {DescriptionError && <span style={{ color: 'red' }}>{Description.length <= 0 ? 'Please Enter Description' : ''}</span>}
                      </div><br></br>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="Name" className="form-label fw-semibold">Calendar Event Date <span className="text-danger">*</span></label>
                        <DatePicker
                          selected={CalendarDate}
                          onChange={handleCalendarDatechange}
                          dateFormat="dd-MM-yyyy"
                          value={CalendarDate}
                          placeholderText="DD-MM-YYYY"
                          className="form-control readonly"
                          autoComplete="off"
                          showMonthDropdown
                          showYearDropdown
                        />
                      </div>
                      {CalendarDateError && <div style={{ color: 'red' }}>{CalendarDateError}</div>}<br></br>
                    </div>

                 
                    <div className="col-md-3">
                      <label htmlFor="fileInput" className="form-label fw-semibold">Calendar Image
                        <span style={{ color: '#B71C1C' }}> (Single/ Multiple)</span>
                      </label>
                      <input type="file" ref={ref2} className="form-control" multiple onChange={HandleCalendarImage} accept="image/jpeg, image/png, image/jpg, image/PNG" style={{ height: "37px" }} />
                      {!CalendarImageerrorMsg && <span className="text-danger">Please Enter Only Image File</span>}
                      {ImgerrorMsg && <p style={{ color: 'red' }}>{ImgerrorMsg}</p>}<br></br>
                    </div>

                    <div className="col-md-3" style={{ marginTop: '2%' }}>
                      {issubmitvisible &&
                        <button type="button" onClick={submitHandler} className="btn btn-outline-primary font-weight-bolder ml-2 " style={{ marginRight: '2%' }} >Submit</button>
                      }
                      {isUpdatevisible &&
                        <button type="button" onClick={submitHandler} className="btn btn-outline-primary font-weight-bolder ml-2 " style={{ marginRight: '2%' }} >Update</button>
                      }
                      <button type="button" onClick={CloseClick} className="btn btn-outline-danger font-weight-bolder ml-2" style={{ marginRight: '2%' }} >Cancel</button>
                      <button type="button" onClick={ViewHandler} className="btn btn-outline-primary font-weight-bolder ml-2">View</button>
                    </div>

                  </div>
                </div>

              </FormWithConstraints>
            </div>
          </div>
        </div >
      </div >
    </div >
  )
}

export default CalenderEvent

