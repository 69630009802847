import React from 'react'
import { useState, useRef, useEffect } from "react";
import Select from 'react-select'
import DatePicker from "react-datepicker";
import { FormWithConstraints, FieldFeedbacks, FieldFeedback, } from 'react-form-with-constraints';
import { allowOnlyNumbers } from '../inputvalidation';
import services from "../Services/Services";
import Swal from "sweetalert2";
import moment from 'moment';
import MultiSelect from "@khanacademy/react-multi-select";
import { useCookies } from 'react-cookie';
import { useNavigate, useLocation } from 'react-router-dom';


const Addusermaster = () => {

  const [cookies, setCookie] = useCookies(['user']);
  const [ckUserID, setckUserID] = useState(cookies.ckUserID);

  const [UserID, setUserID] = useState('')
  const [UserName, setUserName] = useState('')
  const [userNameerror, setUserNameerror] = useState('')

  const [Password, setPassword] = useState('')
  const [Passworderror, setPassworderror] = useState('')
  const [isPasswordvisible, setisPasswordvisible] = useState(true)

  const [MobileNo, setMobileNo] = useState('')
  const [Mobileerror, setMobileerror] = useState('')

  const [Birthdate, setBirthdate] = useState('')
  const [BdateError, setBdateError] = useState('');
  const [Birthdateerror, setBirthdateerror] = useState('')

  const [Address, setAddress] = useState('')
  const [Addresserror, setAddresserror] = useState('')

  const [UserDesignationID, setUserDesignationID] = useState({ 'label': '--Select User Designation--', 'value': '--Select User Designation--' });
  const [UserDesignationes, getUserDesignation] = useState([])
  const [UserDesignationError, setUserDesignationError] = useState('');

  const [DesignationID, setDesignationID] = useState([])
  const [Designationes, getDesignation] = useState([])
  const [DesignationError, setDesignationError] = useState('');

  const [issubmitvisible, setIssubmitvisible] = useState(true)
  const [isUpdatevisible, setIsUpdatevisible] = useState(false)

  const form = useRef(FormWithConstraints);
  const { state } = useLocation();
  const navigate = useNavigate();
  const ref = useRef();
 

  useEffect(() => {

    GetUserDesignation();
    GetDesignation()

    if (state != null) {
      EditUser(state);
    }
  }, []);

  const handleBirthdatechange = (date) => {
    setBirthdate(date);
    setBdateError('')
  };

  const GetUserDesignation = () => {
    var data = JSON.stringify({
      "UserDesignationID": "",
      "UserID": "",
      "DesignationName": "",
      "StartDate": "",
      "EndDate": "",
      "PaginationID": "-1",
      "PageLength": ""
    });
    var config = {
      method: 'POST',
      url: services.API_URL + "UserDesignation/GetUserDesignation",
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    services.JsonValue(config).then(response => {
      if (response.data.status) {
        getUserDesignation(response.data.data?.map(item => ({ value: item.UserDesignationID, label: item.DesignationName })))
      }
      else {
        getUserDesignation([]);
      }
    }, error => { })
  }

  const handleDesignationChange = (e) => {
    setUserDesignationID(e);
    setUserDesignationError('')
  }

  const GetDesignation = () => {
    debugger;

    var data = JSON.stringify({
      "DesignationID": "",
      "UserID": "",
      "CategoryID": "",
      "DesignationName": "",
      "StartDate": "",
      "EndDate": "",
      "PaginationID": "-1",
      "PageLength": ""
    });
    var config = {
      method: 'POST',
      url: services.API_URL + "Designation/GetDesignation",
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    services.JsonValue(config).then(response => {

      if (response.data.status) {

        getDesignation(response.data.data?.map(item => ({ value: item.DesignationID, label: item.DesignationName })));
        // setDesignationID([])
      }

    }, error => { })
  }
  const onDesignationChange = (e) => {
    console.log(e, "jjjjjjjjj")
    setDesignationID(e);
    setDesignationError('')
  }

  const handleUsernameChange = (e) => {
    setUserName(e.target.value);
    setUserNameerror('')
  }
  const handlePasswardChange = (e) => {
    setPassword(e.target.value);
    setPassworderror('')
  }
  const handleMobileNoChange = (e) => {
    setMobileerror('')
    setMobileNo(e.target.value);

    const inputReferMobileNumber = e.target.value;
    const MoNo = inputReferMobileNumber.replace(/\D/g, '');
    setMobileNo(MoNo);
    setMobileerror('');
  }
  const handleAddressChange = (e) => {
    setAddress(e.target.value);
    setAddresserror('')
  }

  const submitHandler = (e) => {
    debugger;
    var allvalid = true;

    if (UserName?.trim() !== '') {
      setUserNameerror('');
    } else {
      setUserNameerror('Please Enter User Name');
      allvalid = false;
    }

    if (Password?.trim() !== '') {
      setPassworderror('');
    } else {
      setPassworderror('Please Enter Password');
    }

    if (!Birthdate) {
      setBdateError('Please Select Birth Date');
      allvalid = false;
    } else {
      setBdateError('');
    }

    if (Address?.trim() !== '') {
      setAddresserror('');
    } else {
      setAddresserror('Please Enter Address');
      allvalid = false;
    }

    var InvBirthdt;
    if (Birthdate !== "") {
      InvBirthdt = moment(Birthdate).format('DD-MM-YYYY');
    }

    const currentDate = new Date();
    if (Birthdate > currentDate) {
      setBirthdateerror('Please Select Date In The Past.');
      return;
    }

    if (MobileNo.trim() !== '') {
      if (/^\d{10}$/.test(MobileNo)) {
        setMobileerror('');
      } else {
        setMobileerror('Please Enter a Valid 10 Digit Mobile Number');
        allvalid = false;
      }
    } else {
      setMobileerror('Please Enter Mobile No');
      allvalid = false;
    }

    if (UserDesignationID.value === "--Select User Designation--") {
      setUserDesignationError('Please Select User Designation');
      allvalid = false;
    }

    if (DesignationID.length === 0) {
      setDesignationError('Please Select Designation');
      allvalid = false;
    }

    if (allvalid) {

      Swal.fire({
        title: 'Are you sure?',
        // text: 'Do you want to submit?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, submit!',
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("dessssss-------->", designationDetail);

          var data = "";
          if (UserID !== "") {

            var updatedDesignationDetail = DesignationID?.map(item => ({
              UserDesignationDetailID: item.UserDesignationDetailID || '',
              DesignationID: item
            }));

            data = JSON.stringify({
              "UserID": UserID,
              "UserName": UserName,
              "Password": Password,
              "MobileNo": MobileNo,
              "Address": Address,
              "BirthDate": InvBirthdt,
              "UserDesignationID": UserDesignationID.value,
              "UserDesignationDetail": updatedDesignationDetail
            });
          }
          else {

            var designationDetail = DesignationID?.map(item => ({
              UserDesignationDetailID: item.UserDesignationDetailID || '',
              DesignationID: item
            }));

            data = JSON.stringify({
              "UserID": "",
              "UserName": UserName,
              "Password": Password,
              "MobileNo": MobileNo,
              "Address": Address,
              "BirthDate": InvBirthdt,
              "UserDesignationID": UserDesignationID.value,
              "UserDesignationDetail": designationDetail
            });
          }

          var config = {
            method: 'POST',
            url: services.API_URL + "User/SetUser",
            headers: {
              'Content-Type': 'application/json'
            },
            data: data
          };
          services.JsonValue(config).then(response => {
            //// debugger;
            if (response.data.status) {
              // debugger;
              if (UserID !== "") {
                Swal.fire({
                  title: 'Successfully Updated', icon: "success", width: "400px", showConfirmButton: false, timer: 1500
                }).then(() => {
                  window.location.href = "/viewuser";
                });                
                setIssubmitvisible(true);
                setisPasswordvisible(true);
                setPassworderror('')
                setIsUpdatevisible(false);
              }
              else {
                Swal.fire({
                  title: 'Successfully Inserted', icon: "success", width: "400px", showConfirmButton: false, timer: 1500
                }).then(() => {
                  window.location.href = "/viewuser";
                });
              }
              ClearData();
            }
            // else {
            //   Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
            // }
          }, error => { })
        }
      })
    }

  }

  const EditUser = (id) => {
    debugger;
    setIsUpdatevisible(true)
    setIssubmitvisible(false)
    setisPasswordvisible(false)

    var data = JSON.stringify({
      "UserID": id,
      "UserName": "",
      "MobileNo": "",
      "BirthDate": "",
      "StartDate": "",
      "EndDate": "",
      "PaginationID": "-1",
      "PageLength": "10"
    });
    var config = {
      method: 'POST',
      url: services.API_URL + "User/GetUser",
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    services.JsonValue(config).then(response => {

      var date = "";
      var dateString = response.data.data[0].BirthDate;
      if (dateString != null) {
        var dateMomentObject = moment(dateString, "DD-MM-YYYY");
        date = dateMomentObject.toDate();
      }
      else {
        date = "";
      }

      setUserID(response.data.data[0]?.UserID)
      setUserName(response.data.data[0]?.UserName || '')
      setMobileNo(response.data.data[0]?.MobileNo || '')
      setAddress(response.data.data[0]?.Address || '')

      if (response.data.data[0]?.Password) {
        setisPasswordvisible(true);
      }

      setBirthdate(date)
      setUserDesignationID({ 'label': response.data.data[0].DesignationName, 'value': response.data.data[0].UserDesignationID })

      const updatedRow = []
      response.data.data[0].UserDesignationDetail?.map((element, index) => {
        updatedRow.push(element.DesignationID.toString())
      })
      console.log(updatedRow, "updatedRow")
      setDesignationID(updatedRow)


    }, error => { })
  }

  const CloseClick = () => {
    // setUserID('')
    setIssubmitvisible(true)
    setisPasswordvisible(true);
    setPassworderror('')
    setIsUpdatevisible(false)
    setUserName('')
    setUserNameerror('')
    setMobileNo('')
    setMobileerror('')
    setPassword('')
    setPassworderror('')
    setAddress('')
    setAddresserror('')
    setBirthdate('')
    setBdateError('')
    setBirthdateerror('')
    setUserDesignationID({ 'label': '--Select User Designation--', 'value': '--Select User Designation--' });
    setUserDesignationError('')
    setDesignationError('')
    setDesignationID([])
  }
  const ClearData = () => {
    setUserID('')
    setUserName('')
    setMobileNo('')
    setPassword('')
    setAddress('')
    setBirthdate('')
    setBirthdateerror('')
    setUserDesignationID({ 'label': '--Select User Designation--', 'value': '--Select User Designation--' });
    setUserDesignationError('')
    setDesignationError('')
    setDesignationID([])

  }
  const ViewHandler = () => {
    navigate('/viewuser')   
  }


  return (
    <>
      <div className="body-wrapper">
        <div className="container-fluid">

          <div className='col-md-12'>
            <div className="card">
              <div className="px-3 py-2 border-bottom">
                <h5>User Master</h5>
              </div>
              <FormWithConstraints
                ref={form}
                // onSubmit={submitHandler}
                noValidate>

                <div className="card-body">
                  <div className='row'>
                    <div className='col-md-3'>
                      <div className="form-group">
                        <label htmlFor="UserName" className="form-label fw-semibold"> User Name <span className="text-danger">*</span> </label>
                        <input type="text" onChange={handleUsernameChange} required value={UserName} type1="textnm" className="form-control" placeholder="Enter Name"></input>
                        <span style={{ color: 'red' }}>{userNameerror}</span>
                      </div><br></br>
                    </div>

                    {isPasswordvisible && (
                      <div className='col-md-3'>
                        <div className="form-group">
                          <label htmlFor="Password" className="form-label fw-semibold">Password <span className="text-danger">*</span></label>
                          <input type="Password" onChange={handlePasswardChange} required value={Password} maxLength={20} type1="textnm" className="form-control" placeholder="Enter Password"></input>
                          <p style={{ color: 'red' }}>{Passworderror}</p>
                        </div>
                      </div>
                    )}

                    <div className='col-md-3'>
                      <div className="form-group">
                        <label htmlFor="MobileNo" className="form-label fw-semibold">Mobile No <span className="text-danger">*</span></label>
                        <input type="MobileNo" onChange={handleMobileNoChange} required value={MobileNo} maxLength={10} onKeyPress={allowOnlyNumbers} type1="textnm" className="form-control" placeholder="Enter Mobile No"></input>
                        <p style={{ color: 'red' }}>{Mobileerror}</p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="Name" className="form-label fw-semibold">Birth Date <span className="text-danger">*</span></label>
                        <DatePicker
                          selected={Birthdate}
                          onChange={handleBirthdatechange}
                          autoComplete="off"
                          dateFormat="dd-MM-yyyy"
                          value={Birthdate}
                          placeholderText="dd-MM-yyyy"
                          className="form-control readonly"
                          showMonthDropdown
                          showYearDropdown
                        />
                        {BdateError && <div style={{ color: 'red' }}>{BdateError}</div>}
                      </div>
                      <p style={{ color: 'red' }}>{Birthdateerror}</p>
                    </div>

                    <div className='col-md-3'>
                      <div className="form-group">
                        <label htmlFor="Address" className="form-label fw-semibold">Address <span className="text-danger">*</span></label>
                        <textarea name="Address" ref={ref} onChange={handleAddressChange} required value={Address} rows={2} cols={20} className="form-control" placeholder="Enter Address" />
                        <span style={{ color: 'red' }}>{Addresserror}</span>
                      </div><br></br>
                    </div>


                    <div className="col-md-3">
                      <div className="form-group ">
                        <label htmlFor="UserDesignation" className="form-label fw-semibold">User Designation <span className="text-danger"> *</span></label>
                        <Select options={[{ label: '--Select User Designation--', value: '--Select User Designation--' }, ...UserDesignationes]} value={UserDesignationID} onChange={handleDesignationChange} placeholder="--Select User Designation--" />
                        {UserDesignationError && <p style={{ color: 'red' }}>{UserDesignationError}</p>}
                      </div><br></br>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group ">
                        <label htmlFor="Designation" className="form-label fw-semibold">Designation <span className="text-danger"> *</span></label>
                        <MultiSelect style={{ borderColor: '#2684FF', borderWidth: 'none', borderStyle: 'none' }}
                          options={Designationes} selected={DesignationID} checked={false}
                          onSelectedChanged={onDesignationChange}
                          overrideStrings={{
                            selectSomeItems: "--Select Designation--", allItemsAreSelected: "All Designationes are Selected",
                            selectAll: "Select All", search: "Search", selectSomeItemsColor: "hsl(0, 0%, 20%)"
                          }}
                          placeholder="--Select Designation--"
                        />
                        {DesignationError && <p style={{ color: 'red' }}>{DesignationError}</p>}
                      </div><br></br>
                    </div>

                  </div>

                  <div className="d-flex align-items-center gap-2">
                    {issubmitvisible &&
                      < button type="button" onClick={submitHandler} className="btn btn-outline-primary font-weight-bolder ml-2 ">Submit</button>
                    }
                    {isUpdatevisible &&
                      <button type="button" onClick={submitHandler} className="btn btn-outline-primary font-weight-bolder ml-2 ">Update</button>
                    }
                    <button type="button" onClick={CloseClick} className="btn btn-outline-danger font-weight-bolder ml-2">Cancel</button>

                    <button type="button" onClick={ViewHandler} className="btn btn-outline-primary font-weight-bolder ml-2">View</button>
                  </div>
                </div>

              </FormWithConstraints>
            </div>
          </div>


        </div >
      </div >
    </>
  )
}

export default Addusermaster
