import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material';
import MaterialTable from 'material-table';
import { useState, useRef, useEffect } from "react";
import { FormWithConstraints, FieldFeedbacks, FieldFeedback, } from 'react-form-with-constraints';
import services from "../Services/Services";
import Swal from "sweetalert2";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from 'react-select'
import DOMPurify from "dompurify"
import { useCookies } from 'react-cookie';
import XLSX from "xlsx-color";
import moment from 'moment';

const defaultMaterialTheme = createTheme();

const Greetingdraft = () => {

    const [cookies, setCookie] = useCookies(['user']);
    const [ckUserID, setckUserID] = useState(cookies.ckUserID);

    const headerTblStyle = { color: 'Black' };
    const cols = [
        { title: (<div style={{ color: 'white' }}><span>Sr.No</span></div>), field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{(pageId * pageCount - 10) + rowData.tableData.id + 1}</p>) } },
        // { title: (<div style={{ width: '100%', color: 'white' }}><span>User Name</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '100px' }}>{rowData.UserName}</p>) } },
        { title: (<div style={{ color: 'white', width: '150%' }}><span>Festival Name</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '80px' }}>{rowData.FestivalName}</p>) } },
        {
            title: (<div style={{ color: 'white', width: '800px' }}> <span>Draft</span></div>), field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => {
                return (
                    <div dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                            rowData.Draft
                        ),
                    }} style={{ marginBottom: '1px' }}>
                    </div>
                )
            }
        },
    ]  

    const handleExcelExport = async (pageCount) => {

        var data = JSON.stringify({

            "GreetingDraftID": "",
            "UserID": "",
            "FestivalID": "",
            "PaginationID": "0",
            "PageLength": `${pageCount}`
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "GreetingDraft/GetGreetingDraft",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                exportToExcel(response.data.data)
            }

        }, error => { })
    };

    const exportToExcel = (array) => {
        console.log(array, 'data');

        const header = ["Sr.No", "User Name", "Festival Name", "Draft"];
        const body = [];
        array?.forEach((col, index) => {
            const cleanedDraft = col.Draft.replace(/<\/?p>/g, '').replace(/\n/g, '\u000A');
            body.push([index + 1, col.UserName, col.FestivalName, cleanedDraft]);
        });

        if (!body.length) {
            return Swal.fire({
                title: "No Data Found!",
                icon: "error",
                timer: 1500
            });
        }

        const ws1 = XLSX.utils.aoa_to_sheet([header, ...body]);

        header.forEach((col, index) => {
            ws1[XLSX.utils.encode_cell({ r: 0, c: index })].s = {
                font: { bold: true, color: { rgb: "f7f4ef" } }, // White font color
                fill: { patternType: 'solid', fgColor: { rgb: "#B71C1C" } }, // Blue background color
                alignment: { wrapText: index === 3, horizontal: 'center' }, // Enable wrap text for the "Draft" column
            };
        });

        ws1['!cols'] = [
            { width: 6 },
            { width: 20 },
            { width: 20 },
            { width: 50 },
        ];

        // Set row height for the "Draft" column
        const fixedHeight = 500;
        for (let row = 1; row <= body.length; row++) {
            ws1[`D${row}`].s = { alignment: { wrapText: true } };
            ws1[`D${row}`].hpx = fixedHeight;
        }

        let cell = {
            font: { bold: true, color: { rgb: "f7f4ef" } },
            fill: { patternType: 'solid', fgColor: { rgb: "#B71C1C" } },
            alignment: { wrapText: true, horizontal: 'center' }, // Enable wrap text for the "Draft" column
            border: {
                left: { style: 'thin', color: { rgb: '000000' } },
                right: { style: 'thin', color: { rgb: '000000' } },
                top: { style: 'thin', color: { rgb: '000000' } },
                bottom: { style: 'thin', color: { rgb: '000000' } }
            }
        };
        ws1['A1'].s = cell;
        ws1['B1'].s = cell;
        ws1['C1'].s = cell;
        ws1['D1'].s = cell;

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws1, 'GreetingDraft');

        XLSX.writeFile(wb, `GreetingDraft_${moment().format('DD-MM-YYYY_HH:mm')}.xlsx`);
    };

    const [GreetingDraftID, setGreetingDraftID] = useState('')

    const [FestivalID, setFestivalID] = useState({ 'label': '--Select Festival--', 'value': '--Select Festival--' });
    const [Festivales, getFestival] = useState([])
    const [FestivalErrMsg, setFestivalErrMsg] = useState('');

    // const [UserID, setUserID] = useState({ 'label': '--Select User Name--', 'value': '--Select User Name--' });
    // const [Useres, getUser] = useState([])
    // const [UserErrMsg, setUserErrMsg] = useState('');

    const [Draft, setDraft] = useState('');
    const [validationError, setValidationError] = useState('');

    const [pageCount, setPageCount] = useState(10);
    const [pageId, setPageId] = useState(1);
    const [tableData, setTableData] = useState([]);
    const [allTableData, setAllTableData] = useState([]);
    const [TotalCount, setTotalCount] = useState('')

    const [issubmitvisible, setIssubmitvisible] = useState(true)
    const [isUpdatevisible, setIsUpdatevisible] = useState(false)
    const ref = useRef();
    const [data, getData] = useState([])
    const form = useRef(FormWithConstraints);


    useEffect(() => {
        GetGreetingDraft(pageCount);
        GetFestivalMaster();
        // GetUserMaster();
    }, []);

    // const GetUserMaster = () => {
    //     var data = JSON.stringify({
    //         "UserID": "",
    //         "UserName": "",
    //         "MobileNo": "",
    //         "BirthDate": "",
    //         "StartDate": "",
    //         "EndDate": "",
    //         "PaginationID": "-1",
    //         "PageLength": ""
    //     });
    //     var config = {
    //         method: 'POST',
    //         url: services.API_URL + "User/GetUser",
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         data: data
    //     };
    //     services.JsonValue(config).then(response => {

    //         if (response.data.status) {
    //             getUser(response.data.data.map(item => ({ value: item.UserID, label: item.UserName })))
    //         }
    //         else {
    //             getUser([]);
    //         }
    //     }, error => { })
    // }
    const GetFestivalMaster = () => {
        var data = JSON.stringify({
            "FestivalID": "",
            "FestivalName": "",
            "PaginationID": "-1",
            "PageLength": ""
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "FestivalMaster/GetFestivalMaster",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                getFestival(response.data.data.map(item => ({ value: item.FestivalID, label: item.FestivalName })))
            }
            else {
                getFestival([]);
            }
        }, error => { })
    }
    const GetGreetingDraft = (pageCount) => {
        var data = JSON.stringify({
            "GreetingDraftID": "",
            "UserID": "",
            "FestivalID": "",
            "PaginationID": "0",
            "PageLength": `${pageCount}`
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "GreetingDraft/GetGreetingDraft",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                setTableData(response.data.data)
                setAllTableData(response.data.data)
                setPageId(1)
                setIssubmitvisible(true)
                setIsUpdatevisible(false)
                setTotalCount(response.data.total[0].TotalCount);
            }
            else {
                getData([]);
            }
        }, error => { })
    }

    const setPreviousApi = () => {

        if (pageId == 1) {
            return
        }
        const tempt = [...allTableData]
        console.log((pageId - 2) * pageCount, (pageId - 1) * pageCount, 'alltabledata')
        const sliced = tempt.slice((pageId - 2) * pageCount, (pageId - 1) * pageCount)

        setTableData(sliced)
        setPageId(pageId - 1)

    };
    const setNextApi = () => {
        console.log(pageId, "pageID", pageCount)

        if (!tableData.length) {
            return;
        }
        if (pageId * pageCount < allTableData.length) {
            const tempt = [...allTableData];
            const sliced = tempt.slice((pageId) * pageCount, (pageId + 1) * pageCount);
            setTableData(sliced);
            setPageId(pageId + 1);
            return;
        }

        const paginationIndex = allTableData[pageId * pageCount - 1]?.GreetingDraftID;
        if (!paginationIndex) {
            return;
        }

        var data = JSON.stringify({
            "GreetingDraftID": "",
            "UserID": "",
            "FestivalID": "",
            "PaginationID": paginationIndex,
            "PageLength": `${pageCount}`
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "GreetingDraft/GetGreetingDraft",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        services.JsonValue(config).then(response => {
            if (response.data.status) {
                setTableData(response.data.data)
                setPageId(pageId + 1)
                setAllTableData([...allTableData, ...response.data.data])
                setTotalCount(response.data.total[0].TotalCount);
            }

        }, error => { })
    };

    const handleEditorChange = (event, editor) => {
        setValidationError('')
        const data = editor.getData();
        setDraft(data);
    };

    const onFestivalChange = (e) => {
        setFestivalID(e);
        setFestivalErrMsg('')
    }

    // const onUserChange = (e) => {
    //     setUserID(e);
    //     setUserErrMsg('')
    // }

    const submitHandler = (e) => {
        var allvalid = true;

        // if (UserID.value === "--Select User Name--") {
        //     setUserErrMsg('Please Select User Name');
        //     allvalid = false;
        // }

        if (FestivalID.value === "--Select Festival--") {
            setFestivalErrMsg('Please Select Festival');
            allvalid = false;
        }

        if (!Draft.trim()) {
            setValidationError('Please Enter Draft');
        } else {
            setValidationError('');
        }

        if (allvalid) {

            Swal.fire({
                title: 'Are you sure?',
                // text: 'Do you want to submit?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, submit!',
            }).then((result) => {
                if (result.isConfirmed) {

                    var data = "";
                    if (GreetingDraftID != "") {
                        data = JSON.stringify({

                            "GreetingDraftID": GreetingDraftID,
                            "UserID": ckUserID,
                            "FestivalID": FestivalID.value,
                            "Draft": Draft,

                        });
                    }
                    else {
                        data = JSON.stringify({
                            "GreetingDraftID": "",
                            "UserID": ckUserID,
                            "FestivalID": FestivalID.value,
                            "Draft": Draft,
                        });
                    }

                    var config = {
                        method: 'POST',
                        url: services.API_URL + "GreetingDraft/SetGreetingDraft",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: data
                    };
                    services.JsonValue(config).then(response => {
                        ////
                        if (response.data.status) {
                            //
                            if (GreetingDraftID != "") {
                                // Swal.fire({ position: 'top-end', toast: true, icon: 'success', title: 'Successfully Updated', showConfirmButton: false, timer: 1500 });
                                Swal.fire({
                                    title: 'Successfully Updated', icon: "success",  width:"400px", showConfirmButton: false, timer: 1500
                                });
                            }
                            else {
                                Swal.fire({
                                    title: 'Successfully Inserted', icon: "success",  width:"400px", showConfirmButton: false, timer: 1500
                                });
                            }
                            ClearData();
                            GetGreetingDraft(pageCount);
                        }
                        else {
                            Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                        }
                    }, error => { })
                }
            })
        }

    }

    const EditGreetingDraft = (id) => {

        setIsUpdatevisible(true)
        setIssubmitvisible(false)
        setFestivalErrMsg('')
        // setUserErrMsg('')

        var data = JSON.stringify({

            "GreetingDraftID": id,
            "UserID": "",
            "FestivalID": "",
            "PaginationID": "-1",
            "PageLength": ""
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "GreetingDraft/GetGreetingDraft",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {

            setGreetingDraftID(response.data.data[0].GreetingDraftID)
            // setUserID({ 'label': response.data.data[0].UserName, 'value': response.data.data[0].UserID })
            setFestivalID({ 'label': response.data.data[0].FestivalName, 'value': response.data.data[0].FestivalID })
            setDraft(response.data.data[0].Draft)

        }, error => { })
    }

    const CheckAlert = (id) => {

        Swal.fire({
            title: 'Are you Sure You Want to Delete?', icon: "warning", showCancelButton: true, confirmButtonText: 'Delete', cancelButtonText: 'Cancel'
        }).then((result) => {

            if (result.isConfirmed) {
                var data = JSON.stringify({

                });
                var config = {
                    method: 'GET',
                    url: services.API_URL + "GreetingDraft/DeleteGreetingDraft/" + id,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    console.log(response.data.status, 'staus')
                    if (response.data.status) {
                        GetGreetingDraft(pageCount);
                        Swal.fire({
                            title: 'Successfully Deleted', icon: "success",  width:"400px", showConfirmButton: false, timer: 1500
                        });
                    } else {
                        Swal.fire({ position: 'top-end', icon: 'error', title: response.data.message, showConfirmButton: false, timer: 1500 });
                    }
                    ClearData();
                }, error => { })
            } else if (result.isDenied) {
                Swal.close();
            }
        });


    }

    const CloseClick = () => {
        setIssubmitvisible(true)
        setIsUpdatevisible(false)
        // setUserID({ 'label': '--Select User Name--', 'value': '--Select User Name--' });
        // setUserErrMsg('')
        setFestivalID({ 'label': '--Select Festival--', 'value': '--Select Festival--' });
        setFestivalErrMsg('')
        setDraft('')
        setValidationError('')
    }
    const ClearData = () => {
        setGreetingDraftID('')
        // setUserID({ 'label': '--Select User Name--', 'value': '--Select User Name--' });
        // setUserErrMsg('')
        setFestivalID({ 'label': '--Select Festival--', 'value': '--Select Festival--' });
        setFestivalErrMsg('')
        setDraft('')
        setValidationError('')
    }

    const ExcelClick = () => {
        handleExcelExport(pageCount);
    }

    return (
        <>
            <div className="body-wrapper">
                <div className="container-fluid">

                    <div className='col-md-12'>
                        <div className="card">
                            <div className="px-3 py-2 border-bottom">
                                <h5>Greeting Draft</h5>
                            </div>
                            <FormWithConstraints
                                ref={form}
                                // onSubmit={submitHandler}
                                noValidate>

                                <div className="card-body">

                                    <div className='row'>
                                        {/* <div className="col-md-3">
                                            <div className="form-group ">
                                                <label htmlFor="User" className="form-label fw-semibold">User Name <span className="text-danger"> *</span></label>
                                                <Select options={[{ label: '--Select User --', value: '--Select User --' }, ...Useres]} value={UserID} onChange={onUserChange} placeholder="--Select User --" />
                                                {UserErrMsg && <p style={{ color: 'red' }}>{UserErrMsg}</p>}
                                            </div><br></br>
                                        </div> */}

                                        <div className="col-md-3">
                                            <div className="form-group ">
                                                <label htmlFor="Festival" className="form-label fw-semibold">Festival <span className="text-danger"> *</span></label>
                                                <Select options={[{ label: '--Select Festival--', value: '--Select Festival--' }, ...Festivales]} value={FestivalID} onChange={onFestivalChange} placeholder="--Select Festival--" />
                                                {FestivalErrMsg && <p style={{ color: 'red' }}>{FestivalErrMsg}</p>}
                                            </div><br></br>

                                        </div>
                                        <div className="col-md-9">
                                            <label htmlFor="fileInput" className="form-label fw-semibold">Draft <span className="text-danger"> *</span></label>
                                            <div className="form-group" style={{ width: '100%' }}>
                                                <div className="img-thumbnail">
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={Draft}
                                                        onInit={(editor) => {
                                                            console.log('Editor is ready to use!', editor);
                                                        }}
                                                        onChange={handleEditorChange}
                                                    />
                                                </div>
                                                {validationError && <div style={{ color: 'red' }}>{validationError}</div>}
                                            </div>
                                        </div>


                                        <div className="col-md-3" style={{ marginTop: '4%' }}>
                                            {issubmitvisible &&
                                                <button type="button" onClick={submitHandler} className="btn btn-outline-primary font-weight-bolder ml-2" style={{ marginRight: '5px' }}>Submit</button>
                                            }
                                            {isUpdatevisible &&
                                                <button type="button" onClick={submitHandler} className="btn btn-outline-primary font-weight-bolder ml-2" style={{ marginRight: '5px' }}>Update</button>
                                            }
                                            <button type="button" onClick={CloseClick} className="btn btn-outline-danger font-weight-bolder ml-2">Cancel</button>

                                            <a className="btn btn-outline-success font-weight-bolder mt-12" onClick={ExcelClick} style={{ marginLeft: '5px' }}>
                                                <i id="btnExcel" className="fa fa-file-excel" /> Excel
                                            </a>

                                        </div>
                                    </div>
                                </div>

                            </FormWithConstraints>
                        </div>
                    </div>

                    <div className='col-md-12'>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MaterialTable columns={cols} data={tableData}
                                actions={[
                                    {
                                        icon: 'edit', tooltip: 'Edit', onClick: (e, r) => EditGreetingDraft(r.GreetingDraftID)
                                    },
                                    {
                                        icon: 'delete', tooltip: 'Delete', onClick: (e, r) => CheckAlert(r.GreetingDraftID, r.FestivalName)
                                    }
                                ]}

                                options={{
                                    headerStyle: { backgroundColor: '#B71C1C', color: 'white', fontFamily: 'system-ui' }, toolbar: true, actionsColumnIndex: -1, search: false,
                                    paging: false, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                                }}


                                components={{
                                    Toolbar: props => (
                                        <>
                                            <div className="row" >
                                                <div className="col-md-12" style={{ display: 'flex', alignItems: 'center', marginLeft: '1%', marginTop: '5px', marginBottom: '0.3%' }}>
                                                    <h5>View Greeting Draft : <span className="badge bg-light-primary text-black fw-semibold fs-2">{TotalCount}</span></h5>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }}
                            />
                        </ThemeProvider>
                    </div>

                    <div id="ctl00_ContentPlaceHolder1_divPagination">
                        <div className="col-lg-12 col-md-12 col-12 cssPagination" style={{ boxShadow: '2px 2px 5px #808080' }}>
                            <nav aria-label="Page navigation">
                                <ul className="GridPager pagination" style={{ paddingTop: '2px' }}>
                                    <div style={{ marginTop: '0.5%', marginBottom: '0.5%', marginLeft: '3px' }}>
                                        <button type="button" onClick={setPreviousApi} className="btn btn-primary mt-12 mr-3 mobmt-2 page-disable" style={{ marginRight: '1.5%' }} >Previous </button>
                                    </div>
                                    <div style={{ marginTop: '0.5%', marginBottom: '0.5%', marginLeft: '3px' }} >
                                        <button type="button" onClick={setNextApi} className="btn btn-danger mt-12 mobmt-1 page-disable" style={{ marginRight: '1.5%' }}>Next </button>
                                    </div>
                                </ul>
                            </nav>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

export default Greetingdraft
