import React from 'react'
import { useCookies } from 'react-cookie';
import { Navigate } from 'react-router-dom';

const HandleLoginRoute = ({ children }) => {
    const [cookies, setCookie, removeCookie] = useCookies();

    if (cookies.ckUserID) {
        return children
    } else {
        return <Navigate to={"/splash"} />
    }
}

export default HandleLoginRoute