import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material';
import MaterialTable from 'material-table';
import { useState, useRef, useEffect } from "react";
import { FormWithConstraints, FieldFeedbacks, FieldFeedback, } from 'react-form-with-constraints';
import services from "../Services/Services";
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import Select from 'react-select'

const defaultMaterialTheme = createTheme();

const Categorymaster = () => {

    const headerTblStyle = { color: 'Black' };

    const cols = [
        { title: (<div style={{ color: 'white' }}><span>Sr.No</span></div>), field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '1px' }}>{(pageId * pageCount - 10) + rowData.tableData.id + 1}</p>) } },
        { title: (<div style={{ color: 'white' }}><span>Category Name</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '500px' }}>{rowData.CategoryName}</p>) } },
        { title: (<div style={{ color: 'white' }}><span>Designation Name</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '500px' }}>{rowData.DesignationName}</p>) } },
    ]

    const form = useRef(FormWithConstraints);

    const [cookies, setCookie] = useCookies(['user']);
    const [ckUserID, setckUserID] = useState(cookies.ckUserID);

    const [CategoryID, setCategoryID] = useState('')
    const [CategoryName, setCategoryName] = useState('')
    const [CategoryNameerror, setCategoryNameerror] = useState('')

    const [DesignationID, setDesignationID] = useState({ 'label': '--Select Designation--', 'value': '--Select Designation--' });
    const [Designationes, getDesignation] = useState([])
    const [DesignationError, setDesignationError] = useState('');

    const [pageCount, setPageCount] = useState(10);
    const [pageId, setPageId] = useState(1);
    const [tableData, setTableData] = useState([]);
    const [allTableData, setAllTableData] = useState([]);
    const [TotalCount, setTotalCount] = useState('')

    const [issubmitvisible, setIssubmitvisible] = useState(true)
    const [isUpdatevisible, setIsUpdatevisible] = useState(false)
    const ref = useRef();

    useEffect(() => {
        GetCategory(pageCount);
        GetDesignation();
    }, []);

    const GetCategory = (pageCount) => {
        debugger;
        var data = JSON.stringify({

            "CategoryID": "",
            "CategoryName": "",
            "DesignationID": "",
            "PaginationID": "0",
            "PageLength": `${pageCount}`
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "Category/GetCategory",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                setTableData(response.data.data)
                setAllTableData(response.data.data)
                setPageId(1)
                setIssubmitvisible(true)
                setIsUpdatevisible(false)
                setTotalCount(response.data.total[0].TotalCount);
            }

        }, error => { })
    }


    const setPreviousApi = () => {
        debugger;
        if (pageId == 1) {
            return
        }
        const tempt = [...allTableData]
        console.log((pageId - 2) * pageCount, (pageId - 1) * pageCount, 'alltabledata')
        const sliced = tempt.slice((pageId - 2) * pageCount, (pageId - 1) * pageCount)

        setTableData(sliced)
        setPageId(pageId - 1)

    };
    const setNextApi = () => {
        debugger;
        if (!tableData.length) {
            return;
        }
        if (pageId * pageCount < allTableData.length) {
            const tempt = [...allTableData];
            const sliced = tempt.slice((pageId) * pageCount, (pageId + 1) * pageCount);
            setTableData(sliced);
            setPageId(pageId + 1);
            return;
        }

        const paginationIndex = allTableData[pageId * pageCount - 1]?.CategoryID;
        if (!paginationIndex) {
            return;
        }

        var data = JSON.stringify({
            "CategoryID": "",
            "CategoryName": "",
            "PaginationID": paginationIndex,
            "PageLength": `${pageCount}`
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "Category/GetCategory",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        services.JsonValue(config).then(response => {
            if (response.data.status) {
                setTableData(response.data.data)
                setPageId(pageId + 1)
                setAllTableData([...allTableData, ...response.data.data])
                setTotalCount(response.data.total[0].TotalCount);
            }

        }, error => { })
    };

    const handleCategoryNameChange = (e) => {
        setCategoryName(e.target.value);
        setCategoryNameerror('')
    }
    const GetDesignation = () => {
        debugger;
        var data = JSON.stringify({
            "DesignationID": "",
            "UserID": "",
            "DesignationName": "",
            "StartDate": "",
            "EndDate": "",
            "PaginationID": "-1",
            "PageLength": ""
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "Designation/GetDesignation",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            debugger;
            if (response.data.status) {
                getDesignation(response.data.data?.map(item => ({ value: item.DesignationID, label: item.DesignationName })))
            }
            else {
                getDesignation([]);
            }
        }, error => { })
    }
    const onDesignationChange = (e) => {
        setDesignationID(e);
        setDesignationError('')
    }

    const submitHandler = (e) => {
        e.preventDefault();
        var data = "";
        var allvalid = true;

        if (CategoryName.length === 0) {
            setCategoryNameerror(true);
            allvalid = false;
        }

        if (DesignationID.value === "--Select Designation--") {
            setDesignationError('Please Select Designation');
            allvalid = false;
        }


        if (allvalid) {
            Swal.fire({
                title: 'Are you sure?',
                // text: 'You are about to submit the form.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, submit it!',
            }).then((result) => {
                if (result.isConfirmed) {
                    // Continue with the submission logic
                    if (CategoryID !== "") {
                        // Update operation
                        data = JSON.stringify({
                            "CategoryID": CategoryID,
                            "CategoryName": CategoryName,
                            "DesignationID": DesignationID.value

                        });
                    } else {
                        // Insert operation
                        data = JSON.stringify({
                            "CategoryID": "",
                            "CategoryName": CategoryName,
                            "DesignationID": DesignationID.value
                        });
                    }

                    var config = {
                        method: 'POST',
                        url: services.API_URL + "Category/SetCategory",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: data
                    };

                    services.JsonValue(config).then(response => {
                        if (CategoryID && response.data.status === 1) {
                            // Successfully Updated
                            Swal.fire({
                                title: 'Successfully Updated',
                                icon: "success",
                                width:"400px",
                                showConfirmButton: false,
                                timer: 1500
                            });

                        } else if (!CategoryID && response.data.status === 1) {
                            // Successfully Inserted
                            Swal.fire({
                                title: 'Successfully Inserted',
                                icon: "success",
                                width:"400px",
                                showConfirmButton: false,
                                timer: 1500
                            });
                        } else if (response.data.status === 0) {
                            // Record already exists (show error message)
                            Swal.fire({
                                title: 'Error',
                                text: 'Record already exists!',
                                icon: 'error'
                            });
                        }
                        ClearData();
                        GetCategory(pageCount);
                    });
                }
            });
        }
    };

    const CloseClick = () => {
        setIssubmitvisible(true)
        setIsUpdatevisible(false)
        setCategoryID('')
        setCategoryName('')
        setCategoryNameerror('')
        setDesignationID({ 'label': '--Select Designation--', 'value': '--Select Designation--' });
        setDesignationError('')
    }

    const ClearData = () => {
        setCategoryID('')
        setCategoryName('')
        setCategoryNameerror('')
        setDesignationID({ 'label': '--Select Designation--', 'value': '--Select Designation--' });
        setDesignationError('')
    }

    const EditCategory = (id) => {
        debugger;
        setCategoryNameerror('')
        setDesignationError('')
        setIsUpdatevisible(true)
        setIssubmitvisible(false)

        var data = JSON.stringify({
            "CategoryID": id,
            "CategoryName": "",
            "PaginationID": "-1",
            "PageLength": ""
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "Category/GetCategory",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {

            setCategoryID(response.data.data[0].CategoryID)
            setCategoryName(response.data.data[0].CategoryName)
            setDesignationID({ 'label': response.data.data[0].DesignationName, 'value': response.data.data[0].DesignationID })

        }, error => { })
    }

    const CheckAlert = (id) => {
        debugger;
        Swal.fire({
            title: 'Are you Sure You Want to Delete?', icon: "warning", showCancelButton: true, confirmButtonText: 'Delete', cancelButtonText: 'Cancel'
        }).then((result) => {

            if (result.isConfirmed) {
                var data = JSON.stringify({
                    "CategoryID": id

                });
                var config = {
                    method: 'POST',
                    url: services.API_URL + "Category/DeleteCategory",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    console.log(response.data.status, 'staus')
                    if (response.data.status) {
                        GetCategory(pageCount);
                        Swal.fire({
                            title: 'Successfully Deleted', icon: "success",  width:"400px", showConfirmButton: false, timer: 1500
                        });
                    } else {
                        Swal.fire({ position: 'top-end', icon: 'error', title: response.data.message, showConfirmButton: false, timer: 1500 });
                    }
                    setCategoryName('')
                }, error => { })
            } else if (result.isDenied) {
                Swal.close();
            }
        });


    }

    return (
        <>
            <div className="body-wrapper">
                <div className="container-fluid">

                    <div className='col-md-12'>
                        <div className="card">
                            <div className="px-3 py-2 border-bottom">
                                <h5>Category Master</h5>
                            </div>
                            <FormWithConstraints
                                ref={form}
                                // onSubmit={submitHandler}
                                noValidate>

                                <div className="card-body">

                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className="form-group">
                                                <label htmlFor="CategoryName" className="form-label fw-semibold"> Category Name <span className="text-danger">*</span> </label>
                                                <input type="text" onChange={handleCategoryNameChange} required value={CategoryName} type1="textnm" className="form-control" placeholder="Enter Category Name"></input>
                                                {CategoryNameerror && <span style={{ color: 'red' }}>{CategoryName.length <= 0 ? 'Please Enter Category Name' : ''}</span>}
                                            </div><br></br>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group ">
                                                <label htmlFor="Designation" className="form-label fw-semibold">Designation <span className="text-danger"> *</span></label>
                                                <Select options={[{ label: '--Select Designation--', value: '--Select Designation--' }, ...Designationes]} value={DesignationID} onChange={onDesignationChange} placeholder="--Select Designation--" />
                                                {DesignationError && <p style={{ color: 'red' }}>{DesignationError}</p>}
                                            </div><br></br>
                                        </div>

                                        <div className="col-md-3" style={{ marginTop: '2%' }}>
                                            {issubmitvisible &&
                                                <button type="button" onClick={submitHandler} className="btn btn-outline-primary font-weight-bolder ml-2" style={{ marginRight: '5px' }}>Submit</button>
                                            }
                                            {isUpdatevisible &&
                                                <button type="button" onClick={submitHandler} className="btn btn-outline-primary font-weight-bolder ml-2" style={{ marginRight: '5px' }}>Update</button>
                                            }
                                            <button type="button" onClick={CloseClick} className="btn btn-outline-danger font-weight-bolder ml-2">Cancel</button>
                                        </div>
                                    </div>
                                </div>

                            </FormWithConstraints>
                        </div>
                    </div>

                    <div className='col-md-12'>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MaterialTable columns={cols} data={tableData}
                                actions={[
                                    {
                                        icon: 'edit', tooltip: 'Edit', onClick: (e, r) => EditCategory(r.CategoryID)
                                    },
                                    {
                                        icon: 'delete', tooltip: 'Delete', onClick: (e, r) => CheckAlert(r.CategoryID, r.CategoryName)
                                    }
                                ]}

                                options={{
                                    headerStyle: { backgroundColor: '#B71C1C', color: 'white', fontFamily: 'system-ui' }, toolbar: true, actionsColumnIndex: -1, search: false,
                                    paging: false, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                                }}


                                components={{
                                    Toolbar: props => (
                                        <>
                                            <div className="row" >
                                                <div className="col-md-12" style={{ display: 'flex', alignItems: 'center', marginLeft: '1%', marginTop: '5px', marginBottom: '0.3%' }}>
                                                    <h5>View Category: <span className="badge bg-light-primary text-black fw-semibold fs-2">{TotalCount}</span></h5>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }}
                            />
                        </ThemeProvider>
                    </div>

                    <div id="ctl00_ContentPlaceHolder1_divPagination">
                        <div className="col-lg-12 col-md-12 col-12 cssPagination" style={{ boxShadow: '2px 2px 5px #808080' }}>
                            <nav aria-label="Page navigation">
                                <ul className="GridPager pagination" style={{ paddingTop: '2px' }}>
                                    <div style={{ marginTop: '0.5%', marginBottom: '0.5%', marginLeft: '3px' }}>
                                        <button type="button" onClick={setPreviousApi} className="btn btn-primary mt-12 mr-3 mobmt-2 page-disable" style={{ marginRight: '1.5%' }} >Previous </button>
                                    </div>
                                    <div style={{ marginTop: '0.5%', marginBottom: '0.5%', marginLeft: '3px' }} >
                                        <button type="button" onClick={setNextApi} className="btn btn-danger mt-12 mobmt-1 page-disable" style={{ marginRight: '1.5%' }}>Next </button>
                                    </div>
                                </ul>
                            </nav>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

export default Categorymaster

