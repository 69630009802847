import React from 'react'
import '../privacypolicy.css';

const privacypolicy = () => {

    return (
        <>
            <div>
                <nav className="privacypolicynavbar background">
                    <ul className="nav-list">
                        <div className="logo">
                            <img src="../dist/images/profile/kp.jpg" style={{ height: '80px', width: '100%' }} />
                        </div>

                        <li style={{ fontSize: '25px' }}>KP Group</li>
                    </ul>
                </nav>
                <div className="wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="btn-group pull-right m-t-25" />
                                {/* breadcrumb start */}
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="page-title">
                                                <h2>KP Group</h2>
                                            </div>
                                            <p className="pheading">
                                                Information we collect
                                            </p>
                                            <p>
                                                We obtain the information you provide when you register the Application.
                                                Registration with us is optional for some of our applications. However, please keep
                                                in mind that you may not be able to use some of the features offered by the
                                                Application unless you register with us. This Privacy Policy (“Privacy Policy”)
                                                applies to our <span className="pheading">KP Group</span> application.
                                            </p>
                                            <p className="pheading">
                                                Security
                                            </p>
                                            <p>
                                                We are concerned about safeguarding the confidentiality of your information. We
                                                provide physical, electronic, and procedural safeguards to protect information we
                                                process and maintain. For example, we limit access to this information to authorized
                                                employees and contractors who need to know that information in order to operate,
                                                develop or improve our Application. Please be aware that, although we endeavor
                                                provide reasonable security for information we process.
                                            </p>
                                            <p>
                                                If we decide to change our privacy policy, we will post those changes on this page
                                                so that you are always aware of what information we collect, how we use it, and
                                                under what circumstances we disclose it.
                                            </p>
                                            <p>
                                                If you choose to use our Service, then you agree to the collection and use of
                                                information in relation to this policy. The Personal Information that we collect is
                                                used for providing and improving the Service. We will not use or share your
                                                information with anyone except as described in this Privacy Policy.
                                            </p>
                                            <p className="pheading">
                                                Contact us
                                            </p>
                                            <p>
                                                If you have any questions regarding privacy while using the Application, or have
                                                questions about our practices, please contact us via mail at <span className="pheading">info@smtechno.com</span>.
                                            </p>
                                            <p>
                                                All rights are reserved.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default privacypolicy
