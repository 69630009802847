import React, { useState, useRef, useEffect } from 'react'
import { FormWithConstraints, FieldFeedbacks, FieldFeedback, } from 'react-form-with-constraints';
import { ThemeProvider, createTheme } from '@mui/material';
import MaterialTable from 'material-table';
import DatePicker from "react-datepicker";
import { allowOnlyNumbers, allowOnlyCharacters } from '../inputvalidation';
import services from "../Services/Services";
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import moment from 'moment';
import XLSX from "xlsx-color";
import { MdDelete } from 'react-icons/md'

const defaultMaterialTheme = createTheme();

const ViewCalendarEvent = () => {

  const handleExcelExport = async (st) => {

    var NM = "";

    if (Title != "") {
      NM = Title
    }

    var data = JSON.stringify({

      "CalendarEventID": "",
      "UserID": "",
      "Title": st == "cancle" ? "" : NM,
      "StartDate": moment(StartDate, "DD-MM-YYYY"),
      "EndDate": moment(EndDate, "DD-MM-YYYY"),
      "PaginationID": "-1",
      "PageLength": ""
    });

    var config = {
      method: 'POST',
      url: services.API_URL + "CalendarEvent/getCalendarEvent",
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    services.JsonValue(config).then(response => {
      if (response.data.status) {
        exportToExcel(response.data.data)
      }

    }, error => { })
  };
  const exportToExcel = (array) => {
    console.log(array, 'data');
    debugger;
    const header = ["Sr.No", "Title", "Description", "CalendarDate", "Date"];
    const body = [];
    array?.forEach((col, index) => {
      body.push([index + 1, col.Title, col.Description, col.CalendarDate, col.CreatedDate]);
    });

    if (!body.length) {
      return Swal.fire({
        title: "No Data Found!",
        icon: "error",
        timer: 1500
      });
    }

    const ws1 = XLSX.utils.aoa_to_sheet([header, ...body]);

    header.forEach((col, index) => {
      ws1[XLSX.utils.encode_cell({ r: 0, c: index })].s = {
        font: { bold: true, color: { rgb: "f7f4ef" } }, // White font color
        fill: { patternType: 'solid', fgColor: { rgb: "#B71C1C" } }, // Blue background color
        alignment: { wrapText: index === 3, horizontal: 'center' }, // Enable wrap text for the "Draft" column
      };
    });

    ws1['!cols'] = [
      { width: 6 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];

    let cell = {
      font: { bold: true, color: { rgb: "f7f4ef" } },
      fill: { patternType: 'solid', fgColor: { rgb: "#B71C1C" } },
      alignment: { wrapText: true, horizontal: 'center' }, // Enable wrap text for the "Draft" column
      border: {
        left: { style: 'thin', color: { rgb: '000000' } },
        right: { style: 'thin', color: { rgb: '000000' } },
        top: { style: 'thin', color: { rgb: '000000' } },
        bottom: { style: 'thin', color: { rgb: '000000' } }
      }
    };

    ws1['A1'].s = cell;
    ws1['B1'].s = cell;
    ws1['C1'].s = cell;
    ws1['D1'].s = cell;
    ws1['E1'].s = cell;



    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, 'CalendarEvent');

    // Save the workbook
    XLSX.writeFile(wb, `CalendarEvent_${moment().format('DD-MM-YYYY_HH:mm')}.xlsx`);
  };

  const headerTblStyle = { color: 'Black' };

  const cols = [
    { title: (<div style={{ color: 'white', width: '8px' }}><span>Sr.No</span></div>), field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{(pageId * pageCount - 10) + rowData.tableData.id + 1}</p>) } },
    { title: (<div style={{ color: 'white' }}><span> Title</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '100px' }}>{rowData.Title}</p>) } },
    { title: (<div style={{ color: 'white' }}><span> Description</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '500px' }}>{rowData.Description}</p>) } },
    { title: (<div style={{ color: 'white' }}><span>Calendar Event Date</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '75px' }}>{rowData.CalendarDate}</p>) } },
    { title: (<div style={{ color: 'white' }}><span>Date</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '75px' }}>{rowData.CreatedDate}</p>) } },
  ]

  const calendarUrl = process.env.REACT_APP_Calendar_URL;

  const [Title, setTitle] = useState('')

  const [StartDate, setStartDate] = useState(new Date())
  const [EndDate, setEndDate] = useState(new Date())
  const [dateError, setDateError] = useState("");

  const [iconFilter1, seticonFilter1] = useState('fa fa-plus')
  const [isfiltervisible1, setFilter1] = useState(false)

  const [iconFilter, seticonFilter] = useState('fa fa-plus')
  const [isfiltervisible, setFilter] = useState(false)

  const [pageCount, setPageCount] = useState(10);
  const [pageId, setPageId] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [allTableData, setAllTableData] = useState([]);
  const [TotalCount, setTotalCount] = useState('')

  const form = useRef(FormWithConstraints);
  const navigate = useNavigate();

  useEffect(() => {
    GetCalendarEvent(pageCount);
  }, []);


  const GetCalendarEvent = (pageCount, st) => {

    var TE = "";

    if (Title != "") {
      TE = Title
    }

    var data = JSON.stringify({

      "CalendarEventID": "",
      "UserID": "",
      "Title": st == "cancle" ? "" : TE,
      "StartDate": moment(StartDate, "DD-MM-YYYY"),
      "EndDate": moment(EndDate, "DD-MM-YYYY"),
      "PaginationID": "0",
      "PageLength": `${pageCount}`
    });

    var config = {
      method: 'POST',
      url: services.API_URL + "CalendarEvent/getCalendarEvent",
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    services.JsonValue(config).then(response => {
      if (response.data.status) {
        setTableData(response.data.data)
        setAllTableData(response.data.data)
        setPageId(1)
        setTotalCount(response.data.total[0].TotalCount);
      }
      else {
        setTableData([]);
      }
    }, error => { })
  }
  const setPreviousApi = () => {
    debugger;
    if (pageId == 1) {
      return
    }
    const tempt = [...allTableData]
    console.log((pageId - 2) * pageCount, (pageId - 1) * pageCount, 'alltabledata')
    const sliced = tempt.slice((pageId - 2) * pageCount, (pageId - 1) * pageCount)

    setTableData(sliced)
    setPageId(pageId - 1)

  };
  const setNextApi = () => {
    debugger;

    if (!tableData.length) {
      return;
    }
    if (pageId * pageCount < allTableData.length) {
      const tempt = [...allTableData];
      const sliced = tempt.slice((pageId) * pageCount, (pageId + 1) * pageCount);
      setTableData(sliced);
      setPageId(pageId + 1);
      return;
    }

    const paginationIndex = allTableData[pageId * pageCount - 1]?.CalendarEventID;
    if (!paginationIndex) {
      return;
    }


    var data = JSON.stringify({
      "CalendarEventID": "",
      "UserID": "",
      "Title": "",
      "StartDate": moment(StartDate, "DD-MM-YYYY"),
      "EndDate": moment(EndDate, "DD-MM-YYYY"),
      "PaginationID": paginationIndex,
      "PageLength": `${pageCount}`
    });

    var config = {
      method: 'POST',
      url: services.API_URL + "CalendarEvent/getCalendarEvent",
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    services.JsonValue(config).then(response => {
      if (response.data.status) {
        setTableData(response.data.data)
        setPageId(pageId + 1)
        setAllTableData([...allTableData, ...response.data.data])
        setTotalCount(response.data.total[0].TotalCount);
      }
    }, error => { })
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  }
  const handleInvitationStartchange = (date) => setStartDate(date);
  const handleEndDatechange = (date) => setEndDate(date);

  const onSearchClick = (e) => {
    e.preventDefault();
    if (StartDate && EndDate && StartDate > EndDate) {
      setDateError('Start Date Must Be Less Than End date');
    }
    GetCalendarEvent(pageCount);
  }
  const OnSearchCancelClick = () => {
    setTitle('')
    setStartDate(new Date())
    setEndDate(new Date())
    setDateError('')
    GetCalendarEvent(pageCount, "cancle");
  }
  const ExcelClick = () => {
    handleExcelExport();
  }
  const OnFilterClick = (e) => {
    e.preventDefault();
    if (isfiltervisible === false) {
      setFilter(true);
      seticonFilter("fa fa-minus");
    }
    else {
      setFilter(false);
      seticonFilter("fa fa-plus");
    }
  }
  const CloseClick = (e) => {
    e.preventDefault();
    if (isfiltervisible1 === false) {
      setFilter1(true);
      seticonFilter1("fa fa-minus");
    }
    else {
      setFilter1(false);
      seticonFilter1("fa fa-plus");
    }
    navigate('/CalenderEvent')
  }
  const EditCalendarEvent = (e) => {
    debugger;
    navigate("/CalenderEvent", {
      state: e
    });
  };


  const CheckAlert = (id) => {
    debugger;
    Swal.fire({
      title: 'Are you Sure You Want to Delete?', icon: "warning", showCancelButton: true, confirmButtonText: 'Delete', cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {

        var data = JSON.stringify({
          "CalendarEventID": id
        });
        var config = {
          method: 'POST',
          url: services.API_URL + "CalendarEvent/DeletCalendarEvent",
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };
        services.JsonValue(config).then(response => {
          if (response.data.status) {
            GetCalendarEvent(pageCount);
            Swal.fire({
              title: 'Successfully Deleted', icon: "success", width: "400px", showConfirmButton: false, timer: 1500
            });
          } else {
            Swal.fire({ position: 'top-end', icon: 'error', title: response.data.message, showConfirmButton: false, timer: 1500 });
          }
        }, error => { })
      } else if (result.isDenied) {
        Swal.close();
      }
    });
  }

  const HandleImagedelete = (CalendarEventImageDetailID, Image) => {
    debugger;
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        var data = JSON.stringify({
          "CalendarEventImageDetailID": CalendarEventImageDetailID,
          "CalendarImage": Image,
        });

        var config = {
          method: 'POST',
          url: services.API_URL + "CalendarEvent/DeleteCalendarEventImages",
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };

        services.JsonValue(config).then(response => {
          if (response.data.status) {
            GetCalendarEvent(pageCount);
            Swal.fire({
              title: 'Successfully Deleted',
              icon: "success",
              width: "400px",
              showConfirmButton: false,
              timer: 1500
            });
          } else {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: response.data.message,
              showConfirmButton: false,
              timer: 1500
            });
          }
        }).catch(error => {
          // Handle API error if needed
          console.error("Error deleting image:", error);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          });
        });
      }
    });
  };

  return (
    <>
      <div className="body-wrapper">
        <div className='container-fluid'>
          <div className='row'>

            <div className='col-md-12'>
              <FormWithConstraints
                ref={form}
                onSubmit={OnFilterClick}
                noValidate>

                {isfiltervisible &&
                  <div className="card">
                    <div className="px-3 py-2 border-bottom">
                      <h5>Searching</h5>
                    </div>
                    <div className="card-body">
                      <div className='row'>

                        <div className='col-md-3'>
                          <div className="form-group">
                            <label htmlFor="Title" className="form-label fw-semibold">Title</label>
                            <input type="Title" onChange={handleTitleChange} required value={Title} maxLength={20} onKeyPress={allowOnlyCharacters} type1="textnm" className="form-control" placeholder="Enter Title"></input>
                          </div><br></br>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="Name" className="form-label fw-semibold"> Start Date</label>
                            <DatePicker
                              dateFormat="dd-MM-yyyy"
                              selected={StartDate}
                              autoComplete="off"
                              className="form-control readonly"
                              id="txtTaskDate"
                              value={StartDate}
                              placeholderText="dd-MM-yyyy"
                              onChange={handleInvitationStartchange}
                              showMonthDropdown
                              showYearDropdown
                            />
                          </div><br></br>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="Name" className="form-label fw-semibold"> End Date</label>
                            <DatePicker
                              dateFormat="dd-MM-yyyy"
                              selected={EndDate}
                              autoComplete="off"
                              className="form-control readonly"
                              id="txtTaskDate"
                              value={EndDate}
                              placeholderText="dd-MM-yyyy"
                              onChange={handleEndDatechange}
                              showMonthDropdown
                              showYearDropdown
                            />
                            {dateError && <p style={{ color: 'red' }}>{dateError}</p>}
                          </div>

                        </div>

                        <div className='col-md-3'>
                          <button type="button" onClick={onSearchClick} className="btn btn-primary mt-12 mr-3 mobmt-2" style={{ marginRight: '5px', marginTop: '30px' }} >Search </button>
                          <button type="button" onClick={OnSearchCancelClick} className="btn btn-danger mt-12 mobmt-1" style={{ marginRight: '5px', marginTop: '30px' }}>Cancel </button>

                          <a className="btn btn-outline-success font-weight-bolder mt-12" onClick={ExcelClick} style={{ marginRight: '5px', marginTop: '30px' }}>
                            <i id="btnExcel" className="fa fa-file-excel" /> Excel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </FormWithConstraints>
            </div>

            <div className='col-md-12'>

              <ThemeProvider theme={defaultMaterialTheme}>
                <MaterialTable columns={cols} data={tableData}

                  actions={[{
                    icon: 'edit', tooltip: 'Edit', onClick: (e, r) => EditCalendarEvent(r.CalendarEventID)
                  },
                  {
                    icon: 'delete', tooltip: 'Delete', onClick: (e, r) => CheckAlert(r.CalendarEventID, r.UserName)
                  }
                  ]}

                  components={{
                    Toolbar: props => (
                      <div className="row" >
                        <div className="col-md-10.5" style={{ display: 'flex', alignItems: 'center', marginLeft: '0.5%', marginTop: '0.5%', marginBottom: '0.5%' }}>
                          <h5 style={{ marginLeft: '5px' }}> View Calendar Event : <span className="badge bg-light-primary text-black fw-semibold fs-2 ">{TotalCount}</span></h5>

                          <div className="col-md-10" style={{ textAlign: 'right' }}>
                            <a className="btn btn-outline-primary font-weight-bolder" style={{ marginRight: '5px', marginTop: '5px' }} onClick={OnFilterClick}>
                              <i id="btnFilter" className={iconFilter} isfiltervisible='false' /> Filter
                            </a>

                            <a className="btn btn-outline-dark font-weight-bolder" onClick={CloseClick} style={{ marginTop: '6px', marginRight: '5px' }}>
                              <i id="btnFilter" className={iconFilter1} isfiltervisible='false' /> Add Calendar Event
                            </a>

                          </div>
                        </div>
                      </div>
                    )
                  }}

                  options={{
                    headerStyle: { backgroundColor: '#B71C1C', color: 'white', fontFamily: 'system-ui' }, toolbar: true, actionsColumnIndex: -1,
                    paging: false, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                  }}

                  detailPanel={[
                    {
                      icon: '',
                      tooltip: 'Image Detail',
                      render: rowData => {
                        return (
                          <>
                            <div style={{ border: '1px' }}>
                              <table className="table table-light-dark" align="center" cellSpacing="0" rules="all" border="1" style={{ width: '100%', bordercollapse: 'collapse', border: '1px solid black' }}>
                                <thead>
                                  <tr>
                                    <td style={{ backgroundColor: '#B71C1C', border: '1px solid black', color: 'white', fontWeight: 'bold' }}>Calendar Images</td>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th>
                                      <span style={{ display: "flex" }}>
                                        {rowData.CalendarEventImageDetail && rowData.CalendarEventImageDetail.length > 0 ? (
                                          rowData.CalendarEventImageDetail?.map((image, index) => (
                                            <div key={index} style={{ position: 'relative' }}>
                                              <a href={calendarUrl + image.CalendarImage} target="_blank" rel="noopener noreferrer">
                                                <img
                                                  src={calendarUrl + image.CalendarImage}
                                                  alt={`Image ${index + 1}`}
                                                  style={{ width: '80px', height: '80px', marginRight: '5px', border: '1px solid black', color: 'black' }}
                                                  onError={(e) => {
                                                    console.error(`Error loading image ${index + 1}:`, e);
                                                  }}
                                                />
                                              </a>
                                              <MdDelete
                                                onClick={() => HandleImagedelete(image.CalendarEventImageDetailID, "CalendarImage")}
                                                style={{ fontSize: '1.3em', position: 'absolute', zIndex: '2', left: '5px', top: '5px', cursor: 'pointer' }}
                                              />
                                            </div>
                                          ))
                                        ) : (
                                          <div>
                                            <img
                                              src="Images/Noimage.png"
                                              alt="No Image Available"
                                              style={{ width: '80px', height: '80px', marginRight: '5px', border: '1px solid black', color: 'black' }}
                                            />
                                          </div>
                                        )}
                                      </span>

                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                          </>
                        )
                      },
                    }
                  ]}
                />
              </ThemeProvider>
            </div>
          </div>

          <div id="ctl00_ContentPlaceHolder1_divPagination">
            <div className="col-lg-12 col-md-12 col-12 cssPagination" style={{ boxShadow: '2px 2px 5px #808080' }}>
              <nav aria-label="Page navigation">
                <ul className="GridPager pagination" style={{ paddingTop: '2px' }}>
                  <div style={{ marginTop: '0.5%', marginBottom: '0.5%', marginLeft: '3px' }}>
                    <button type="button" onClick={setPreviousApi} className="btn btn-primary mt-12 mr-3 mobmt-2 page-disable" style={{ marginRight: '1.5%' }} >Previous </button>
                  </div>
                  <div style={{ marginTop: '0.5%', marginBottom: '0.5%', marginLeft: '3px' }} >
                    <button type="button" onClick={setNextApi} className="btn btn-danger mt-12 mobmt-1 page-disable" style={{ marginRight: '1.5%' }}>Next </button>
                  </div>
                </ul>
              </nav>
            </div>
          </div>


        </div>
      </div>

    </>
  )
}

export default ViewCalendarEvent
