import React, { useState, useRef, useEffect } from 'react'
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import { FormWithConstraints, FieldFeedbacks, FieldFeedback, } from 'react-form-with-constraints';
import { allowOnlyNumbers, allowOnlyCharacters } from '../inputvalidation';
import '../style.css';
import services from "../Services/Services";
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

const Addinvitation = () => {

    const [cookies, setCookie] = useCookies(['user']);
    const [InvitationID, setInvitationID] = useState('')
    const [ckUserID, setckUserID] = useState(cookies.ckUserID);

    const [InvitationDetails, setInvitationDetails] = useState('')

    const [NatureOfProgram, setNatureOfProgram] = useState('')
    const [NatureError, setNatureError] = useState('')

    const [InvitationStartDate, setInvitationStartDate] = useState(new Date())
    const [InvitationStartDateError, setInvitationStartDateError] = useState('');

    const [InvitationEndDate, setInvitationEndDate] = useState(new Date())
    const [dateError, setDateError] = useState("");


    const [InvitationStartTime, setInvitationStartTime] = useState('')
    const [InvitationStartTimeError, setInvitationStartTimeError] = useState('');

    const [InvitationEndTime, setInvitationEndTime] = useState('')

    const [ContactName, setContactName] = useState('')
    const [ContactNameerror, setContactNameerror] = useState('')

    const [ContactMobileNo, setContactMobileNo] = useState('')
    const [ContactMobileNoerror, setContactMobileNoerror] = useState('')

    const [Venue, setVenue] = useState('')
    const [VenueError, setVenueError] = useState('')

    const [ReferenceName, setReferenceName] = useState('')

    const [ReferenceMobileNo, setReferenceMobileNo] = useState('')
    const [ReferenceMobileNoerror, setReferenceMobileNoerror] = useState('')

    const [Details, setDetails] = useState('')

    const [Images, setImages] = useState([])
    const [ImgerrorMsg, setImgErrorMsg] = useState('')
    const [ImageserrorMsg, setImageserrorMsg] = useState(true)

    const [PDF, setPDF] = useState('')
    const [OldPDF, setOldPDF] = useState('')
    const [PDFerrorMsg, setPDFerrorMsg] = useState(true)

    const [GoogleMapUrl, setGoogleMapUrl] = useState('');
    const [GoogleMaperror, setGoogleMapError] = useState('');

    const form = useRef(FormWithConstraints);
    const { state } = useLocation();
    const navigate = useNavigate();

    const [issubmitvisible, setIssubmitvisible] = useState(true)
    const [isUpdatevisible, setIsUpdatevisible] = useState(false)

    const ref = useRef();
    const ref2 = useRef();
    const ref1 = useRef();


    useEffect(() => {       
        if (state != null) {
            EditInvitation(state);
          }
    }, []);

    const handleContactNameChange = (e) => {
        setContactName(e.target.value);
        setContactNameerror('')
    }

    const handleContactMobileNoChange = (e) => {
        setContactMobileNoerror('')
        setContactMobileNo(e.target.value);

        const inputContactMobileNoNumber = e.target.value;
        const MoNo = inputContactMobileNoNumber.replace(/\D/g, '');
        setContactMobileNo(MoNo);
        setContactMobileNoerror('');
    }

    const handleInvitationStartchange = (date) => {
        setInvitationStartDate(date);
        setInvitationStartDateError('');
    };

    const handleInvitationEndDatechange = (date) => setInvitationEndDate(date);

    const handleTimeChange = (e) => {
        setInvitationStartTime(e.target.value);
        setInvitationStartTimeError('');
    };

    const validateTime = (time) => {

        const isValidFormat = /^([01]\d|2[0-3]):([0-5]\d)$/.test(time);
        if (!isValidFormat) {
            setInvitationStartTimeError('Please Enter Invitation Start Time');
            return false;
        }
        return true;
    };

    const handleInvitationEndTimeChange = (e) => {
        setInvitationEndTime(e.target.value);
    }

    const handleRefMobileChange = (e) => {

        if (e != null) {
            setReferenceMobileNo(e);
        } else {
            setReferenceMobileNo('');
        }

        const inputReferenceMobileNoNumber = e.target.value;
        // Remove any non-digit characters from the input
        const ReferMoNo = inputReferenceMobileNoNumber.replace(/\D/g, '');
        setReferenceMobileNo(ReferMoNo);
        setReferenceMobileNoerror('');
    }

    const HandleImages = (e) => {
        if (['png', 'jpg', 'jpeg', 'PNG'].includes(e.target.value.split('.')[e.target.value.split('.').length - 1])) {
            setImageserrorMsg(true)
            const files = e.target.files;
            setImages([...Images, ...files]);
            setImgErrorMsg('')
        }
        else {
            setImageserrorMsg(false)
        }
    }

    const handlePDF = (e) => {
        if (['pdf', 'PDF'].includes(e.target.value.split('.')[e.target.value.split('.').length - 1])) {
            setPDFerrorMsg(true);
            if (e.target.files[0]) {
                setPDF(e.target.files);
            }
        } else {
            setPDFerrorMsg(false);
        }
        setOldPDF('');
    }

    const ViewHandler = () => {
        navigate('/viewinvitation')  
    }
    const submitHandler = (e) => {

        e.preventDefault();
        var allvalid = true;

        if (NatureOfProgram.length === 0) {
            setNatureError(true)
            allvalid = false;
        }

        if (Venue.length === 0) {
            setVenueError(true)
        }

        if (!InvitationStartDate) {
            setInvitationStartDateError('Please Select Invitation Start Date');
            allvalid = false;
        } else {
            setInvitationStartDateError('');
        }

        if (InvitationStartDate && InvitationEndDate && InvitationStartDate > InvitationEndDate) {
            setDateError('Start Date Must Be Less Than End date');
        }
        else {
            setDateError('')
        }

        var InvStartdt;
        if (InvitationStartDate !== "") {
            InvStartdt = moment(InvitationStartDate).format('DD-MM-YYYY');
        }

        var InvEnddt;
        if (InvitationEndDate !== "") {
            InvEnddt = moment(InvitationEndDate).format('DD-MM-YYYY');
        }

        if (!validateTime(InvitationStartTime)) {
            allvalid = false;
        }

        if (ContactName.length === 0) {
            setContactNameerror(true)
            allvalid = false;
        }

        if (ContactMobileNo?.trim() !== '') {
            if (/^\d{10}$/.test(ContactMobileNo)) {
                setContactMobileNoerror('');
            } else {
                setContactMobileNoerror('Please Enter Valid 10 Digit Mobile Number');
                allvalid = false;
            }
        } else {
            setContactMobileNoerror('Please Enter Contact Mobile No');
            allvalid = false;
        }

        if (ReferenceMobileNo.length !== 10 && ReferenceMobileNo.length) {
            setReferenceMobileNoerror('Please Enter Valid 10 Digit Mobile Number');
            allvalid = false;
        }

        if (!ImageserrorMsg || !PDFerrorMsg) {
            return
        }

        if (GoogleMapUrl.length !== 0) {
            if (!validateUrl(GoogleMapUrl)) {
                setGoogleMapError('Please enter a valid Google Map URL');
                allvalid = false;
            } else {
                setGoogleMapError('');
            }
        }


        if (allvalid) {
            Swal.fire({
                title: 'Are you sure?',
                // text: 'You are about to submit the form.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, submit it!',
            }).then((result) => {
                if (result.isConfirmed) {

                    var form = new FormData();
                    if (InvitationID !== "") {
                        form.append('InvitationID', InvitationID);
                        form.append('UserID', ckUserID);
                        form.append('NatureOfProgram', NatureOfProgram);
                        form.append('InvitationStartDate', InvStartdt);
                        form.append('InvitationEndDate', InvEnddt);
                        form.append('InvitationStartTime', InvitationStartTime);
                        form.append('InvitationEndTime', InvitationEndTime);
                        form.append('Venue', Venue);
                        form.append('ContactName', ContactName);
                        form.append('ContactMobileNo', ContactMobileNo);
                        form.append('ReferenceName', ReferenceName);
                        form.append('ReferenceMobileNo', ReferenceMobileNo);
                        form.append('Details', Details);
                        for (let i = 0; i < Images.length; i++) {
                            form.append('Images', Images[i]);
                        }

                        form.append('OldPDF', OldPDF);

                        for (let i = 0; i < PDF.length; i++) {
                            form.append('PDF', PDF[i]);
                        }

                        form.append('GoogleMapUrl', GoogleMapUrl);
                    }
                    else {

                        form.append('InvitationID', "");
                        form.append('UserID', ckUserID);
                        form.append('NatureOfProgram', NatureOfProgram);
                        form.append('InvitationStartDate', InvStartdt);
                        form.append('InvitationEndDate', InvEnddt);
                        form.append('InvitationStartTime', InvitationStartTime);
                        form.append('InvitationEndTime', InvitationEndTime);
                        form.append('Venue', Venue);
                        form.append('ContactName', ContactName);
                        form.append('ContactMobileNo', ContactMobileNo);
                        form.append('ReferenceName', ReferenceName);
                        form.append('ReferenceMobileNo', ReferenceMobileNo);
                        form.append('Details', Details);

                        for (let i = 0; i < Images.length; i++) {
                            form.append('Images', Images[i]);
                        }
                        form.append('PDF', PDF[0]);
                        form.append('GoogleMapUrl', GoogleMapUrl);
                    }

                    services.SetInvitation(form).then(response => {
                        debugger;
                        if (response.data.status) {

                            if (InvitationID !== "") {
                                // Swal.fire({ position: 'top-end', toast: true, icon: 'success', title: 'Successfully Updated', showConfirmButton: false, timer: 1500 });
                                Swal.fire({
                                    title: 'Successfully Updated', icon: "success", showConfirmButton: false, timer: 3000
                                });
                                window.location.href = "/viewinvitation"
                                setIssubmitvisible(true);
                                setIsUpdatevisible(false);
                            }
                            else {
                                Swal.fire({
                                    title: 'Successfully Inserted', icon: "success", showConfirmButton: false, timer: 3000
                                });
                                window.location.href = "/viewinvitation"
                            }
                            ClearData();
                        }

                    }, error => { console.log(error, 'error') });
                }
            })
        }
    }
    //--------GoogleMap Url validation----------//    
    const validateUrl = (url) => {
        const googleMapUrlPattern = /^(https?:\/\/)?((www\.)?google\.[a-z]{2,3}\/maps\/?.*|maps\.app\.goo\.gl\/.*)$/i;
        const generalUrlPattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-_]+)\.([a-zA-Z]{2,})(\/[a-zA-Z0-9-_#&=?]*)*\/?$/i;
    
        return googleMapUrlPattern.test(url) || generalUrlPattern.test(url);
    };
    const handleGoogleMapUrl = (event) => {
        const url = event.target.value;
        setGoogleMapUrl(url);

        if (url.length > 0) {
            // Check if the URL matches either pattern
            if (!validateUrl(url)) {
                setGoogleMapError('Please enter a valid Google Map URL');
            } else {
                setGoogleMapError('');
            }
        } else {
            setGoogleMapError('');
        }
    };
    //-----------------------------------//

    const EditInvitation = (id) => {
        debugger;
        setIsUpdatevisible(true)
        setIssubmitvisible(false)

        var data = JSON.stringify({
            "InvitationID": id,
            "DepartmentID": "",
            "UserID": "",
            "OfficerName": "",
            "OfficerMobileNo": "",
            "InvitationStatus": "",
            "UserType": "",
            "EntryDate": "",
            "StartDate": "",
            "EndDate": "",
            "PaginationID": "0",
            "PageLength": "10"
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "Invitation/GetInvitation",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        services.JsonValue(config).then(response => {
            if (response.data.status) {

                var date = "";
                var dateString = response.data.data[0].InvitationStartDate;
                if (dateString != null) {
                    var dateMomentObject = moment(dateString, "DD-MM-YYYY");
                    date = dateMomentObject.toDate();
                }
                else {
                    date = "";
                }

                var EndDate = "";
                var dateString1 = response.data.data[0].InvitationEndDate;
                if (dateString1 != null) {
                    var dateMomentObject1 = moment(dateString1, "DD-MM-YYYY");
                    EndDate = dateMomentObject1.toDate();
                }
                else {
                    EndDate = "";
                }

                setInvitationID(response.data.data[0].InvitationID)
                setNatureOfProgram(response.data.data[0]?.NatureOfProgram || "")

                setInvitationStartDate(date)
                setInvitationEndDate(EndDate)

                setInvitationStartTime(response.data.data[0]?.InvitationStartTime || "")
                setInvitationEndTime(response.data.data[0]?.InvitationEndTime || "")

                setContactName(response.data.data[0]?.ContactName || "")
                setContactMobileNo(response.data.data[0]?.ContactMobileNo || "")

                setReferenceName(response.data.data[0]?.ReferenceName || "")
                setReferenceMobileNo(response.data.data[0]?.ReferenceMobileNo || "")
                setDetails(response.data.data[0]?.Details || "")
                setOldPDF(response.data.data[0]?.PDF || '')

                setVenue(response.data.data[0]?.Venue || "")
                setReferenceName(response.data.data[0]?.ReferenceName || "")
                setGoogleMapUrl(response.data.data[0]?.GoogleMapUrl || "")
            }
        }, error => { })
    }

    const ClearData = () => {
        setInvitationID('')
        setNatureOfProgram('')
        setNatureError('')

        setVenue('')
        setVenueError('')

        setContactName('')
        setContactNameerror('')
        setContactMobileNo('')
        setContactMobileNoerror('')
        setDetails('')

        setReferenceName('')
        setReferenceMobileNo('')

        setInvitationStartDate('')
        setInvitationEndDate('')

        setInvitationStartTime('')
        setInvitationEndTime('')

        setImgErrorMsg('')

        setPDF('')
        setOldPDF('');
        setPDFerrorMsg(true)

        setGoogleMapUrl('')
        setGoogleMapError('')
        ref1.current.value = ''
        ref2.current.value = ''
    }

    const CloseClick = () => {
        setIssubmitvisible(true)
        setIsUpdatevisible(false)
        setNatureOfProgram('')
        setNatureError('')
        // setInvitationStartDate('')
        setInvitationStartDateError('')
        setDateError('')
        // setInvitationEndDate('')
        setInvitationStartTime('')
        setInvitationStartTimeError('')
        setInvitationEndTime('')
        setContactName('')
        setContactNameerror('')
        setContactMobileNo('')
        setContactMobileNoerror('')
        setVenue('')
        setVenueError('')
        setReferenceName('')
        setReferenceMobileNo('')
        setReferenceMobileNoerror('')
        setDetails('')

        setImgErrorMsg('')

        setPDF('')
        setOldPDF('');
        setPDFerrorMsg(true)

        setGoogleMapUrl('')
        setGoogleMapError('')
        ref1.current.value = ''
        ref2.current.value = ''
    }


    return (
        <div className="body-wrapper">
            <div className="container-fluid">
                <div className='row'>
                    <div className='col-md-12'>

                        <div className="card">
                            <div className="px-3 py-2 border-bottom">
                                <h5>Add Invitation</h5>
                            </div>

                            <FormWithConstraints
                                ref={form}
                                // onSubmit={submitHandler}
                                noValidate>

                                <div className="card-body">
                                    <div className='row'>

                                        <div className='col-md-3'>
                                            <div className="form-group">
                                                <label htmlFor="NatureOfProgram" className="form-label fw-semibold"> Nature Of Program<span className="text-danger">*</span> </label>
                                                <input type="text" onChange={e => setNatureOfProgram(e.target.value)} required value={NatureOfProgram} type1="textnm" className="form-control" placeholder="Enter Nature Of Program"></input>
                                                {NatureError && <span style={{ color: 'red' }}>{NatureOfProgram.length <= 0 ? 'Please Enter Nature Of Program' : ''}</span>}<br></br>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="Name" className="form-label fw-semibold">Invitation Start Date</label>
                                                <DatePicker
                                                    selected={InvitationStartDate}
                                                    onChange={handleInvitationStartchange}
                                                    dateFormat="dd-MM-yyyy"
                                                    value={InvitationStartDate}
                                                    placeholderText="DD-MM-YYYY"
                                                    className="form-control readonly"
                                                    autoComplete="off"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                />
                                            </div>
                                            {InvitationStartDateError && <div style={{ color: 'red' }}>{InvitationStartDateError}</div>}<br></br>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="Name" className="form-label fw-semibold">Invitation End Date</label>
                                                <DatePicker
                                                    selected={InvitationEndDate}
                                                    onChange={handleInvitationEndDatechange}
                                                    value={InvitationEndDate}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="DD-MM-YYYY"
                                                    className="form-control readonly"
                                                    autoComplete="off"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                />
                                                {dateError && <p style={{ color: 'red' }}>{dateError}</p>}

                                            </div><br></br>
                                        </div>

                                        <div className='col-md-3'>
                                            <label htmlFor="date" className="form-label fw-semibold">Invitation Start Time<span className="text-danger">*</span></label>
                                            <input type="time" className="form-control" value={InvitationStartTime} onChange={handleTimeChange} />
                                            {InvitationStartTimeError && <p style={{ color: 'red' }}>{InvitationStartTimeError}</p>}<br></br>
                                        </div>

                                        <div className='col-md-3'>
                                            <label htmlFor="date" className="form-label fw-semibold">Invitation End Time</label>
                                            <input type="time" className="form-control" value={InvitationEndTime} onChange={handleInvitationEndTimeChange} /><br></br>
                                        </div>

                                        <div className='col-md-3'>
                                            <div className="form-group">
                                                <label htmlFor="Venue" className="form-label fw-semibold">Venue <span className="text-danger">*</span></label>
                                                <textarea name="Venue" onChange={e => setVenue(e.target.value)} required value={Venue} cols={40} className="form-control" placeholder="Enter Venue" />
                                                {VenueError && <span style={{ color: 'red' }}>{Venue.length <= 0 ? 'Please Enter Venue' : ''}</span>}
                                            </div><br></br>
                                        </div>

                                        <div className='col-md-3'>
                                            <div className="form-group">
                                                <label htmlFor="ContactName" className="form-label fw-semibold">Contact Name<span className="text-danger"> *</span></label>
                                                <input type="ContactName" onChange={handleContactNameChange} required value={ContactName} maxLength={50} onKeyPress={allowOnlyCharacters} type1="textnm" className="form-control" placeholder="Enter Contact Name"></input>
                                                {ContactNameerror && <span style={{ color: 'red' }}>{ContactName.length <= 0 ? 'Please Enter Contact Name' : ''}</span>}<br></br>
                                            </div>
                                        </div>

                                        <div className='col-md-3'>
                                            <div className="form-group">
                                                <label htmlFor="ContactMobileNo" className="form-label fw-semibold">Contact MobileNo<span className="text-danger"> *</span></label>
                                                <input type="ContactMobileNo" onChange={handleContactMobileNoChange} required value={ContactMobileNo} maxLength={10} onKeyPress={allowOnlyNumbers} type1="textnm" className="form-control" placeholder="Enter Contact Mobile No"></input>
                                                <p style={{ color: 'red' }}>{ContactMobileNoerror}</p>
                                            </div>
                                        </div>

                                        <div className='col-md-3'>
                                            <div className="form-group">
                                                <label htmlFor="ReferenceName" className="form-label fw-semibold"> Reference Name </label>
                                                <input type="ReferenceName" onChange={e => setReferenceName(e.target.value)} required value={ReferenceName} maxLength={30} onKeyPress={allowOnlyCharacters} type1="textnm" className="form-control" placeholder="Enter Reference Name"></input><br></br>
                                            </div>
                                        </div>

                                        <div className='col-md-3'>
                                            <div className="form-group">
                                                <label htmlFor="ReferenceMobileNo" className="form-label fw-semibold"> Reference Mobile No</label>
                                                <input type="ReferenceMobileNo" onChange={handleRefMobileChange} required value={ReferenceMobileNo} maxLength={10} onKeyPress={allowOnlyNumbers} type1="textnm" className="form-control" placeholder="Enter Referer Mobile No"></input>
                                                <p style={{ color: 'red' }}>{ReferenceMobileNoerror}</p>
                                            </div>
                                        </div>

                                        <div className='col-md-3'>
                                            <div className="form-group">
                                                <label htmlFor="Details" className="form-label fw-semibold">Details </label>
                                                <textarea name="Details" onChange={e => setDetails(e.target.value)} required value={Details} cols={200} className="form-control" placeholder="Enter Deatil" />
                                            </div><br></br>
                                        </div>

                                        <div className="col-md-3">
                                            <label htmlFor="fileInput" className="form-label fw-semibold">Images
                                                <span style={{ color: '#B71C1C' }}> (Single/ Multiple)</span>
                                            </label>
                                            <input type="file" ref={ref2} className="form-control" multiple onChange={HandleImages} accept="image/jpeg, image/png, image/jpg, image/PNG" style={{ height: "37px" }} />
                                            {!ImageserrorMsg && <span className="text-danger">Please Enter Only Image File</span>}
                                            {ImgerrorMsg && <p style={{ color: 'red' }}>{ImgerrorMsg}</p>}<br></br>
                                        </div>

                                        <div className="col-md-3">
                                            <label htmlFor="fileInput" className="form-label fw-semibold"> Upload PDF</label>
                                            <input type="file" ref={ref1} className="form-control" onChange={handlePDF} accept='application/pdf, application/PDF' style={{ height: "37px" }} />
                                            {OldPDF !== "" && (<label style={{ color: "Red" }}>{OldPDF ? OldPDF : ""} </label>)}
                                            {!PDFerrorMsg && <span className="text-danger">Please Enter Only PDF</span>}<br></br>
                                        </div>

                                        <div className='col-md-3'>
                                            <div className="form-group">
                                                <label htmlFor="GoogleMapUrl" className="form-label fw-semibold">Google Map URL
                                                    <span style={{ color: '#B71C1C' }}> </span> </label>
                                                <input
                                                    type="text"
                                                    onChange={handleGoogleMapUrl}
                                                    required value={GoogleMapUrl}
                                                    className="form-control"
                                                    placeholder="Enter Google Map URL"
                                                />
                                                {GoogleMaperror && <div style={{ color: 'red' }}>{GoogleMaperror}</div>}
                                            </div>
                                        </div>

                                        <div className="col-md-3" style={{ marginTop: '2%' }}>
                                            {issubmitvisible &&
                                                <button type="button" onClick={submitHandler} className="btn btn-outline-primary font-weight-bolder ml-2 " style={{ marginRight: '2%' }} >Submit</button>
                                            }
                                            {isUpdatevisible &&
                                                <button type="button" onClick={submitHandler} className="btn btn-outline-primary font-weight-bolder ml-2 " style={{ marginRight: '2%' }} >Update</button>
                                            }
                                            <button type="button" onClick={CloseClick} className="btn btn-outline-danger font-weight-bolder ml-2" style={{ marginRight: '2%' }} >Cancel</button>
                                            <button type="button" onClick={ViewHandler} className="btn btn-outline-primary font-weight-bolder ml-2">View</button>
                                        </div>

                                    </div>
                                </div>

                            </FormWithConstraints>
                        </div>
                    </div>
                </div >
            </div >
        </div >

    )
}

export default Addinvitation


