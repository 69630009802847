import React, { useState, useRef, useEffect } from 'react'
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import { FormWithConstraints, FieldFeedbacks, FieldFeedback, } from 'react-form-with-constraints';
import { allowOnlyNumbers, allowOnlyCharacters } from '../inputvalidation';
import '../style.css';
import services from "../Services/Services";
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select'
import MultiSelect from "@khanacademy/react-multi-select";


const Sendinvitation = () => {

    const [cookies, setCookie] = useCookies(['user']);
    const [SendInvitationID, setSendInvitationID] = useState('')
    const [ckUserID, setckUserID] = useState(cookies.ckUserID);

    const [NatureOfProgram, setNatureOfProgram] = useState('')
    const [NatureError, setNatureError] = useState('')

    const [InvitationStartDate, setInvitationStartDate] = useState(new Date())
    const [InvitationStartDateError, setInvitationStartDateError] = useState('');

    const [InvitationEndDate, setInvitationEndDate] = useState(new Date())
    const [dateError, setDateError] = useState("");

    const [InvitationStartTime, setInvitationStartTime] = useState('')
    const [InvitationStartTimeError, setInvitationStartTimeError] = useState('');

    const [InvitationEndTime, setInvitationEndTime] = useState('')

    const [Address, setAddress] = useState('')
    const [AddressError, setAddressError] = useState('')

    const [Details, setDetails] = useState('')

    const [InvitationImage, setInvitationImage] = useState([])
    const [ImgerrorMsg, setImgErrorMsg] = useState('')
    const [InvitationImageerrorMsg, setInvitationImageerrorMsg] = useState(true)

    const [PDF, setPDF] = useState('')
    const [OldPDF, setOldPDF] = useState('')
    const [PDFerrorMsg, setPDFerrorMsg] = useState(true)

    const [GoogleMapUrl, setGoogleMapUrl] = useState('');
    const [GoogleMaperror, setGoogleMapError] = useState('');

    const form = useRef(FormWithConstraints);
    const params = new URLSearchParams(useLocation().search);

    const [issubmitvisible, setIssubmitvisible] = useState(true)
    const [isUpdatevisible, setIsUpdatevisible] = useState(false)

    const ref = useRef();
    const ref2 = useRef();
    const ref1 = useRef();

    const [SendInvitationType, setSendInvitationType] = useState('')
    const [SendInvitationTypeError, setSendInvitationTypeError] = useState('')

    const [isCategory, setisCategory] = useState(false)
    const [isManually, setisManually] = useState(false)

    const [isTypeVisible, setTypeVisible] = useState(true)

    const [CategoryID, setCategoryID] = useState([])
    const [Categoryes, getCategory] = useState([])
    const [CategoryError, setCategoryError] = useState('');

    useEffect(() => {
        GetCategory()
    }, []);

    const GetCategory = () => {
        debugger;

        var data = JSON.stringify({
            "CategoryID": "",
            "CategoryName": "",
            "PaginationID": "-1",
            "PageLength": ""
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "Category/GetCategory",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {

            if (response.data.status) {
                debugger
                getCategory(response.data.data.map(item => ({ value: item.CategoryID, label: item.CategoryName })));
                // setCategoryID([])
            }

        }, error => { })
    }
    const onCategoryChange = (e) => {
        setCategoryID(e);
        setCategoryError('')
    }

    const onSendInvitationTypeChange = (e) => {
        var value = e.target.value;

        if (value == "Category") {
            setisCategory(true);
        } else {
            setisCategory(false);
        }

        if (value == "Manually") {
            setisManually(true);
        } else {
            setisManually(false);
        }

        setSendInvitationType(e.target.value);
        setSendInvitationTypeError('')
        setCategoryError('')
        setaddTable(false)
        setEmailError(false)
        setMobileNoError(false)

    }

    const [rows, setrows] = useState([{ Name: '', MobileNo: '', Email: '' }]);
    const [addtable, setaddTable] = useState(false)
    const [mobileNoError, setMobileNoError] = useState(false);
    const [emailError, setEmailError] = useState(false);

    const onNameChange = idx => e => {
        const { value } = e.target;
        var rowdata = [...rows];
        rowdata[idx] = {
            ...rowdata[idx],
            Name: value,
        };
        setrows(rowdata, () => { form.validateFields(e.target) });
    };

    //--- Mobile number validation---/
    const onMobileNoChange = (idx) => (event) => {
        const { value } = event.target;
        if (!validateMobileNo(value)) {
            setrows(prevRows => {
                const updatedRows = [...prevRows];
                updatedRows[idx].mobileNoError = true; // Set error for this row
                return updatedRows;
            });
        } else {
            setrows(prevRows => {
                const updatedRows = [...prevRows];
                updatedRows[idx].mobileNoError = false; // Clear error for this row
                return updatedRows;
            });
        }

        // Update the value in rows state
        setrows(prevRows => {
            const updatedRows = [...prevRows];
            updatedRows[idx].MobileNo = value;
            return updatedRows;
        });
    };
    const validateMobileNo = (mobileNo) => {
        const mobileRegex = /^[0-9]{10}$/;
        return mobileRegex.test(mobileNo);
    };
    //--------------------------------------//

    // Email validation function
    const onEmailChange = (idx) => (event) => {
        const { value } = event.target;

        // Example validation
        if (!validateEmailID(value)) {
            setrows(prevRows => {
                const updatedRows = [...prevRows];
                updatedRows[idx].emailError = true; // Set error for this row
                return updatedRows;
            });
        } else {
            setrows(prevRows => {
                const updatedRows = [...prevRows];
                updatedRows[idx].emailError = false; // Clear error for this row
                return updatedRows;
            });
        }

        // Update the value in rows state
        setrows(prevRows => {
            const updatedRows = [...prevRows];
            updatedRows[idx].Email = value;
            return updatedRows;
        });
    };
    const validateEmailID = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    //--------------------------------------//

    const handleProductAddRow = () => {
        const lastRow = rows[rows.length - 1];
        if (lastRow && (lastRow.Name?.trim() === '' || lastRow.MobileNo?.trim() === '' || lastRow.Email?.trim() === '')) {
            setaddTable(true);
            return;
        }
        const newRow = {
            SendInvitationPersonDetailID: "",
            MobileNo: "",
            Name: "",
            Email: ""
        };
        setrows([...rows, newRow]);
    };
    const handleProductRemoveSpecificRow = (idx) => () => {
        debugger;
        if (idx > 0) {
            const rowdata = [...rows];
            const SendInvitationPersonDetailID = rowdata[idx].SendInvitationPersonDetailID;

            const config = {
                method: 'GET',
                url: services.API_URL + "OurProduct/deleteProductItemSubDetail/" + SendInvitationPersonDetailID,
                headers: {
                    'Content-Type': 'application/json'
                },
            };

            services.JsonValue(config).then(response => {
                if (response.data.success) {
                    Swal.fire({ icon: 'success', title: 'Row Deleted Successfully', showConfirmButton: false, timer: 1500 });
                }
            }, error => {

            });

            rowdata.splice(idx, 1);
            setrows(rowdata);
        } else {
            Swal.fire({ icon: 'error', title: 'Cannot Remove Row', showConfirmButton: false, timer: 1500 });
        }
    };

    const handleInvitationStartchange = (date) => {
        setInvitationStartDate(date);
        setInvitationStartDateError('');
    };
    const handleInvitationEndDatechange = (date) => setInvitationEndDate(date);

    const handleTimeChange = (e) => {
        setInvitationStartTime(e.target.value);
        setInvitationStartTimeError('');
    };
    const validateTime = (time) => {

        const isValidFormat = /^([01]\d|2[0-3]):([0-5]\d)$/.test(time);
        if (!isValidFormat) {
            setInvitationStartTimeError('Please Enter Invitation Start Time');
            return false;
        }
        return true;
    };
    const handleInvitationEndTimeChange = (e) => {
        setInvitationEndTime(e.target.value);
    }
    const HandleInvitationImage = (e) => {
        if (['png', 'jpg', 'jpeg', 'PNG'].includes(e.target.value.split('.')[e.target.value.split('.').length - 1])) {
            setInvitationImageerrorMsg(true)
            const files = e.target.files;
            setInvitationImage([...InvitationImage, ...files]);
            setImgErrorMsg('')
        }
        else {
            setInvitationImageerrorMsg(false)
        }
    }
    const handlePDF = (e) => {
        if (['pdf', 'PDF'].includes(e.target.value.split('.')[e.target.value.split('.').length - 1])) {
            setPDFerrorMsg(true);
            if (e.target.files[0]) {
                setPDF(e.target.files);
            }
        } else {
            setPDFerrorMsg(false);
        }
        setOldPDF('');
    }
    const ViewHandler = () => {
        window.location.href = "/viewsendinvitation"
    }

    const submitHandler = (e) => {
        debugger
        e.preventDefault();
        var allvalid = true;

        if (GoogleMapUrl.length !== 0) {
            if (!validateUrl(GoogleMapUrl)) {
                setGoogleMapError('Please enter a valid Google Map URL');
                allvalid = false;
            } else {
                setGoogleMapError('');
            }
        }

        if (NatureOfProgram.length === 0) {
            setNatureError(true);
            allvalid = false;
        }

        if (Address.length === 0) {
            setAddressError(true);
            allvalid = false;
        }

        if (!InvitationStartDate) {
            setInvitationStartDateError('Please Select Invitation Start Date');
            allvalid = false;
        } else {
            setInvitationStartDateError('');
        }

        if (InvitationStartDate && InvitationEndDate && InvitationStartDate > InvitationEndDate) {
            setDateError('Start Date Must Be Less Than End date');
            allvalid = false;
        } else {
            setDateError('');
        }

        var InvStartdt = InvitationStartDate ? moment(InvitationStartDate).format('DD-MM-YYYY') : "";
        var InvEnddt = InvitationEndDate ? moment(InvitationEndDate).format('DD-MM-YYYY') : "";

        if (!validateTime(InvitationStartTime)) {
            allvalid = false;
        }     

        if (!InvitationImageerrorMsg || !PDFerrorMsg) {
            return;
        }

        if (SendInvitationType === 'Manually') {
            const hasEmptyFields = rows.some(row => row.Name.trim() === '' || row.MobileNo.trim() === '' || row.Email.trim() === '');
            if (hasEmptyFields) {
                setaddTable(true);
                allvalid = false;
            } else {
                setaddTable(false);
                rows.forEach((row, idx) => {
                    if (row.MobileNo.trim() !== '' && !validateMobileNo(row.MobileNo)) {
                        setrows(prevRows => {
                            const updatedRows = [...prevRows];
                            updatedRows[idx].mobileNoError = true;
                            return updatedRows;
                        });
                        setMobileNoError(true);
                        allvalid = false;
                    }

                    if (row.Email.trim() !== '' && !validateEmailID(row.Email)) {
                        setrows(prevRows => {
                            const updatedRows = [...prevRows];
                            updatedRows[idx].emailError = true;
                            return updatedRows;
                        });
                        setEmailError(true);
                        allvalid = false;
                    }
                });
            }
        }

        if (SendInvitationType.length === 0) {
            setSendInvitationTypeError(true);
            allvalid = false;
        }

        if (SendInvitationType === 'Category') {
            if (!isCategory || (Array.isArray(CategoryID) && CategoryID.length === 0)) {
                setCategoryError('Please Select Category');
                allvalid = false;
            } else {
                setCategoryError('');
            }
        }

        if (allvalid) {
            Swal.fire({
                title: 'Are you sure?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, submit it!',
            }).then((result) => {
                if (result.isConfirmed) {
                    const rowdata = [...rows];

                    var form = new FormData();
                    form.append('SendInvitationID', "");
                    form.append('UserID', ckUserID);
                    form.append('NatureOfProgram', NatureOfProgram);
                    form.append('InvitationStartDate', InvStartdt);
                    form.append('InvitationEndDate', InvEnddt);
                    form.append('InvitationStartTime', InvitationStartTime);
                    form.append('InvitationEndTime', InvitationEndTime);
                    form.append('Address', Address);
                    form.append('Details', Details);

                    for (let i = 0; i < InvitationImage.length; i++) {
                        form.append('InvitationImage', InvitationImage[i]);
                    }
                    form.append('SendInvitationType', SendInvitationType);

                    form.append('InvitationCategoryDetail', JSON.stringify(
                        Array.isArray(CategoryID) ? CategoryID?.map(item => ({
                            SendInvitationCategoryDetailID: item.SendInvitationCategoryDetailID || '',
                            CategoryID: item,
                        })) : []
                    ));

                    form.append('InvitationPersonDetail', JSON.stringify(
                        rowdata?.map(item => ({
                            SendInvitationPersonDetailID: item.SendInvitationPersonDetailID || '',
                            Name: item.Name || '',
                            MobileNo: item.MobileNo || '',
                            Email: item.Email || ''
                        }))
                    ));

                    form.append('InvitationPDF', PDF[0]);
                    form.append('GoogleMapUrl', GoogleMapUrl);

                    services.SetSendInvitation(form).then(response => {
                        debugger;
                        if (response.data.status) {
                            Swal.fire({
                                title: 'Successfully Inserted',
                                icon: "success",
                                width: "400px",
                                showConfirmButton: false,
                                timer: 1500
                            });
                            window.location.href = "/viewsendinvitation";
                            ClearData();
                        }
                    }, error => {
                        console.log(error, 'error');
                    });
                }
            })
        }
    };

    //--------GoogleMap Url validation----------//    
    // const validateUrl = (url) => {
    //     const googleMapUrlPattern = /^(https?:\/\/)?(www\.google\.[a-z]{2,3})\/maps\/?.*$/i;
    //     const generalUrlPattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-_]+)\.([a-zA-Z]{2,})(\/[a-zA-Z0-9-_#&=?]*)*\/?$/i;

    //     return googleMapUrlPattern.test(url) || generalUrlPattern.test(url);
    // };   
    // const handleGoogleMapUrl = (event) => {
    //     const url = event.target.value;
    //     setGoogleMapUrl(url);

    //     if (url.length > 0) {

    //         // Check if the URL matches either pattern
    //         if (!validateUrl(url)) {
    //             setGoogleMapError('Please enter a valid Google Map URL');
    //         } else {
    //             setGoogleMapError('');
    //         }
    //     } else {
    //         setGoogleMapError('');
    //     }
    // };

    const validateUrl = (url) => {
        const googleMapUrlPattern = /^(https?:\/\/)?((www\.)?google\.[a-z]{2,3}\/maps\/?.*|maps\.app\.goo\.gl\/.*)$/i;
        const generalUrlPattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-_]+)\.([a-zA-Z]{2,})(\/[a-zA-Z0-9-_#&=?]*)*\/?$/i;
    
        return googleMapUrlPattern.test(url) || generalUrlPattern.test(url);
    };
    const handleGoogleMapUrl = (event) => {
        const url = event.target.value;
        setGoogleMapUrl(url);

        if (url.length > 0) {
            // Check if the URL matches either pattern
            if (!validateUrl(url)) {
                setGoogleMapError('Please enter a valid Google Map URL');
            } else {
                setGoogleMapError('');
            }
        } else {
            setGoogleMapError('');
        }
    };
    //-----------------------------------//
    const ClearData = () => {
        setSendInvitationID('')
        setNatureOfProgram('')
        setNatureError('')

        setAddress('')
        setAddressError('')

        setDetails('')

        setInvitationStartDate('')
        setInvitationEndDate('')

        setInvitationStartTime('')
        setInvitationEndTime('')

        setImgErrorMsg('')
        setSendInvitationTypeError('')

        setPDF('')
        setOldPDF('');
        setPDFerrorMsg(true)

        setGoogleMapUrl('')
        setGoogleMapError('')
        ref1.current.value = ''
        ref2.current.value = ''
    }
    const CloseClick = () => {
        setIssubmitvisible(true)
        setIsUpdatevisible(false)
        setNatureOfProgram('')
        setNatureError('')

        setInvitationStartDateError('')
        setDateError('')

        setInvitationStartTime('')
        setInvitationStartTimeError('')
        setInvitationEndTime('')

        setAddress('')
        setAddressError('')

        setDetails('')

        setImgErrorMsg('')
        setInvitationImageerrorMsg(true)

        setSendInvitationType('')
        setSendInvitationTypeError('')

        setisCategory(false)
        setisManually(false)

        setCategoryID('')
        setCategoryError('')

        setPDF('')
        setOldPDF('');
        setPDFerrorMsg(true)

        setrows([{ Name: '', MobileNo: '', Email: '' }]);
        setaddTable(false)

        setGoogleMapUrl('')
        setGoogleMapError('')

        ref1.current.value = ''
        ref2.current.value = ''
    }

    return (
        <div className="body-wrapper">
            <div className="container-fluid">
                <div className='row'>
                    <div className='col-md-12'>

                        <div className="card">
                            <div className="px-3 py-2 border-bottom">
                                <h5>Add Send Invitation</h5>
                            </div>

                            <FormWithConstraints
                                ref={form}
                                // onSubmit={submitHandler}
                                noValidate>

                                <div className="card-body">
                                    <div className='row'>

                                        <div className='col-md-3'>
                                            <div className="form-group">
                                                <label htmlFor="NatureOfProgram" className="form-label fw-semibold"> Nature Of Program<span className="text-danger">*</span> </label>
                                                <input type="text" onChange={e => setNatureOfProgram(e.target.value)} required value={NatureOfProgram} type1="textnm" maxLength={30} className="form-control" placeholder="Enter Nature Of Program"></input>
                                                {NatureError && <span style={{ color: 'red' }}>{NatureOfProgram.length <= 0 ? 'Please Enter Nature Of Program' : ''}</span>}
                                            </div><br></br>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="Name" className="form-label fw-semibold">Invitation Start Date</label>
                                                <DatePicker
                                                    selected={InvitationStartDate}
                                                    onChange={handleInvitationStartchange}
                                                    dateFormat="dd-MM-yyyy"
                                                    value={InvitationStartDate}
                                                    placeholderText="DD-MM-YYYY"
                                                    className="form-control readonly"
                                                    autoComplete="off"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                />
                                            </div>
                                            {InvitationStartDateError && <div style={{ color: 'red' }}>{InvitationStartDateError}</div>}<br></br>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="Name" className="form-label fw-semibold">Invitation End Date</label>
                                                <DatePicker
                                                    selected={InvitationEndDate}
                                                    onChange={handleInvitationEndDatechange}
                                                    value={InvitationEndDate}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="DD-MM-YYYY"
                                                    className="form-control readonly"
                                                    autoComplete="off"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                />
                                                {dateError && <p style={{ color: 'red' }}>{dateError}</p>}

                                            </div><br></br>
                                        </div>

                                        <div className='col-md-3'>
                                            <label htmlFor="date" className="form-label fw-semibold">Invitation Start Time <span className="text-danger">*</span></label>
                                            <input type="time" className="form-control" value={InvitationStartTime} onChange={handleTimeChange} />
                                            {InvitationStartTimeError && <p style={{ color: 'red' }}>{InvitationStartTimeError}</p>}<br></br>
                                        </div>

                                        <div className='col-md-3'>
                                            <label htmlFor="date" className="form-label fw-semibold">Invitation End Time</label>
                                            <input type="time" className="form-control" value={InvitationEndTime} onChange={handleInvitationEndTimeChange} /><br></br>
                                        </div>

                                        <div className='col-md-3'>
                                            <div className="form-group">
                                                <label htmlFor="Address" className="form-label fw-semibold">Address <span className="text-danger">*</span></label>
                                                <textarea name="Address" onChange={e => setAddress(e.target.value)} required value={Address} cols={40} maxLength={100} className="form-control" placeholder="Enter Address" />
                                                {AddressError && <span style={{ color: 'red' }}>{Address.length <= 0 ? 'Please Enter Address' : ''}</span>}
                                            </div><br></br>
                                        </div>

                                        <div className='col-md-3'>
                                            <div className="form-group">
                                                <label htmlFor="Details" className="form-label fw-semibold">Details </label>
                                                <textarea name="Details" onChange={e => setDetails(e.target.value)} required value={Details} cols={200} className="form-control" placeholder="Enter Deatils" />
                                            </div><br></br>
                                        </div>


                                        <div className="col-md-3">
                                            <label htmlFor="fileInput" className="form-label fw-semibold">Invitation Image
                                                <span style={{ color: '#B71C1C' }}> (Single/ Multiple)</span>
                                            </label>
                                            <input type="file" ref={ref2} className="form-control" multiple onChange={HandleInvitationImage} accept="image/jpeg, image/png, image/jpg, image/PNG" style={{ height: "37px" }} />
                                            {!InvitationImageerrorMsg && <span className="text-danger">Please Enter Only Image File</span>}
                                            {ImgerrorMsg && <p style={{ color: 'red' }}>{ImgerrorMsg}</p>}<br></br>
                                        </div>

                                        <div className="col-md-3">
                                            <label htmlFor="fileInput" className="form-label fw-semibold"> Upload PDF</label>
                                            <input type="file" ref={ref1} className="form-control" onChange={handlePDF} accept='application/pdf, application/PDF' style={{ height: "37px" }} />
                                            {OldPDF !== "" && (<label style={{ color: "Red" }}>{OldPDF ? OldPDF : ""} </label>)}
                                            {!PDFerrorMsg && <span className="text-danger">Please Enter Only PDF</span>}<br></br>
                                        </div>



                                        {isTypeVisible &&
                                            <div className="col-md-3">
                                                <div className="form-group ">
                                                    <label htmlFor="SendInvitationType" className="form-label fw-semibold">Send InvitationType <span className="text-danger">*</span></label>
                                                    <div className="form-control">
                                                        <div className="d-flex align-items-center gap-2" onChange={onSendInvitationTypeChange}>
                                                            <label className="d-flex align-items-center gap-2"><input type="radio" value="Category" name="SendInvitationType" checked={SendInvitationType === 'Category'} /><span>Category</span></label>
                                                            <label className="d-flex align-items-center gap-2"><input type="radio" value="Manually" name="SendInvitationType" checked={SendInvitationType === 'Manually'} /><span>Manually</span></label>
                                                        </div>
                                                    </div>
                                                    {SendInvitationTypeError && <span style={{ color: 'red' }}>{SendInvitationType.length <= 0 ? 'Please Select Send InvitationType' : ''}</span>}
                                                </div><br></br>
                                            </div>
                                        }

                                        {isTypeVisible && isCategory &&
                                            <div className="col-md-3">
                                                <div className="form-group ">
                                                    <label htmlFor="Category" className="form-label fw-semibold">Category <span className="text-danger"> *</span></label>
                                                    <MultiSelect style={{ borderColor: '#2684FF', borderWidth: 'none', borderStyle: 'none' }}
                                                        options={Categoryes} selected={CategoryID} checked={false}
                                                        onSelectedChanged={onCategoryChange}
                                                        overrideStrings={{
                                                            selectSomeItems: "--Select Category--", allItemsAreSelected: "All Categoryes are Selected",
                                                            selectAll: "Select All", search: "Search", selectSomeItemsColor: "hsl(0, 0%, 20%)"
                                                        }}
                                                        placeholder="--Select Category--"
                                                    />
                                                    {CategoryError && <p style={{ color: 'red' }}>{CategoryError}</p>}
                                                </div>
                                            </div>
                                        }

                                        {isTypeVisible && isManually &&
                                            <div>
                                                {rows.map((item, idx) => (
                                                    <div className="row" key={idx}>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label className="form-label fw-semibold" htmlFor={"Name" + idx}>Name</label>
                                                                <input type="Name" name={"Name" + idx} value={rows[idx].Name} onChange={onNameChange(idx)} className="form-control" maxLength={20} placeholder="Enter Name" onKeyPress={allowOnlyCharacters} />
                                                                {addtable && idx === 0 && !rows[idx].Name && <span style={{ color: 'red' }}>Please Enter Name</span>}
                                                            </div><br></br>
                                                        </div>

                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label className="form-label fw-semibold" htmlFor={"MobileNo" + idx}>Mobile No</label>
                                                                <input type="text" name={"MobileNo" + idx} value={rows[idx].MobileNo} onChange={onMobileNoChange(idx)} className="form-control" maxLength={10} onKeyPress={allowOnlyNumbers} placeholder="Enter MobileNo" />
                                                                {addtable && idx === 0 && !rows[idx].MobileNo && <span style={{ color: 'red' }}>Please Enter MobileNo</span>}
                                                                {item.mobileNoError && <span style={{ color: 'red' }}>Please Enter a valid 10-digit Mobile No</span>}
                                                            </div><br></br>
                                                        </div>

                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label className="form-label fw-semibold" htmlFor={"Email" + idx}>Email</label>
                                                                <input type="text" name={"Email" + idx} value={rows[idx].Email} onChange={onEmailChange(idx)} className="form-control" maxLength={30} placeholder="Enter Email" />
                                                                {addtable && idx === 0 && !rows[idx].Email && <span style={{ color: 'red' }}>Please Enter Email</span>}
                                                                {item.emailError && <span style={{ color: 'red' }}>Please Enter Valid Email ID</span>}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-3" style={{ marginTop: '12px' }}>
                                                            <i onClick={handleProductAddRow} className="fa fa-plus-circle pt-10 mobpt-1 mobmr-5" style={{ fontSize: 30, color: '#3699ff', marginRight: '5px' }} />
                                                            <i onClick={handleProductRemoveSpecificRow(idx)} className="fa fa-minus-circle pt-10 mobpt-1" style={{ fontSize: 30, color: '#3699ff', marginTop: '6%' }} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        }

                                        <div className='col-md-3'>
                                            <div className="form-group">
                                                <label htmlFor="GoogleMapUrl" className="form-label fw-semibold">Google Map URL
                                                    <span style={{ color: '#B71C1C' }}> </span> </label>
                                                <input
                                                    type="text"
                                                    onChange={handleGoogleMapUrl}
                                                    required value={GoogleMapUrl}
                                                    className="form-control"
                                                    placeholder="Enter Google Map URL"
                                                />
                                                {GoogleMaperror && <div style={{ color: 'red' }}>{GoogleMaperror}</div>}
                                            </div>
                                        </div>

                                        <div className="col-md-3" style={{ marginTop: '2%' }}>
                                            {issubmitvisible &&
                                                <button type="button" onClick={submitHandler} className="btn btn-outline-primary font-weight-bolder ml-2 " style={{ marginRight: '2%' }} >Submit</button>
                                            }
                                            {isUpdatevisible &&
                                                <button type="button" onClick={submitHandler} className="btn btn-outline-primary font-weight-bolder ml-2 " style={{ marginRight: '2%' }} >Update</button>
                                            }
                                            <button type="button" onClick={CloseClick} className="btn btn-outline-danger font-weight-bolder ml-2" style={{ marginRight: '2%' }} >Cancel</button>
                                            <button type="button" onClick={ViewHandler} className="btn btn-outline-primary font-weight-bolder ml-2">View</button>
                                        </div>

                                    </div>
                                </div>

                            </FormWithConstraints>
                        </div>
                    </div>
                </div >
            </div >
        </div >

    )
}

export default Sendinvitation


