import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material';
import MaterialTable from 'material-table';
import { useState, useRef, useEffect } from "react";
import { FormWithConstraints, FieldFeedbacks, FieldFeedback, } from 'react-form-with-constraints';
import services from "../Services/Services";
import Swal from "sweetalert2";
import Select from 'react-select'
import { useCookies } from 'react-cookie';
import XLSX from "xlsx-color";
import moment from 'moment';
import { allowOnlyNumbers, allowOnlyCharacters } from '../inputvalidation';
import DatePicker from "react-datepicker";
import MultiSelect from '@khanacademy/react-multi-select';
import { MdNoBackpack } from 'react-icons/md';


const defaultMaterialTheme = createTheme();

const ContactCard = () => {

  const [cookies, setCookie] = useCookies(['user']);
  const [ckUserID, setckUserID] = useState(cookies.ckUserID);
  // const [ckDesignationID, setckDesignationID] = useState(cookies.ckDesignationID);


  const headerTblStyle = { color: 'Black' };
  const cols = [
    { title: (<div style={{ color: 'white', width: '9px' }}><span>Sr.No</span></div>), field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px'}}>{(pageId * pageCount - 10) + rowData.tableData.id + 1}</p>) } },
    { title: (<div style={{ width: '100%', color: 'white' }}><span>Name</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '140px' }}>{rowData.Name}</p>) } },
    { title: (<div style={{ color: 'white' }}> <span>Designation</span></div>), headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '150px' }}>{rowData.Designation}</p>) } },
    {
      title: (<div style={{ color: 'white', width: '180px' }}><span> Mobile No</span></div>), removable: false, headerStyle: headerTblStyle, render: rowData => {
        return (
          <div style={{ marginBottom: '1px', width: '215px' }}>{
            <table id="MobileCard" style={{ border: 'solid 1px #000000', marginRight: '5px' }}>
              <tbody>
                <tr style={{ border: 'solid 1px #000000', background: '#ffffff' }}>
                  <td style={{ border: 'solid 1px #000000' }}>Mobile No</td>
                  <td style={{ border: 'solid 1px #000000', padding: 2, fontWeight: 'bold', color: 'black' }}>
                    <span id="MobileNo" />{rowData.MobileNumber1}<br />
                  </td>
                </tr>
                <tr style={{ border: 'solid 1px #000000', background: '#ffffff' }}>
                  <td style={{ border: 'solid 1px #000000', marginLeft: '5px' }}>Mobile No 2</td>
                  <td style={{ border: 'solid 1px #000000', padding: 2, fontWeight: 'bold', color: 'black' }}>
                    <span id="MobileNo2" />{rowData.MobileNumber2}<br />
                  </td>
                </tr>
                <tr style={{ border: 'solid 1px #000000', background: '#ffffff' }}>
                  <td style={{ border: 'solid 1px #000000' }}>Telephone No</td>
                  <td style={{ border: 'solid 1px #000000', padding: 2, fontWeight: 'bold', color: 'black' }}>
                    <span id="MobileNo3" />{rowData.LandlineNumber}<br />
                  </td>
                </tr>
              </tbody>
            </table>}</div>)
      }
    },
    { title: (<div style={{ color: 'white' }}> <span>Address</span></div>), headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', width: '160px' }}>{rowData.Address}</p>) } },
    { title: (<div style={{ color: 'white', width: '60px' }}> <span>Email ID</span></div>), headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.EmailID}</p>) } },

  ]

  const form = useRef(FormWithConstraints);

  const handleExcelExport = async (pageCount, st) => {

    var NM = "";
    var MNO = "";

    if (Name1 != "") {
      NM = Name1
    }

    if (MobileNo1 != "") {
      MNO = MobileNo1
    }

    var data = JSON.stringify({
      "ContactCardID": "",
      "Name": st == "cancle" ? "" : NM,
      "MobileNo": st == "cancle" ? "" : MNO,
      "StartDate": "",
      "EndDate": "",
      "PaginationID": "-1",
      "PageLength": "0"

    });
    var config = {
      method: 'POST',
      url: services.API_URL + "ContactCard/GetContactCard",
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    services.JsonValue(config).then(response => {
      if (response.data.status) {
        exportToExcel(response.data.data)
      }

    }, error => { })
  };

  const exportToExcel = (array) => {
    console.log(array, 'data');
    debugger;
    const header = ["Sr.No", "Name", "Designation","Mobile No", "Mobile No 2", "LandlineNumber", "Address", "EmailID"];
    const body = [];
    array?.forEach((col, index) => {
      body.push([index + 1, col.Name, col.Designation, col.MobileNumber1, col.MobileNumber2, col.LandlineNumber, col.Address, col.EmailID]);
    });

    if (!body.length) {
      return Swal.fire({
        title: "No Data Found!",
        icon: "error",
        timer: 1500
      });
    }

    const ws1 = XLSX.utils.aoa_to_sheet([header, ...body]);

    header.forEach((col, index) => {
      ws1[XLSX.utils.encode_cell({ r: 0, c: index })].s = {
        font: { bold: true, color: { rgb: "f7f4ef" } }, // White font color
        fill: { patternType: 'solid', fgColor: { rgb: "#B71C1C" } }, // Blue background color
        alignment: { wrapText: index === 3, horizontal: 'center' }, // Enable wrap text for the "Draft" column
      };
    });

    ws1['!cols'] = [
      { width: 6 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 }, 
    ];


    let cell = {
      font: { bold: true, color: { rgb: "f7f4ef" } },
      fill: { patternType: 'solid', fgColor: { rgb: "#B71C1C" } },
      alignment: { wrapText: true, horizontal: 'center' }, // Enable wrap text for the "Draft" column
      border: {
        left: { style: 'thin', color: { rgb: '000000' } },
        right: { style: 'thin', color: { rgb: '000000' } },
        top: { style: 'thin', color: { rgb: '000000' } },
        bottom: { style: 'thin', color: { rgb: '000000' } }
      }
    };
    ws1['A1'].s = cell;
    ws1['B1'].s = cell;
    ws1['C1'].s = cell;
    ws1['D1'].s = cell;
    ws1['E1'].s = cell;
    ws1['F1'].s = cell;
    ws1['G1'].s = cell;
    ws1['H1'].s = cell;
   

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, 'ContactCard');

    XLSX.writeFile(wb, `ContactCard_${moment().format('DD-MM-YYYY_HH:mm')}.xlsx`);
  };

  const [ContactCardID, setContactCardID] = useState('')

  //---------------Searching ---------------//  
  const [Name1, setName1] = useState('')
  const [MobileNo1, setMobileNo1] = useState('')
  //---------------------//
  const [Name, setName] = useState('')
  const [Nameerror, setNameerror] = useState('')

  const [Designation, setDesignation] = useState('')
  const [Designationerror, setDesignationerror] = useState('')

  const [MobileNo, setMobileNo] = useState('')
  const [MobileNoerror, setMobileNoerror] = useState('')

  const [MobileNo2, setMobileNo2] = useState('')
  const [MobileNo2error, setMobileNo2error] = useState('')

  const [LandlineNumber, setLandlineNumber] = useState('')
  const [LandlineNumbererror, setLandlineNumbererror] = useState('')

  const [Address, setAddress] = useState('')

  const [EmailID, setEmailID] = useState('')
  const [emailError, setEmailError] = useState('')

  const [pageCount, setPageCount] = useState(10);
  const [pageId, setPageId] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [allTableData, setAllTableData] = useState([]);
  const [TotalCount, setTotalCount] = useState('')

  const [issubmitvisible, setIssubmitvisible] = useState(true)
  const [isUpdatevisible, setIsUpdatevisible] = useState(false)
  const ref = useRef();

  const [iconFilter, seticonFilter] = useState('fa fa-plus')
  const [isfiltervisible, setFilter] = useState(false)

  const [iconFilter1, seticonFilter1] = useState('fa fa-plus')
  const [isfiltervisible1, setFilter1] = useState(false)

  useEffect(() => {
    GetContactCard(pageCount);
  }, []);

  const GetContactCard = (pageCount, st) => {
    debugger;

    var NM = "";
    var MNO = "";

    if (Name1 != "") {
      NM = Name1
    }

    if (MobileNo1 != "") {
      MNO = MobileNo1
    }

    var data = JSON.stringify({
      "ContactCardID": "",
      "Name": st == "cancle" ? "" : NM,
      "Designation": "",
      "MobileNumber1": st == "cancle" ? "" : MNO,
      "MobileNumber2": "",
      "StartDate": "",
      "EndDate": "",
      "PaginationID": "0",
      "PageLength": `${pageCount}`
    });

    var config = {
      method: 'POST',
      url: services.API_URL + "ContactCard/GetContactCard",
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    services.JsonValue(config).then(response => {
      if (response.data.status) {
        setTableData(response.data.data)
        setAllTableData(response.data.data)
        setPageId(1)
        setIssubmitvisible(true)
        setIsUpdatevisible(false)
        setTotalCount(response.data.total[0].TotalCount);
      }
      else {
        setTableData([]);
      }

    }, error => { })
  }
  const setPreviousApi = () => {
    debugger;
    if (pageId == 1) {
      return
    }
    const tempt = [...allTableData]
    console.log((pageId - 2) * pageCount, (pageId - 1) * pageCount, 'alltabledata')
    const sliced = tempt.slice((pageId - 2) * pageCount, (pageId - 1) * pageCount)

    setTableData(sliced)
    setPageId(pageId - 1)

  };
  const setNextApi = () => {
    console.log(pageId, "pageID", pageCount)

    if (!tableData.length) {
      return;
    }
    if (pageId * pageCount < allTableData.length) {
      const tempt = [...allTableData];
      const sliced = tempt.slice((pageId) * pageCount, (pageId + 1) * pageCount);
      setTableData(sliced);
      setPageId(pageId + 1);
      return;
    }

    const paginationIndex = allTableData[pageId * pageCount - 1]?.ContactCardID;
    if (!paginationIndex) {
      return;
    }

    var data = JSON.stringify({

      "ContactCardID": "",
      "Name": "",
      "Designation": "",
      "MobileNumber1": "",
      "MobileNumber2": "",
      "StartDate": "",
      "EndDate": "",
      "PaginationID": paginationIndex,
      "PageLength": `${pageCount}`
    });

    var config = {
      method: 'POST',
      url: services.API_URL + "ContactCard/GetContactCard",
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    services.JsonValue(config).then(response => {
      if (response.data.status) {
        setTableData(response.data.data)
        setPageId(pageId + 1)
        setAllTableData([...allTableData, ...response.data.data])
        setTotalCount(response.data.total[0].TotalCount);
      }

    }, error => { })
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    setNameerror('')
  }
  const handleDesignationChange = (e) => {
    setDesignation(e.target.value);
    setDesignationerror('')
  }
  //-- Serching --//
  const handleNameChange1 = (e) => {
    setName1(e.target.value);
  }
  const handleMobileChange1 = (e) => {
    setMobileNo1(e.target.value);
  }
  //-------------//

  const handleMobileChange = (e) => {
    setMobileNoerror('')
    setMobileNo(e.target.value);

    const inputReferenceMobileNoNumber = e.target.value;
    const MoNo = inputReferenceMobileNoNumber.replace(/\D/g, '');
    setMobileNo(MoNo);
    setMobileNoerror('');
  }
  const handleMobileChange2 = (e) => {
    if (e != null) {
      setMobileNo2(e);
    } else {
      setMobileNo2('');
    }
    const inputMobileNoNumber2 = e.target.value;
    // Remove any non-digit characters from the input
    const MoNo2 = inputMobileNoNumber2.replace(/\D/g, '');
    setMobileNo2(MoNo2);
    setMobileNo2error('');
  }
  const handleLandlineNumberChange = (e) => {
    if (e != null) {
      setLandlineNumber(e);
    } else {
      setLandlineNumber('');
    }
    const inputLandlineNumber = e.target.value;
    // Remove any non-digit characters from the input
    const TellNo = inputLandlineNumber.replace(/\D/g, '');
    setLandlineNumber(TellNo);
    setLandlineNumbererror('');
  }

  const validateEmailID = () => {
    if (EmailID.length) {
      const EmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (EmailID.match(EmailRegex)) {
        setEmailError('');
        return true;
      } else {
        setEmailError('Please Enter Valid Email ID');
        return false;
      }
    }
    return true;
  };

  const submitHandler = (e) => {
    debugger;

    var allvalid = true;

    if (Name.length === 0) {
      setNameerror(true)
      allvalid = false;
    }

    if (Designation.length === 0) {
      setDesignationerror(true)
      allvalid = false;
    }

    if (MobileNo?.trim() !== '') {
      if (/^\d{10}$/.test(MobileNo)) {
        setMobileNoerror('');
      } else {
        setMobileNoerror('Please Enter Valid 10 Digit Mobile Number');
        allvalid = false;
      }
    } else {
      setMobileNoerror('Please Enter Mobile No');
      allvalid = false;
    }

    if (MobileNo2.length !== 10 && MobileNo2.length) {
      setMobileNo2error('Please Enter Valid 10 Digit Mobile Number');
      allvalid = false;
    }

    if (LandlineNumber.length !== 10 && LandlineNumber.length) {
      setLandlineNumbererror('Please Enter Valid 10 Digit Landline Number');
      allvalid = false;
    }

    const isEmailValid = validateEmailID();
    if (!isEmailValid) {
      allvalid = false;
    }

    if (allvalid) {

      Swal.fire({
        title: 'Are you sure?',
        // text: 'Do you want to submit?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, submit!',
      }).then((result) => {
        if (result.isConfirmed) {

          var data = "";

          if (ContactCardID != "") {
            data = JSON.stringify({
              "ContactCardID": ContactCardID,
              "UserID": ckUserID,
              "Name": Name,
              "Designation": Designation,
              "MobileNumber1": MobileNo,
              "MobileNumber2": MobileNo2,
              "LandlineNumber": LandlineNumber,
              "Address": Address,
              "EmailID": EmailID,


            });
          }
          else {
            data = JSON.stringify({
              "ContactCardID": "",
              "UserID": ckUserID,
              "Name": Name,
              "Designation": Designation,
              "MobileNumber1": MobileNo,
              "MobileNumber2": MobileNo2,
              "LandlineNumber": LandlineNumber,
              "Address": Address,
              "EmailID": EmailID,

            });
          }

          var config = {
            method: 'POST',
            url: services.API_URL + "ContactCard/SetContactCard",
            headers: {
              'Content-Type': 'application/json'
            },
            data: data
          };
          services.JsonValue(config).then(response => {
            //// debugger;
            if (response.data.status) {
              // debugger;
              if (ContactCardID != "") {
                // Swal.fire({ position: 'top-end', toast: true, icon: 'success', title: 'Successfully Updated', showConfirmButton: false, timer: 1500 });
                Swal.fire({
                  title: 'Successfully Updated', icon: "success", timer: 1500
                });
              }
              else {
                Swal.fire({
                  title: 'Successfully Inserted', icon: "success", timer: 1500
                });
              }
              ClearData();
              GetContactCard(pageCount);
            }
            else {
              Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
            }
          }, error => { })
        }
      })
    }

  }

  const EditContactCard = (id) => {
    debugger;
    if (isfiltervisible === false) {
      setFilter(true);
      seticonFilter("fa fa-minus");
    }
    setIsUpdatevisible(true)
    setIssubmitvisible(false)

    var data = JSON.stringify({
      "ContactCardID": id,
      "Name": "",
      "Designation": "",
      "MobileNumber1": "",
      "MobileNumber2": "",
      "StartDate": "",
      "EndDate": "",
      "PaginationID": "-1",
      "PageLength": ""
    });

    var config = {
      method: 'POST',
      url: services.API_URL + "ContactCard/GetContactCard",
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    services.JsonValue(config).then(response => {

      setContactCardID(response.data.data[0].ContactCardID)
      setName(response.data.data[0].Name)
      setDesignation(response.data.data[0].Designation)
      setMobileNo(response.data.data[0].MobileNumber1)
      setMobileNo2(response.data.data[0]?.MobileNumber2 || '')
      setLandlineNumber(response.data.data[0]?.LandlineNumber || '')
      setEmailID(response.data.data[0]?.EmailID || '')
      setAddress(response.data.data[0]?.Address || '')
    }, error => { })
  }

  const CheckAlert = (id) => {
    debugger;
    Swal.fire({
      title: 'Are you Sure You Want to Delete?', icon: "warning", showCancelButton: true, confirmButtonText: 'Delete', cancelButtonText: 'Cancel'
    }).then((result) => {

      if (result.isConfirmed) {
        var data = JSON.stringify({
          "ContactCardID": id
        });
        var config = {
          method: 'POST',
          url: services.API_URL + "ContactCard/DeleteContactCard",
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };
        services.JsonValue(config).then(response => {
          console.log(response.data.status, 'staus')
          if (response.data.status) {
            GetContactCard(pageCount);
            Swal.fire({
              title: 'Successfully Deleted', icon: "success",  width:"400px", showConfirmButton: false, timer: 1500
            });
          } else {
            Swal.fire({ position: 'top-end', icon: 'error', title: response.data.message, showConfirmButton: false, timer: 1500 });
          }
          ClearData();
        }, error => { })
      } else if (result.isDenied) {
        Swal.close();
      }
    });


  }

  const CloseClick = () => {
    setIssubmitvisible(true)
    setIsUpdatevisible(false)
    setName('')
    setNameerror('')
    setDesignation('')
    setDesignationerror('')
    setMobileNo('')
    setMobileNoerror('')
    setMobileNo2('')
    setMobileNo2error('')
    setLandlineNumber('')
    setLandlineNumbererror('')
    setAddress('')
    setEmailID('')
    setEmailError('')
  }
  const ClearData = () => {
    setContactCardID('')
    setName('')
    setNameerror('')
    setDesignation('')
    setDesignationerror('')
    setMobileNo('')
    setMobileNoerror('')
    setMobileNo2('')
    setMobileNo2error('')
    setLandlineNumber('')
    setAddress('')
    setEmailID('')
  }

  const OnFilterClick = (e) => {
    e.preventDefault();
    if (isfiltervisible === false) {
      setFilter(true);
      seticonFilter("fa fa-minus");
    }
    else {
      setFilter(false);
      seticonFilter("fa fa-plus");
    }
  }

  const OnFilterClick1 = (e) => {
    setFilter(false)
    setFilter1(true);

    e.preventDefault();
    if (isfiltervisible1 === false) {
      setFilter1(true);
      seticonFilter1("fa fa-minus");
    }
    else {
      setFilter1(false);
      seticonFilter1("fa fa-plus");
    }
  }


  const onSearchClick = (e) => {
    e.preventDefault();
    GetContactCard(pageCount)
  }

  const OnSearchCancelClick = () => {
    setMobileNo1('')
    setName1('')
    GetContactCard(pageCount, "cancle");
  }

  const ExcelClick = () => {
    handleExcelExport();
  }

  return (
    <>
      <div className="body-wrapper">
        <div className="container-fluid">

          <div className='col-md-12'>
            {isfiltervisible &&
              <div className="card">
                <div className="px-3 py-2 border-bottom">
                  <h5>Contact Card</h5>
                </div>
                <FormWithConstraints
                  ref={form}
                  onSubmit={OnFilterClick}
                  noValidate>
                  {/* <div className="card-body">
                    <div className='row'>

                      <div className='col-md-3'>
                        <div className="form-group">
                          <label htmlFor="Name" className="form-label fw-semibold">Name <span className="text-danger"> *</span></label>
                          <input type="Name" onChange={handleNameChange} required value={Name} maxLength={50} onKeyPress={allowOnlyCharacters} type1="textnm" className="form-control" placeholder="Enter Name"></input>
                          {Nameerror && <span style={{ color: 'red' }}>{Name.length <= 0 ? 'Please Enter Name' : ''}</span>}
                        </div>  <br></br>
                      </div>

                      <div className='col-md-3'>
                        <div className="form-group">
                          <label htmlFor="Designation" className="form-label fw-semibold">Designation <span className="text-danger"> *</span></label>
                          <input type="Designation" onChange={handleDesignationChange} required value={Designation} maxLength={50} onKeyPress={allowOnlyCharacters} type1="textnm" className="form-control" placeholder="Enter Designation"></input>
                          {Designationerror && <span style={{ color: 'red' }}>{Designation.length <= 0 ? 'Please Enter Designation' : ''}</span>}
                        </div>  <br></br>
                      </div>


                      <div className='col-md-3'>
                        <div className="form-group">
                          <label htmlFor="MobileNo" className="form-label fw-semibold"> Mobile No 1<span className="text-danger"> *</span></label>
                          <input type="MobileNo" onChange={handleMobileChange} required value={MobileNo} maxLength={10} onKeyPress={allowOnlyNumbers} type1="textnm" className="form-control" placeholder="Enter Mobile No"></input>
                          <p style={{ color: 'red' }}>{MobileNoerror}</p>
                        </div>
                      </div>

                      <div className='col-md-3'>
                        <div className="form-group">
                          <label htmlFor="MobileNo2" className="form-label fw-semibold"> Mobile No 2</label>
                          <input type="MobileNo2" onChange={handleMobileChange2} required value={MobileNo2} maxLength={10} onKeyPress={allowOnlyNumbers} type1="textnm" className="form-control" placeholder="Enter Mobile No 2"></input>
                          <p style={{ color: 'red' }}>{MobileNo2error}</p>
                        </div>
                      </div>

                      <div className='col-md-3'>
                        <div className="form-group">
                          <label htmlFor="LandlineNumber" className="form-label fw-semibold"> Landline Number</label>
                          <input type="LandlineNumber" onChange={handleLandlineNumberChange} required value={LandlineNumber} maxLength={10} onKeyPress={allowOnlyNumbers} type1="textnm" className="form-control" placeholder="Enter Telephone No"></input>
                          <p style={{ color: 'red' }}>{LandlineNumbererror}</p>
                        </div>
                      </div>

                      <div className='col-md-3'>
                        <div className="form-group">
                          <label htmlFor="Address" className="form-label fw-semibold">Address</label>
                          <textarea name="Address" ref={ref} onChange={e => setAddress(e.target.value)} required value={Address} rows={2} cols={20} className="form-control" placeholder="Enter Address" />
                        </div><br></br>
                      </div>

                      <div className='col-md-3'>
                        <div className="form-group">
                          <label htmlFor="EmailID" className="form-label fw-semibold">Email ID</label>
                          <input type="EmailID" onChange={(event) => setEmailID(event.target.value)} required value={EmailID} maxLength={100} type1="textnm" className="form-control" placeholder="Enter Email ID"></input>
                          {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
                        </div>
                      </div>


                      <div className='col-md-3' style={{ marginTop: '30px' }}>
                        {issubmitvisible &&
                          <button type="button" onClick={submitHandler} className="btn btn-outline-primary font-weight-bolder ml-2" style={{ marginRight: '5px' }}>Submit</button>
                        }
                        {isUpdatevisible &&
                          <button type="button" onClick={submitHandler} className="btn btn-outline-primary font-weight-bolder ml-2" style={{ marginRight: '5px' }}>Update</button>
                        }
                        <button type="button" onClick={CloseClick} className="btn btn-outline-danger font-weight-bolder ml-2">Cancel</button>

                      </div>
                    </div>
                  </div> */}
                </FormWithConstraints>
              </div>
            }
          </div>

          {isfiltervisible1 &&
            <div className="card">
              <div className="border-bottom">
                <h4 style={{ marginLeft: '15px', marginTop: '5px' }}>Searching</h4>
              </div>
              <div className="card-body">

                <div className='row'>

                  <div className='col-md-3'>
                    <div className="form-group">
                      <label htmlFor="Name" className="form-label fw-semibold">Name</label>
                      <input type="Name" onChange={handleNameChange1} required value={Name1} maxLength={50} onKeyPress={allowOnlyCharacters} type1="textnm" className="form-control" placeholder="Enter Name"></input>
                    </div><br></br>
                  </div>

                  <div className='col-md-3'>
                    <div className="form-group">
                      <label htmlFor="MobileNo1" className="form-label fw-semibold"> Mobile No </label>
                      <input type="MobileNo1" onChange={handleMobileChange1} required value={MobileNo1} maxLength={10} onKeyPress={allowOnlyNumbers} type1="textnm" className="form-control" placeholder="Enter Mobile No"></input>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <button type="button" onClick={onSearchClick} className="btn btn-outline-primary font-weight-bolder ml-2 " style={{ marginRight: '3%', marginTop: '28px' }} >Search </button>
                    <button type="button" onClick={OnSearchCancelClick} className="btn btn-outline-danger font-weight-bolder ml-2" style={{ marginRight: '1%', marginTop: '28px' }}>Cancel </button>
                    <a className="btn btn-outline-success font-weight-bolder mt-12" onClick={ExcelClick} style={{ marginRight: '1%', marginTop: '28px', marginLeft: '2px' }}>
                      <i id="btnExcel" className="fa fa-file-excel" /> Excel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          }

          <div className='col-md-12'>
            <ThemeProvider theme={defaultMaterialTheme}>
              <MaterialTable columns={cols} data={tableData}
                actions={[
                  // {
                  //   icon: 'edit', tooltip: 'Edit', onClick: (e, r) => EditContactCard(r.ContactCardID)
                  // },
                  {
                    icon: 'delete', tooltip: 'Delete', onClick: (e, r) => CheckAlert(r.ContactCardID, r.Name)
                  }
                ]}

                options={{
                  headerStyle: { backgroundColor: '#B71C1C', color: 'white', fontFamily: 'system-ui' }, toolbar: true, actionsColumnIndex: -1, search: false,
                  paging: false, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                }}

                components={{
                  Toolbar: props => (
                    <>
                      <div className="row" >
                        <div className="col-md-12" style={{ display: 'flex', alignItems: 'center', marginLeft: '1%', marginTop: '0.3%', marginBottom: '0.3%' }}>
                          <h5>View Contact Card : <span className="badge bg-light-primary text-black fw-semibold fs-2">{TotalCount}</span></h5>

                          <div className="col-md-10" style={{ textAlign: 'right', marginLeft: 'auto' }}>

                            <a className="btn btn-outline-dark font-weight-bolder" onClick={OnFilterClick1} style={{ marginTop: '5px', marginRight: '20px' }}>
                              <i id="btnFilter" className={iconFilter1} isfiltervisible='false' /> Filter
                            </a>                          
                          </div>
                        </div>
                      </div>
                    </>
                  )
                }}
              />
            </ThemeProvider>
          </div>

          <div id="ctl00_ContentPlaceHolder1_divPagination">
            <div className="col-lg-12 col-md-12 col-12 cssPagination" style={{ boxShadow: '2px 2px 5px #808080' }}>
              <nav aria-label="Page navigation">
                <ul className="GridPager pagination" style={{ paddingTop: '2px' }}>
                  <div style={{ marginTop: '0.5%', marginBottom: '0.5%', marginLeft: '3px' }}>
                    <button type="button" onClick={setPreviousApi} className="btn btn-primary mt-12 mr-3 mobmt-2 page-disable" style={{ marginRight: '1.5%' }} >Previous </button>
                  </div>
                  <div style={{ marginTop: '0.5%', marginBottom: '0.5%', marginLeft: '3px' }} >
                    <button type="button" onClick={setNextApi} className="btn btn-danger mt-12 mobmt-1 page-disable" style={{ marginRight: '1.5%' }}>Next </button>
                  </div>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactCard

